@import 'styles/common';

.step {
  background-color: white;
  border-radius: 2px;
  display: flex;
  max-width: 894px;

  @include mobile {
    flex-direction: column-reverse;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    width: calc(100% - (350px + 16px));
    padding: 32px;

    @include mobile {
      width: 100%;
      margin-left: 0;
      padding: 16px;
    }

    h3 {
      font-size: 24px;
      line-height: 20px;
      color: $red;
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 20px;
    }

    .notice {
      margin: 8px 0 0;
    }

    .controls {
      align-items: center;
      display: flex;
      margin-top: 30px;

      .button {
        width: 100%;
      }

      .cancel {
        margin-left: 34px;
        cursor: pointer;
        color: $red;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }

  &.choosePassengers {
    .passengers {
      margin-bottom: 40px;

      .passenger {
        display: flex;
        align-items: center;
        height: 62px;
        line-height: 20px;
        border-radius: 2px;
        border: 1px solid $light;
        transition: 0.3s;
        padding: 0 16px;
        cursor: pointer;

        .check {
          height: 14px;
          width: 14px;
          border-radius: 2px;
          border: 1px solid #bdbdbd;
          position: relative;
          margin-right: 12px;
          flex-shrink: 0;

          &:after {
            position: absolute;
            border-radius: 2px;
            height: 8px;
            width: 8px;
            top: 2px;
            left: 2px;
            background-color: transparent;
            content: '';
            transition: 0.3s;
          }
        }

        &:not(.selected):hover {
          border-color: darken($light, 8%);
        }

        &.selected {
          background-color: $light;

          .check {
            &:after {
              background-color: $red;
            }
          }
        }

        & + .passenger {
          margin-top: 8px;
        }
      }
    }
  }

  &.chooseTrip {
    .ways {
      margin-bottom: 40px;

      .way {
        display: flex;
        align-items: center;
        height: 62px;
        line-height: 20px;
        border-radius: 2px;
        border: 1px solid $light;
        padding: 0 16px;
        transition: 0.3s;
        cursor: pointer;

        .check {
          height: 14px;
          width: 14px;
          border-radius: 50%;
          border: 1px solid #bdbdbd;
          position: relative;
          margin-right: 12px;
          flex-shrink: 0;

          &:after {
            position: absolute;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            top: 2px;
            left: 2px;
            background-color: transparent;
            content: '';
            transition: 0.3s;
          }
        }

        &:not(.selected):hover {
          border-color: darken($light, 8%);
        }

        &.selected {
          background-color: $light;

          .check {
            &:after {
              background-color: $red;
            }
          }
        }

        & + .way {
          margin-top: 8px;
        }
      }
    }
  }

  &.summary {
    .form {
      padding-top: 10px;

      .calculatingBlock {
        height: 60px;
        display: flex;
        align-items: center;
        border: 1px solid $light;
        border-radius: 2px;
        padding: 0 20px;
        transition: 0.3s;
        cursor: pointer;

        .check {
          height: 14px;
          width: 14px;
          border-radius: 50%;
          border: 1px solid #bdbdbd;
          position: relative;
          margin-right: 12px;
          flex-shrink: 0;

          &:after {
            position: absolute;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            top: 2px;
            left: 2px;
            background-color: transparent;
            content: '';
            transition: 0.3s;
          }
        }

        &:not(.active):hover {
          border-color: darken($light, 8%);
        }

        &:first-of-type {
          margin-bottom: 8px;
        }

        &.active {
          background-color: $light;

          .check {
            &:after {
              background-color: $red;
            }
          }
        }
      }
    }

    .priceBlock {
      padding-bottom: 25px;
      padding-top: 25px;

      &.inside {
        padding-left: 20px;
        padding-right: 10px;
      }

      &.last {
        padding-bottom: 0;
      }

      .priceLine {
        align-items: center;
        display: flex;
        font-size: 15px;
        font-weight: 300;
        line-height: 14px;
        padding-bottom: 14px;
        border-bottom: 1px dashed rgba($main, 0.2);
        justify-content: space-between;
        text-transform: capitalize;

        & + .priceLine {
          margin-top: 13px;
        }

        &.total {
          text-transform: initial;
          padding-bottom: 0;
          border-bottom: 0;
          font-size: 18px;
          font-weight: 600;
          margin-top: 20px;
        }
      }
    }

    .notice {
      margin-top: 25px;

      .link {
        display: inline;
        color: $red;
        font-weight: 600;
      }
    }
  }

  &.status {
    .status {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 28px;

      img {
        margin-bottom: 30px;
      }

      h4 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      p {
        line-height: 26px;
        font-size: 16px;
        margin-bottom: 38px;
        max-width: 330px;
        text-align: center;
      }

      span {
        line-height: 26px;
        font-size: 12px;
        opacity: 0.4;
      }

      .sendVoucher {
        color: $red;
        cursor: pointer;
        margin-top: 23px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}
