@import 'styles/common';

.modal {
  min-width: 540px;
  max-width: 764px;

  @include tablet {
    min-width: initial;
    max-width: initial;
  }

  .contols {
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    margin: auto;

    @include tablet {
      margin: auto;
    }

    h1 {
      margin-bottom: 16px;
      padding-right: 30px;
      line-height: 42px;
      font-size: 32px;
      letter-spacing: -0.5px;
    }

    .form {
      width: 100%;
    }

    .input {
      margin: 24px 0 0;
    }

    .input {
      margin: 24px 0 0;
    }

    .button {
      margin-top: 5px;
    }

    .success {
      display: flex;
      align-items: center;
      flex-direction: column;

      h1 {
        margin: 38px 0 16px;
      }

      h5 {
        margin-bottom: 45px;
        text-align: center;
      }

      div {
        font-size: 12px;
        color: rgba($main, 0.4);
      }
    }
  }
}
