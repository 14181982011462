@import 'styles/common';

.stops {
  display: flex;
  flex-direction: column;
  // padding-bottom: 24px;
  // border-bottom: 1px solid $light;

  .stop {
    display: flex;
    align-items: stretch;
    min-height: 52px;

    @include mobile {
      min-height: 32px;
    }

    .time {
      position: relative;
      margin-right: 15px;
      width: 43px;
      padding-right: 13px;
      line-height: 16px;
      font-size: 12px;
      font-weight: 400;
      border-right: 1px dashed rgba($main, 0.2);

      .circle {
        position: absolute;
        top: 3px;
        right: -4px;
        width: 7px;
        height: 7px;
        border: 1px solid $main;
        border-radius: 50%;
        background-color: white;
      }
    }

    .destination {
      max-width: 218px;

      .address {
        line-height: 16px;
        font-weight: 600;
        margin-bottom: 4px;

        @include mobile {
          margin-bottom: 4px;
        }
      }

      .cityCountry {
        display: flex;
        align-items: center;
        font-weight: 400;
        opacity: 0.4;

        @include mobile {
          display: none;
        }
      }

      .transferText {
        display: flex;
        align-items: center;
        text-align: center;

        div {
          color: $red;
          line-height: 12px;
          font-size: 12px;
          font-weight: 400;
        }

        .tooltip {
          display: none;
          margin-left: 5px;
        }
      }
    }

    &.transfer {
      .destination {
        .cityCountry {
          @include mobile {
            color: $red;
          }
        }
      }
    }
  }

  &.oneColumn {
    .stop {
      .cityCountry {
        opacity: 1;
      }

      &:first-child,
      &:last-child {
        .destination {
          .cityCountry {
            font-weight: 600;
            font-size: 15px;

            @include mobile {
              font-size: 12px;
            }
          }
        }

        .time {
          .circle {
            background-color: $main;
          }
        }
      }

      &:last-child {
        .time {
          border-right: 1px dashed transparent;

          .circle {
            background-color: $main;
          }
        }
      }
    }
  }

  &.twoColumns {
    flex-direction: row;

    .col {
      width: 50%;

      &:first-child {
        .stop {
          &:first-child {
            .destination {
              .cityCountry {
                font-weight: 600;
                font-size: 15px;
              }
            }

            .time {
              .circle {
                background-color: $main;
              }
            }
          }
        }
      }

      &:last-child {
        .stop {
          &:last-child {
            .destination {
              .cityCountry {
                font-weight: 600;
                font-size: 15px;
              }
            }

            .time {
              border-right: 1px dashed transparent;

              .circle {
                background-color: $main;
              }
            }
          }
        }
      }
    }
  }

  &.likeMobile {
    .stop {
      min-height: 32px;

      .destination {
        .cityCountry {
          display: none;
        }

        .address {
          font-size: 12px !important;
        }
      }

      &.transfer {
        .destination {
          .cityCountry {
            color: $red;
          }
        }
      }
    }
  }
}
