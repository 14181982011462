@import 'styles/common';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    padding: 3px;

    .bubble {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: rgba($main, 0.3);
    }

    .bubble1 {
      animation: pulse 0.4s ease 0s infinite alternate;
    }

    .bubble2 {
      animation: pulse 0.4s ease 0.2s infinite alternate;
    }

    .bubble3 {
      animation: pulse 0.4s ease 0.4s infinite alternate;
    }
  }

  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0.25;
      transform: scale(0.75);
    }
  }
}
