@import 'styles/common';

.divider {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  background-color: $red;
  // height: 6px;

  .part {
    width: 9.1%;

    &:nth-child(1) {
      background-color: $tan;
    }

    &:nth-child(2) {
      background-color: $main;
    }
  }

  &.absolute {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &.vertical {
    top: 0;
    bottom: initial;
    flex-direction: column;
    height: 100%;

    .part {
      width: 100%;
      height: 9.1%;
    }
  }
}
