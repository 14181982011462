@import 'styles/common';

.search {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  @include tablet {
    align-items: stretch;
    flex-direction: column;
    margin: auto;
    width: 100%;
    max-width: 768px;
  }

  .inputs {
    display: flex;
    margin-right: 16px;
    border-radius: 2px;
    background-color: white;
    flex: 1;

    @include tablet {
      flex: initial;
      flex-wrap: wrap;
      margin: 0 0 16px;
    }

    @include mobile {
      width: 100%;
    }

    .swap {
      position: absolute;
      top: 50%;
      right: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 1;
      transform: translateY(-50%);

      i {
        font-size: 11px;
        font-weight: 400;
      }

      @include tablet {
        right: 20px;
      }

      @include mobile {
        right: 0;
        bottom: 0;
        padding: 0 16px;
        background-color: white;
        transform: translateX(0) translateY(50%);
      }
    }

    .input {
      position: relative;
      flex: 1;

      input {
        border-radius: 0;
        background-color: transparent;
      }

      &:nth-child(n + 2):not(:last-child) {
        border-right: 1px solid $light;
      }

      @include tablet {
        flex: initial;
        width: 50%;
        border-right: 0;

        &:nth-child(n + 2):not(:last-child) {
          border-right: 0;
        }

        &:nth-child(n + 3) {
          border-top: 1px solid $light;
        }

        &:nth-child(n + 3):nth-child(odd) {
          border-right: 1px solid $light;
        }
      }

      @include mobile {
        width: 50%;
        border-right: 0;

        &:nth-child(n + 2) {
          border-top: 1px solid $light;
        }

        &:nth-child(n + 3):nth-child(odd) {
          border-right: 1px solid $light;
        }
      }

      &.datePicker {
        .removeDate {
          position: absolute;
          right: 10px;
          bottom: 5px;
          padding: 10px;
          font-size: 10px;
          cursor: pointer;
          z-index: 1;
        }

        @include tablet {
          width: 100%;
        }

        @include mobile {
          width: 100%;
        }
      }

      &.passengers {
        width: 150px;

        @include tablet {
          width: 50%;
        }
      }

      &.direction {
        @include mobile {
          width: 100%;
        }
      }

      &.disabled {
        opacity: 0.7;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
