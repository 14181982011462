@import 'styles/common';
@import '~react-day-picker/lib/style.css';
@import '~react-phone-number-input/style.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~@adyen/adyen-web/dist/adyen.css';
// @import '~react-lazy-load-image-component/src/effects/blur.css';

@import 'custom/toast.scss';
@import 'custom/adyen.scss';

html,
body {
  height: 100%;
  overflow-x: hidden !important;
}

html {
  scroll-behavior: smooth;
  will-change: scroll-position;
}

body {
  margin: 0;
  color: $main;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Manrope', 'sans-serif';
  font-feature-settings: 'calt', 'liga';
  font-variation-settings: 'wght' 600;
  font-variation-ligatures: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// #root {
//   display: flex;
//   min-height: 100%;
//
//   > div {
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//   }
//
//   main {
//     flex: 1 0 auto;
//   }
// }

h1 {
  line-height: 64px;
  font-size: 54px;
  letter-spacing: -1px;

  &[data-v2] {
    font-weight: 400;
  }

  @include mobile {
    line-height: 1;
    font-size: 38px;
  }
}

h2 {
  line-height: 42px;
  font-size: 42px;
  letter-spacing: -1px;

  @include mobile {
    line-height: 30px;
    font-size: 30px;
  }
}

h3 {
  line-height: 42px;
  font-size: 32px;
  letter-spacing: -0.5px;

  @include mobile {
    line-height: 30px;
    font-size: 28px;
  }
}

h4 {
  line-height: 38px;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.5px;

  @include mobile {
    line-height: 30px;
    font-size: 24px;
  }
}

h5 {
  line-height: 30px;
  font-size: 20px;
  letter-spacing: -0.5px;

  @include mobile {
    line-height: 19px;
    font-size: 18px;
  }
}

h6 {
  line-height: 12px;
  font-size: 12px;
}

strong {
}

p {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;

  &[data-v2] {
    font-weight: 600;
  }
}

a {
  color: $main;
  text-decoration: none;
}

img {
  image-rendering: auto;
}

[disabled] {
  cursor: not-allowed;
}

input {
  font-weight: 600;
  border: 0;
  background-color: transparent;
  outline: none;
}

ol,
ul {
  margin-bottom: 32px;

  li {
    position: relative;
    padding-left: 12px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;

    & + li {
      margin-top: 12px;
    }
  }
}

ol {
  list-style-position: inside;
  list-style-type: decimal;

  li {
    padding-left: 0;
  }
}

ul {
  li {
    &::before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 5px;
      height: 5px;
      border: 1px solid $red;
      border-radius: 50%;
    }
  }
}

[data-title]:hover::after,
[data-title]:hover::before {
  opacity: 1;
  visibility: visible;
  transition: all 0.1s linear 0.5s;
}

[data-title]::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 20px;
  width: 10px;
  height: 6px;
  opacity: 0;
  visibility: hidden;
  border-right: 5px solid transparent;
  border-bottom: 6px solid white;
  border-left: 5px solid transparent;
  z-index: 1000;

  @include tablet {
    content: none;
  }
}

[data-title]::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  display: flex;
  align-items: center;
  min-height: 36px;
  // max-width: 200%;
  padding: 0 16px;
  color: $main;
  line-height: 20px;
  font-size: 13px;
  opacity: 0;
  white-space: nowrap;
  visibility: hidden;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  letter-spacing: 0;
  z-index: 999;

  @include tablet {
    content: none;
  }
}

[data-title] {
  position: relative;

  &[data-title-center] {
    &::before {
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &[data-title-top] {
    &::after {
      top: initial;
      bottom: calc(100% + 6px);
    }

    &::before {
      top: initial;
      bottom: 100%;
      transform: translateX(-50%) rotateZ(180deg);
    }
  }
}

[data-title=''] {
  &::after,
  &::before {
    content: none;
  }
}

.tickets-ui {
  h1 {
    line-height: 42px;
    font-size: 32px;
    letter-spacing: -0.5px;
  }

  h2 {
    line-height: 24px;
    font-size: 24px;
  }

  h3 {
    line-height: 30px;
    font-size: 20px;
    letter-spacing: -0.5px;
  }

  h4 {
    line-height: 20px;
    font-size: 18px;
  }

  h5 {
    line-height: 20px;
    font-size: 16px;
  }

  h6 {
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
  }
}

input::-webkit-input-placeholder {
  line-height: normal !important;
}
