@import 'styles/common';

.editEvent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 652px;

  @include mobile {
    width: 100%;
  }

  [class=DayPickerInput] {
    margin-bottom: 20px;
    max-width: 540px;
    font-weight: 400;
    border: 1px solid $light;
    border-radius: 2px;
    // z-index: 10;

    // @include mobile {
    //   width: 50%;
    //
    //   & + .datePicker {
    //     border-left: 1px solid $light;
    //   }
    // }

    [class*="--outside"] {
      color: rgba($main, 0.3);
    }

    [role='heading'] {
      margin-bottom: 22px;

      div {
        color: rgba($main, 0.7);
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }

  .input {
    margin-bottom: 20px;
    max-width: 540px;
    border: 1px solid $light;
    border-radius: 2px;
  }

  .dropzone {
    max-width: 540px;
    height: 360px;
  }

  .controls {
    display: flex;
    align-items: center;
    margin-top: 20px;

    button {
      & + button {
        margin-left: 20px;
      }
    }
  }
}
