@import 'styles/common';

.settings {
  padding-top: #{$header_height_desktop + 60px};
  background-color: $light;

  @include tablet {
    padding-top: #{$header_height_tablet + 40px};
  }

  h2 {
    margin-bottom: 60px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  section {
    .block {
      align-items: flex-start;
      display: flex;

      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        margin-left: 16px;
        width: calc(100% - (350px + 16px));

        @include tablet {
          width: 100%;
          margin-left: 0;
        }

        .header {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          padding: 0 32px;
          border-radius: 2px 2px 0 0;
          margin-bottom: 1px;

          .action {
            color: $red;
            text-transform: uppercase;
            cursor: pointer;

            &[disabled] {
              color: rgba($main, 0.7);
              cursor: not-allowed;
            }
          }
        }

        .info {
          padding: 26px 32px;
          background-color: white;
          border-radius: 0 0 2px 2px;

          @include mobile {
            padding: 24px 12px;
          }

          .block {
            display: flex;
            flex-direction: column;

            & + .block {
              margin-top: 26px;
              padding-top: 26px;
              border-top: 1px solid $light;
            }

            .field {
              margin-top: 16px;
            }

            .title {
              font-size: 16px;
              line-break: 14px;
            }

            .subTitle {
              margin-top: 6px;
              opacity: 0.75;
              font-size: 12px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
