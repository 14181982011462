@import 'styles/common';

.modal {
  min-width: 500px !important;
  max-width: 500px !important;

  @include mobile {
    min-width: initial !important;
    max-width: initial !important;
  }

  h1 {
    margin: 0 0 37px;
    font-size: 32px !important;
    line-height: 30px !important;
  }

  .notice {
    p {
      font-size: 12px;
      line-height: 14px;

      & + p {
        margin-top: 20px;
      }
    }
  }

  .link {
    color: $red;
    font-weight: 600;
  }

  .controls {
    align-items: center;
    display: flex;
    width: 100%;
    margin-top: 30px;

    .button {
      width: 100%;
      margin-right: 20px;

      @include mobile {
        & + .button {
          margin-top: 20px;
        }
      }
    }

    .cancel {
      font-size: 12px;
      line-height: 15px;
      color: $red;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
    }
  }
}
