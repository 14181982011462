@import 'styles/common';

.destinationFields {
  .inputs {
    display: flex;
    margin-bottom: 20px;

    .input {
      position: relative;
      flex: 1;
      border: 1px solid $light;

      & + .input {
        margin-left: 20px;

        @include mobile {
          margin: 20px 0 0;
        }
      }
    }
  }

  .title {
    margin-bottom: 20px;
    padding: 0 10px;
    color: rgba($main, 0.4);
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 200px;
    border: 2px dashed $light;
    border-radius: 2px;

    &.load {
      border: 0;
      background-position: center;
      background-size: cover;
    }
  }

  @include mobile {
    flex-direction: column;
  }
}
