@import 'styles/common';

.adyen-checkout__payment-method__header {
  display: none !important;
}

.adyen-checkout__payment-method__details {
  padding: 0 !important;
}

.adyen-checkout__payment-method {
  padding: 0 !important;
  list-style: none !important;

  &:before {
    content: none !important;
  }
}

.adyen-checkout__payment-method__details__content {
  margin-top: 16px !important;
}

.adyen-checkout__payment-method--card {
  border-radius: 2px !important;
  background-color: transparent !important;
  border: 0 !important;
}

.adyen-checkout__input {
  border-radius: 2px !important;
  border-color: $light !important;
  color: $main !important;
}

.adyen-checkout__button {
  background-color: $red !important;
  border-radius: 2px !important;
  margin-top: 6px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
}

.adyen-checkout__label__text {
  color: $main !important;
  padding-bottom: 6px !important;
  font-weight: 600 !important;
}

.adyen-checkout__field {
  margin-bottom: 20px !important;

  @include mobile {
    margin-bottom: 20px !important;
  }

  &.adyen-checkout__card__holderName {
    margin-top: 0 !important;
  }
}

.adyen-checkout__field-wrapper > .adyen-checkout__field:first-child {
  margin-right: 24px !important;

  @include mobile {
    margin-right: 20px !important;
  }
}
