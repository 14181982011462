@import 'styles/common';

.features {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;

  // @include mobile {
  //   justify-content: center;
  // }

  .item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 10px 20px;
    max-width: 150px;
    line-height: 28px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.5px;

    @include laptop {
      margin: 0 4px 20px;
    }

    @include tablet {
      align-items: center;
      flex-direction: row;
      margin: 0 4px 30px;
      width: calc(100% / 3 - 12px);
      max-width: initial;
    }

    @include mobile {
      align-items: flex-start;
      flex-direction: column;
      margin: 0 4px 20px;
      width: calc(50% - 16px);
      font-size: 18px;
    }

    span {
      max-width: 150px;
    }

    .circle {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;
      margin-bottom: 10px;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #f0f0f1;

      img {
        width: inherit;
      }

      @include tablet {
        margin: 0 11px 0 0;
      }

      @include mobile {
        margin: 0 0 10px;
        width: 39px;
        height: 39px;
      }
    }
  }
}
