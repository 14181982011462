@import 'styles/common';

.trip {
  position: relative;
  // margin-top: 8px; // TODO: check this prop
  font-size: 12px;
  border-radius: 4px;
  background-color: white;

  @include mobile {
    margin: auto;
    max-width: 700px;
  }

  &:last-child {
    margin-bottom: 50px;
  }

  .decorativeСircle {
    position: absolute;
    right: 239px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $light;

    &.top {
      // top: -11px;

      // @include mobile {
      // top: initial;
      bottom: 46px;
      left: -8px;
      transform: rotateZ(-90deg);
      // }

      @include mobile {
        bottom: -109px;
        left: -10px;
      }
    }

    &.bottom {
      // bottom: -11px;
      // @include mobile {
      // top: initial;
      right: -11px;
      bottom: 46px;
      transform: rotateZ(-90deg);
      // }

      @include mobile {
        right: -10px;
        bottom: -109px;
      }
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: -1px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    padding: 0 16px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 1px;
    background-color: $red;
    transform: translateY(-50%);

    @include mobile {
      transform: translateY(-100%);
    }
  }

  .tripContent {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .main {
      // border-right: 1px dashed rgba($main, 0.2);
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 24px;
      border-radius: 0 4px 4px 0;

      @include mobile {
        position: relative;
        padding: 12px 12px 0;
      }

      .timing {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        @include mobile {
          margin-bottom: 4px;
        }

        .stop {
          text-transform: uppercase;

          div {
            line-height: 18px;
          }

          .name {
            margin-bottom: 2px;
            letter-spacing: 1px;
          }

          .time {
            height: 20px;
            font-size: 24px;

            @include mobile {
              font-size: 20px;
            }
          }

          .date {
            text-transform: uppercase;
            letter-spacing: 0;
          }

          &.from {
            margin-right: 23px;

            @include mobile {
              margin-right: 12px;
            }
          }

          &.to {
            margin-left: 23px;
            text-align: right;

            @include mobile {
              margin-left: 12px;
            }
          }
        }

        .logo {
          margin: 0 15px;
          height: 20px;

          @include mobile {
            margin: 0 5px;
            height: 15px;
          }

          span {
            height: 100%;
          }

          img {
            height: 100%;
          }
        }

        .divider {
          display: flex;
          flex: 1;
          align-items: center;

          .circle {
            width: 7px;
            height: 7px;
            border: 1px solid rgba($main, 0.2);
            border-radius: 50%;

            &.black {
              margin-right: 4px;
              width: 5px;
              height: 5px;
              background-color: $main;
            }
          }

          .line {
            flex: 1;
            height: 1px;
            background-color: rgba($main, 0.2);
          }

          &.reverse {
            flex-direction: row-reverse;
          }
        }
      }

      .direction {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        min-height: 19px;

        @include mobile {
          padding-bottom: 16px;
          border-bottom: 1px solid $light;
        }

        .stop {
          width: calc(50% - 45px);
          line-height: 18px;
          font-size: 12px;
          text-transform: uppercase;

          @include mobile {
            line-height: 12px;
          }

          .name {
            line-height: 18px;
            font-size: 14px;
            letter-spacing: 1px;
          }

          .date {
            line-height: 18px;
          }

          &.to {
            padding-left: 5px;
            text-align: right;
          }

          &.from {
            padding-right: 5px;
          }
        }

        .duration {
          line-height: 18px;
          text-align: center;
          white-space: nowrap;

          .direct {
            font-weight: 300;
          }

          @include mobile {
            display: none;
          }
        }
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .seatNumber {
          display: flex;
          align-items: center;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;

          i {
            margin-right: 8px;
            color: $tan;
            font-size: 16px;
          }
        }

        .departureDate {
          font-size: 12px;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }

      .notification {
        margin-top: 20px;
        color: $red;
        line-height: 12px;
        font-size: 12px;
        font-weight: 400;

        i {
          margin-right: 8px;
        }
      }
    }

    .controls {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 168px;
      padding: 24px;
      border-radius: 4px 0 0 4px;

      @include mobile {
        flex: 1;
        align-items: center;
        flex-direction: column-reverse;
        width: auto;
        padding: 16px 14px 20px;
        // border-top: 1px solid $light;

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          width: 100%;

          .duration {
            margin-right: 10px;

            .direct {
              font-weight: 300;
            }
          }
        }
      }

      .showDetails {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-left: 8px;
          width: 10px;
          height: 6px;
        }

        &.show {
          img {
            transform: rotate(180deg);
          }
        }
      }

      .userName {
        margin-bottom: 16px;
        font-size: 14px;
        text-align: center;
        text-transform: capitalize;

        @include mobile {
          margin-top: 20px;
        }
      }

      .buttonGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        width: 120px;
        height: 40px;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        border-radius: 2px;
        background: $main
          radial-gradient(farthest-side, rgba(#5d616c, 1), transparent 100%)
          fixed no-repeat;
        background-size: 0 0;

        &[disabled] {
          cursor: not-allowed;
          user-select: none;
          background: rgba($main, 0.7);
        }

        @include mobile {
          margin-bottom: 0;
          width: 100%;
        }

        &:hover {
          background-size: 120px 120px;

          @include tablet {
            background-size: 0;
          }
        }

        .line {
          width: 1px;
          height: 16px;
          background-color: rgba(#fff, 0.2);
        }

        div {
          width: 50%;
          text-align: center;
        }
      }

      .bookingNumber {
        line-height: 18px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;

        span {
          text-transform: initial;
          opacity: 0.7;
        }
      }
    }
  }

  .details {
    padding: 5px 24px 24px;

    @include mobile {
      padding: 5px 12px 12px;
    }

    h5 {
      margin-bottom: 20px;
    }

    .stops {
      padding-bottom: 24px;
      border-bottom: 1px solid $light;

      @include mobile {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    .features {
      display: flex;

      .block {
        width: 50%;

        .busInformation {
          display: flex;
          flex-wrap: wrap;
          margin-top: -7px;

          .img {
            margin: 7px;
            width: 28px;
            height: 28px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        p {
          padding-right: 24px;
          line-height: 18px;
          font-size: 11px;
        }
      }
    }
  }

  .footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    padding: 0 24px;

    @include mobile {
      align-items: initial;
      flex-direction: column;
      height: initial;
      padding: 0;
    }

    .divider {
      position: absolute;
      top: 0;
      right: 20px;
      bottom: initial;
      left: 20px;
      width: initial;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        rgba($main, 0.2) 50%,
        transparent 50%
      );
      background-repeat: repeat-x;
      background-size: 10px 1px;
    }

    .item {
      display: flex;
      align-items: center;
      font-size: 12px;
      white-space: nowrap;

      img {
        margin-right: 8px;
        height: 16px;

        @include mobile {
          margin: 0 0 7px;
        }
      }

      &.pass {
        i {
          margin-right: 8px;
          color: $tan;
        }
      }

      &.bn {
        height: 30px;
        padding: 0 10px;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.25px;
        border-radius: 2px;
        background-color: $light;

        span {
          margin-right: 6px;
          opacity: 0.5;
        }
      }

      &.action {
        cursor: pointer;
      }
    }

    .info {
      display: contents;

      &.top {
        .item {
          &:last-child {
            margin-right: auto;
            margin-left: 24px;

            @include mobile {
              margin-right: initial;
            }
          }
        }
      }

      &.bot {
        .item {
          font-size: 13px;

          &:nth-child(n + 2) {
            // &:last-child {
            margin-left: 24px;

            @include laptop {
              margin-left: 14px;
            }

            @include tablet {
              margin-left: 20px;
            }

            @include mobile {
              margin-right: auto;
            }
          }
        }
      }

      @include mobile {
        display: flex;
        align-items: center;

        &.top {
          display: flex;
          justify-content: space-between;
          padding: 16px;
        }

        &.bot {
          display: flex;
          align-items: center;
          padding: 4px 0 20px;
          overflow-x: auto;

          @include mobile {
            justify-content: space-between;
          }

          .item {
            @include mobile {
              flex-direction: column;
              width: 25%;
              min-width: 80px;

              & + .item {
                margin-left: 30px;
              }

              &:last-child {
                padding-right: 15px;
              }

              &:first-child {
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
  }

  & + .ticket {
    margin-top: 26px;

    @include mobile {
      margin-top: 32px;
    }
  }

  &.promo {
    border: 1px solid $red;

    .decorativeСircle {
      background-color: $red;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 10px;
        background-color: $light;
        z-index: 1;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $light;
      }

      &.top {
        &::after {
          top: 0;
        }
      }

      &.bottom {
        &::after {
          bottom: 0;
        }
      }
    }

    .controls {
      .buttonGroup {
        background-color: $red;
      }
    }
  }

  &.lounge {
    border: 1px solid $tan;

    .decorativeСircle {
      background-color: $tan;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 10px;
        background-color: $light;
        z-index: 1;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $light;
      }

      &.top {
        &::after {
          top: 0;
        }
      }

      &.bottom {
        &::after {
          bottom: 0;
        }
      }
    }

    .label {
      background-color: $tan;
    }
  }
}
