@import 'styles/common';

.notFound {
  flex: 1;

  .title {
    margin: 0 0 60px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  section {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;

    .links {
      display: flex;
      align-items: center;
      padding: 10px;

      .link {
        margin: 10px;
        font-size: 14px;
      }
    }
  }
}
