@import 'styles/common';

.thanksLayout {
  display: flex;
  justify-content: flex-start;
  margin-top: 72px;
  background-color: white !important;

  @include tablet {
    margin-top: 57px;
  }

  .greyBlock {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 39vw;
    height: 36vh;
    background-color: $light;
    z-index: 0;

    @include tablet {
      display: none;
    }
  }

  .container {
    margin-top: 0;
    width: 100%;
  }

  section {
    position: relative;
    max-width: 650px;
    padding: 100px 0 125px;

    @include tablet {
      padding: 50px 0 0;
    }

    h1 {
      margin-bottom: 16px;
      color: $red;
    }

    .orderNumber {
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid $light;
    }

    .content {
      position: relative;
      margin-top: 32px;
      padding-top: 32px;
      padding-left: 34px;
      border-top: 1px solid $light;

      .icon {
        position: absolute;
        top: 35px;
        left: 0;
        width: 18px;
        height: 18px;
      }

      h2 {
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 32px;
      }

      .controls {
        display: flex;
        align-items: flex-start;
        margin-bottom: 38px;

        @include mobile {
          flex-wrap: wrap;
        }

        .button {
          @include mobile {
            min-width: 128px;
          }

          &:first-child {
            margin-right: 20px;

            @include mobile {
              margin-bottom: 20px;
            }
          }
        }
      }

      .loading {
        justify-content: flex-start;
      }
    }
  }
}
