@import 'styles/common';

.emptySlider {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 50px;

  .title {
    margin-bottom: 20px;
    color: rgba($main, 0.3);
    font-size: 100px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 20px;

    @include mobile {
      font-size: 55px;
    }
  }
}
