@import 'styles/common';

.notice {
  display: flex;
  align-items: flex-start;
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 2px;
  // background-color: white;

  .message {
    margin-left: 10px;
  }

  &.main {
    color: $main;
  }

  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }

  &.yellow {
    color: $tan;
  }
}
