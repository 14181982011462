@import 'styles/common';

.passengerDetails {
  .breadcrumbs {
    @extend %breadcrumbs;
  }

  .content {
    section {
      display: flex;
      align-items: flex-start;

      .tripDetails {
        width: 372px;

        @include laptop {
          width: 320px;
        }

        @include tablet {
          visibility: hidden;
        }

        .block {
          background-color: white;
          padding: 24px 30px;
          margin-bottom: 16px;
          position: relative;
          overflow: hidden;
          border-radius: 2px;

          img {
            position: absolute;
            right: 0%;
            bottom: 0;
            height: 92%;
            z-index: 0;

            @include laptop {
              height: 75%;
            }
          }

          .register {
            position: relative;
            z-index: 1;

            h1 {
              color: $red;
              margin-bottom: 14px;
              font-size: 24px;
              line-height: 28px;
            }

            p {
              margin-bottom: 20px;
              font-size: 13px;
              line-height: 18px;
              max-width: 182px;
            }

            .controls {
              display: flex;
              align-items: center;

              .button {
                margin-right: 23px;
                min-width: 81px;
                height: 40px;
                padding: 0 20px;
              }

              .link {
                color: $red;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .forms {
        margin-right: 20px;
        flex: 1;

        @include tablet {
          margin-right: 0;
        }

        .formBlock {
          padding: 24px 32px;
          border-radius: 2px;
          background-color: white;

          @include mobile {
            border-radius: 0;
            margin: 0 -#{$container_padding_mobile};
            padding: 24px $container_padding_mobile;
          }

          & + .formBlock {
            margin-top: 24px;
          }

          .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            transition: 0.25s;
            margin-bottom: 24px;

            h3 {
              i {
                margin-left: 8px;
                font-size: 17px;
                color: $green;
              }
            }

            .toggle {
              cursor: pointer;
              align-items: center;
              display: flex;
              flex: 1;
              justify-content: space-between;
              font-size: 16px;
              line-height: 20px;

              @include mobile {
                flex-direction: column;
              }

              .passengerInfo {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                @include mobile {
                  align-items: flex-start;
                  margin-top: 7px;
                }

                .passengerAge {
                  font-size: 15px;
                  font-weight: 400;
                  color: rgba($main, 0.7);
                }
              }
            }

            i {
              margin-top: 12px;
              font-size: 8px;
              margin-left: 16px;
              transition: 0.3s;
            }

            &.open {
              margin-bottom: 24px;

              i {
                transform: rotate(180deg);
              }
            }

            &.close {
              margin-bottom: 0;

              i {
                transform: rotate(0);
              }
            }
          }

          .seats {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 24px;
            border-top: 1px solid $light;
            margin-bottom: 20px;

            .seat {
              display: flex;
              width: calc(50% - 12px);

              @include mobile {
                width: 100%;
                // justify-content: space-between;

                & + .seat {
                  margin-top: 32px;
                }
              }

              &:nth-child(n + 3) {
                margin-top: 32px;
              }

              // &:nth-child(even) {
              //   margin-left: 32px;
              // }

              .mainInfo {
                display: flex;
                flex-direction: column;
                line-height: 20px;
                font-size: 15px;
                margin-right: 24px;

                .fromTo {
                  font-weight: 400;
                }
              }

              .seatNuber {
                flex-shrink: 0;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 137px;
                border-radius: 2px;
                background-color: $light;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 12px;
                line-height: 18px;

                i {
                  margin-right: 8px;
                  color: $tan;
                  font-size: 16px;
                }
              }
            }
          }

          .form {
            display: flex;
            flex-direction: column;

            .notice {
              margin-bottom: 20px;
            }

            .row {
              display: flex;
              justify-content: space-between;
              margin: 0 -10px;

              .input {
                flex: 1;
                margin: 0 10px;
              }

              @include mobile {
                flex-direction: column;
              }
            }

            .dateOfBirth {
              display: flex;
              flex-direction: column;
              padding-bottom: 22px;

              .selects {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .input + .input {
                  margin-left: 20px;
                }

                .select {
                  flex: 1;
                }

                .select + .select {
                  margin-left: 20px;
                }
              }

              label {
                margin-bottom: 6px;
                color: $main;
                line-height: 14px;
                font-size: 12px;
              }

              .message {
                margin-top: 6px;
                line-height: 16px;
                font-size: 12px;
                letter-spacing: 1px;
              }

              &.error {
                padding-bottom: 0;

                // select {
                //   border-color: $red;
                // }

                .message {
                  color: $red;
                }
              }
            }

            .radios {
              align-items: center;
              display: flex;
              flex: 100%;
              margin: 0 10px 22px;

              .radio + .radio {
                margin-left: 32px;
              }
            }
          }
        }
      }
    }
  }
}
