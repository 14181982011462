@import 'styles/common';

.overlayWrapper {
  position: absolute;
  outline: none;
  z-index: 10;

  .overlay {
    position: relative;
    top: 13px;
    border-radius: 2px;
    outline: none;

    @include tablet {
      top: 0;
    }

    .wrap {
      position: absolute;
      bottom: calc(100% + -1px);
      left: 14px;
      filter: drop-shadow(0 -2px 1px rgba(0, 0, 0, 0.15));

      @include tablet {
        display: none;
      }

      .triangle {
        width: 22px;
        height: 12px;
        background-color: white;
        // clip-path: polygon(50% 100%, 0 0, 100% 0); // bottom
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%); // top
      }
    }
  }

  &.right {
    @include mobile {
      left: 100%;
      transform: translateX(-100%);

      .overlay {
        .wrap {
          left: 95px;
        }
      }
    }
  }

  [class='DayPicker-wrapper'] {
    outline: none;
  }

  [class*='--outside'] {
    color: rgba($main, 0.3);
  }

  [role='heading'] {
    display: none;
  }

  [role='gridcell'] {
    &:hover {
      background-color: rgba($light, 0.25);
    }

    &[class*='DayPicker-Day'] {
      font-size: 14px;
      cursor: pointer;
      border-radius: 2px;

      &:hover {
        background-color: $light !important;
      }
    }

    &[class*='--today'] {
      color: inherit;
      font-weight: 600;
    }

    &[class*='--selected'] {
      color: white !important;
      font-weight: 600;
      background-color: $tan !important;

      &:hover {
        background-color: $tan !important;
      }
    }

    &[class*='disabled'] {
      color: #dce0e0;
      font-weight: 400;
      pointer-events: none;
    }
  }
}

.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0;
  color: rgba($main, 0.7);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;

  .arrow {
    color: $red;
    cursor: pointer;
  }
}

.weekdayName {
  color: $main !important;
  font-size: 14px !important;
  font-weight: 600;
  text-transform: uppercase;
}
