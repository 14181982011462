@import 'styles/common';

.nav {
  display: flex;
  align-items: stretch;
  padding-right: 25px;
  border-bottom-left-radius: 2px;
  background-color: white;

  @include tablet {
    display: none;
  }

  &.ticketPages {
    @include tablet {
      display: flex;
    }

    @include mobile {
      display: none;
    }
  }

  .section {
    display: flex;
    align-items: center;
    padding: 0 30px;

    & + .section {
      border-left: 1px solid #f0f0f1;
    }
  }

  .item {
    position: relative;
    line-height: 15px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
    cursor: pointer;

    & + .item {
      margin-left: 40px; // <- in desing but nav does not fit when window.innerWidth = 1025px
      margin-left: 30px;
    }

    img {
      position: relative;
      top: -1px;
      margin-left: 6px;
      width: 10px;
      height: 6px;
      transition: 0.3s;
      transform-origin: center;
    }

    .wrapList {
      position: absolute;
      top: 100%;
      left: -12px;
      display: none;
      padding-top: 11px;

      @keyframes showNav {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      .list {
        // max-width: 204px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 24px 16px;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15),
          0 1px 3px 0 rgba(0, 0, 0, 0.2);

        .wrap {
          position: absolute;
          bottom: 100%;
          left: 14px;
          filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.15));

          .triangle {
            width: 14px;
            height: 7px;
            background-color: white;
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          }
        }
      }
    }

    .link {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;

      & + .link {
        margin-top: 16px;
      }

      &.important {
        align-items: center;
        display: flex;
        color: $red;

        i {
          margin-right: 9px;
        }
      }

      &:not([disabled]):hover {
        color: $red;
      }

      &[disabled],
      &[disabled]:hover {
        color: rgba($main, 0.7);
        cursor: not-allowed;
        // pointer-events: none;
      }
    }

    .user {
      align-items: center;
      display: flex;

      .avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: $tan;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0;
        line-height: initial;
        background-size: cover;
        background-position: center;
      }

      .wrapList {
        .list {
          .wrap {
            bottom: 99%;
            left: 26px;
          }
        }
      }
    }

    &:hover {
      img {
        transform: rotateZ(180deg);
      }

      .wrapList {
        display: block;
        animation: showNav 250ms ease-in-out both;
      }
    }

    &.active {
      color: #c30909;
    }
  }
}
