@import 'styles/common';

.savedCards {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid $light;
  padding: 20px;
  margin-bottom: 20px;

  .status {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
      margin-right: 20px;
    }

    i {
      font-size: 40px;
      margin-right: 20px;
    }

    &.error {
      color: $red;
    }

    &.success {
      color: $green;
    }
  }

  h3 {
    margin-bottom: 10px;
  }

  .noCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.5;

    h2 {
      margin-bottom: 24px;
    }

    p {
      text-align: center;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    & + .card {
      border-top: 1px solid $light;
    }

    .catdTitle {
      height: 55px;
      align-items: center;
      display: flex;

      .check {
        height: 18px;
        width: 18px;
        position: relative;
        border: 1px solid $light;
        border-radius: 50%;
        margin-right: 15px;

        &:after {
          position: absolute;
          top: 3px;
          left: 3px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: $red;
        }

        &.checked {
          &:after {
            content: '';
          }
        }
      }

      .logo {
        margin-right: 11px;
      }

      .name {
        margin-right: 15px;
      }

      .controls {
        margin-left: auto;

        i {
          transition: 0.3s;
          font-size: 14px;
          color: $red;
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 2px;

          & + i {
            margin-left: 20px;
          }
        }

        &.confirmRemove {
          i {
            &.remove {
              background-color: $red;
              color: white;
            }

            &.cancel {
              background-color: $light;
              color: $main;
            }
          }
        }
      }
    }
  }
}
