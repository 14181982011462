@import 'styles/common';

.switch {
  position: relative;
  display: inline-block;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .checkmark {
    position: relative;
    width: 38px;
    height: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgba($main, 0.25);
    background-color: $light;
    transition: 0.15s;
  }

  .checkmark::after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: $main;
    transition: 0.15s;
    transform: translateY(50%);
  }

  input:checked ~ .checkmark::after {
    background-color: $red;
    transform: translateY(50%) translateX(19px);
  }
}
