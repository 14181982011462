@import 'styles/common';

.slider {
  position: relative;

  .wrap {
    position: relative;
  }

  .slides {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
    scrollbar-width: none; // for firefox

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .controls {
    display: flex;
    align-items: center;
    margin-top: 30px;
    height: 16px;
    font-size: 12px;
    white-space: nowrap;

    .count,
    .divider {
      margin: 0 28px;
    }

    .divider {
      margin: 0 15px;
      width: 1px;
      height: 10px;
      background-color: rgba($main, 0.3);
    }

    .button {
      display: flex;
      color: $red;
      text-transform: uppercase;
      cursor: pointer;
      transition: color 0.15s;

      &.prev {
        i {
          margin-right: 13px;
          position: relative;
          right: 0;
          transition: right 0.15s;
        }

        &:hover {
          i {
            right: 5px;
          }
        }
      }

      &.next {
        i {
          margin-left: 13px;
          position: relative;
          left: 0;
          transition: left 0.15s;
        }

        &:hover {
          i {
            left: 5px;
          }
        }
      }

      &[disabled] {
        color: rgba($main, 0.3);
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }

  .indicators {
    display: flex;
    align-items: center;

    .indicatorButton {
      color: $tan;
    }
  }

  &.withPadding {
    .slides {
      position: relative;
      left: 50%;
      width: 100vw;
      padding-left: calc((100vw - 1440px) / 2 + #{$container_padding_desktop});
      transform: translate(-50%);

      @include laptop {
        padding-left: $container_padding_laptop;
      }

      @include tablet {
        padding-left: $container_padding_tablet;
      }

      @include mobile {
        padding-left: $container_padding_mobile;
      }
    }
  }
}
