@import 'styles/common';

.modal {
  min-width: 500px;
  max-width: 764px;

  @include mobile {
    min-width: initial;
  }

  h2 {
    color: $red;
  }
}
