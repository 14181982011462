@import 'styles/common';

.buyTicketPromo {
  .title {
    color: $red;
  }

  section {
    position: relative;
    padding: 80px 110px;
    background-color: $light;

    @include mobile {
      padding: 0;
      background-color: transparent;
    }

    .map {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;

      @include mobile {
        display: none;
      }

      span {
        display: block;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    p {
      margin: 10px 0 27px;
      max-width: 50%;

      @include mobile {
        max-width: 100%;
      }
    }
  }
}
