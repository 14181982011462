@import 'styles/common';

.cardInput {
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;

  label {
    margin-bottom: 6px;
    color: $main;
    line-height: 14px;
    font-size: 12px;
  }

  .input {
    position: relative;

    .cards {
      position: absolute;
      right: 16px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;

      .card {
        & + .card {
          margin-left: 10px;
        }
      }
    }

    input {
      width: 100%;
      height: 40px;
      padding: 0 16px;
      color: $main;
      font-size: 15px;
      font-weight: 400;
      border: 1px solid $light;
      letter-spacing: 0;
      border-radius: 2px;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px white inset;
        -webkit-text-fill-color: black;
      }

      &::placeholder {
        color: rgba($main, 0.4);
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0;
      }
    }
  }

  .message {
    margin-top: 6px;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &.withoutMessage {
    padding-bottom: 0;
  }

  &.error {
    padding-bottom: 0;

    input {
      border-color: $red;
    }

    .message {
      color: $red;
    }
  }
}
