@import 'styles/common';

.tabs {
  display: flex;
  // flex: 1;
  flex-direction: column;

  .tabsPanel {
    display: flex;
    flex-shrink: 0;
    margin-bottom: 8px;
    overflow-x: auto;

    // @include mobile {
    //   scroll-snap-type: x mandatory;
    // }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .content {
    display: none;
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // visibility: hidden;
    // opacity: 0;

    // display: none;
    // opacity: 0;
    // transition: 0.5s;
    //
    // &.show {
    //   display: block;
    //   opacity: 1;
    // }

    // transition: 0.5s;

    &.active {
      display: block;
      // opacity: 1;
      // visibility: visible;
    }
  }
}

.container {
  // position: relative;
  // background-color: white;
  // padding: 10px 20px;
  // border: 1px solid #F1F2F5;
  // border-radius: 4px;
}

.tab {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px;
  color: $main;
  cursor: pointer;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid $red;

  // @include mobile {
  //   scroll-snap-align: start;
  // }

  .tabText {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;

    img {
      margin-right: 12px;
    }
  }

  &.active {
    background-color: $red;

    .tabText {
      color: white;
    }
  }

  &:first-of-type {
    &.active {
      // border-radius: 0 4px 0 0;
      // border-left: 0;
    }
  }

  &:last-of-type {
    &.active {
      // border-radius: 4px 0 0;
      // border-right: 0;
    }
  }

  &[disabled] {
    cursor: not-allowed;

    .tabText {
      color: rgba($main, 0.3);

      img {
        opacity: 0.3;
      }
    }
  }
}
