@import 'styles/common';

.faqAnswer {
  .title {
    margin: 0 0 60px;
    padding-top: 40px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent),
      url('/images/faq/banner.jpg');
    background-size: cover;

    .banner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      max-width: 978px;
      min-height: 550px;
      padding-bottom: 40px;

      @include mobile {
        min-height: 400px;
      }

      h1 {
        margin-bottom: 45px;
        color: white;
      }

      .search {
        display: flex;

        @include mobile {
          flex-direction: column;
        }

        .button {
          margin-left: 20px;

          @include mobile {
            margin: 20px 0 0;
          }
        }
      }
    }
  }

  .answer {
    position: relative;

    .greyBlock {
      right: 0;
      bottom: -$main_padding_bottom;
      width: 37%;
      height: 295px;
    }

    section {
      max-width: 652px;
      padding-top: 60px;

      .back {
        text-transform: uppercase;

        i {
          margin-right: 13px;
          font-size: 12px;
        }
      }

      h5 {
        margin-bottom: 32px;
      }

      .article {
        max-width: 100%;

        img {
          max-width: 100%;
        }

        p + p {
          margin-top: 32px;
        }

        strong {
          margin-bottom: 5px;
        }
      }
    }
  }

  .links {
    // TODO: common component
    display: flex;
    align-items: center;
    margin-top: 105px;

    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      font-size: 16px;
      background-color: $light;

      & + .link {
        margin-left: 16px;
      }
    }
  }
}
