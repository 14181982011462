@import 'styles/common';

$list: $red, #dc5b78, #7c54af, #985277;
$colorKey: randomNumberBetween(1, length($list));
$color: nth($list, $colorKey);

$mainColor: #7c54af;

.operationalNotifications {
  position: absolute;
  top: 0;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $mainColor;
  width: 100%;
  left: 0;
  padding: 0 25px;
  z-index: 100;

  @include mobile {
    padding: 0 20px;
  }

  .marquee {
    overflow: auto;
    white-space: nowrap;
    margin-right: 30px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
  }

  .icon {
    margin-right: 12px;
  }

  .readMore {
    background-color: rgba(white, 0.2);
    color: white;
    border-radius: 2px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    height: 30px;
    font-weight: 600;
    font-size: 11px;
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: uppercase;
    flex-shrink: 0;
    transition: 0.3s;

    &:hover {
      background-color: rgba(white, 0.3);
    }
  }

  .notification {
    position: fixed;
    right: -100%;
    bottom: 20px;
    border-radius: 20px;
    overflow: hidden;
    width: 364px;
    max-width: calc(100vw - 40px);
    z-index: 10;
    box-shadow: 0 3px 20px 2px rgba($main, 0.2);
    animation: show 0.75s ease-in forwards;

    &.hide {
      animation: hide 0.5s ease-out forwards;
      // right: 20px;
    }

    @keyframes show {
      0% {
        right: -100%;
      }

      70% {
        right: 26px;
      }

      85% {
        right: 16px;
      }

      100% {
        right: 20px;
      }
    }

    @keyframes hide {
      0% {
        right: 20px;
      }

      100% {
        right: -100%;
      }
    }

    .content {
      background-color: white;
      padding: 30px 38px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      @include mobile {
        padding: 15px 19px;
      }
    }

    .header {
      background-color: $mainColor;
      position: relative;
      padding: 30px 38px;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.5px;
      color: white;

      @include mobile {
        padding: 15px 40px 15px 19px;
        font-size: 18px;
        line-height: 27px;
      }

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 10px;
        line-height: 1;
        cursor: pointer;
        color: white;
        padding: 5px;

        @include mobile {
          top: 19px;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 38px 30px;
      background-color: white;

      @include mobile {
        padding: 0 15px 19px;
      }

      .logo {
        @include mobile {
          height: 22px;
        }
      }

      .readMore {
        background: #f5f5f5;
        border-radius: 2px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        height: 40px;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: $main;
        margin-left: 10px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: darken(#f5f5f5, 5%);
        }
      }
    }
  }
}
