@import 'styles/common';

[class*='ReactCollapse--collapse'] {
  transition: height 200ms;
}

.touchedTicket {
  position: relative;
  // margin-top: 8px; // TODO: check this prop
  font-size: 12px;
  border-radius: 4px;
  background-color: white;

  .decorativeСircle {
    position: absolute;
    right: 239px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $light;

    &.top {
      top: -11px;

      @include mobile {
        top: initial;
        bottom: -8px;
        left: -11px;
        transform: rotateZ(-90deg);
      }
    }

    &.bottom {
      bottom: -11px;

      @include mobile {
        top: initial;
        right: -11px;
        bottom: -8px;
        transform: rotateZ(-90deg);
      }
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: -1px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    padding: 0 16px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 1px;
    background-color: $red;
    transform: translateY(-50%);

    @include mobile {
      transform: translateY(-100%);
    }
  }

  .ticketContent {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .main {
      flex: 1;
      padding: 24px;
      border-radius: 0 4px 4px 0;
      // border-right: 1px dashed rgba($main, 0.2);
      display: flex;
      flex-direction: column;

      @include mobile {
        position: relative;
        padding: 12px 12px 20px;
      }

      .timing {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        @include mobile {
          margin-bottom: 4px;
        }

        .stop {
          text-transform: uppercase;

          div {
            line-height: 18px;
          }

          .name {
            margin-bottom: 2px;
            letter-spacing: 1px;
          }

          .time {
            height: 20px;
            font-size: 24px;

            @include mobile {
              font-size: 20px;
            }
          }

          &.from {
            margin-right: 23px;

            @include mobile {
              margin-right: 12px;
            }
          }

          &.to {
            margin-left: 23px;
            text-align: right;

            @include mobile {
              margin-left: 12px;
            }
          }
        }

        .logo {
          margin: 0 15px;
          height: 20px;

          @include mobile {
            margin: 0 5px;
            height: 15px;
          }

          span {
            height: 100%;
          }

          img {
            height: 100%;
          }
        }

        .divider {
          display: flex;
          flex: 1;
          align-items: center;

          .circle {
            width: 7px;
            height: 7px;
            border: 1px solid rgba($main, 0.2);
            border-radius: 50%;

            &.black {
              margin-right: 4px;
              width: 5px;
              height: 5px;
              background-color: $main;
            }
          }

          .line {
            flex: 1;
            height: 1px;
            background-color: rgba($main, 0.2);
          }

          &.reverse {
            flex-direction: row-reverse;
          }
        }
      }

      .direction {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 26px;
        border-bottom: 1px solid $light;
        min-height: 19px;

        @include mobile {
          margin-bottom: 18px;
          padding-bottom: 0;
          border-bottom: 0;
        }

        .stop {
          line-height: 18px;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          width: calc(50% - 45px);

          @include mobile {
            line-height: 12px;
          }

          .name {
            letter-spacing: 1px;
            font-size: 14px;
            line-height: 18px;
          }

          .date {
            letter-spacing: 0;
            line-height: 18px;
          }

          &.to {
            padding-left: 5px;
            text-align: right;
          }

          &.from {
            padding-right: 5px;
          }
        }

        .duration {
          text-align: center;
          text-transform: uppercase;
          width: 90px;
          line-height: 18px;
        }
      }

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .seatNumber {
          align-items: center;
          display: flex;
          font-size: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;

          i {
            color: $tan;
            margin-right: 8px;
            font-size: 16px;
          }
        }

        // .departureDate {
        //   text-transform: uppercase;
        //   font-size: 12px;
        //   letter-spacing: 1px;
        //   text-align: center;
        // }

        .showDetails {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-left: 8px;
            width: 10px;
            height: 6px;
          }

          &.show {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }

      .notification {
        margin-top: 20px;
        color: $red;
        line-height: 12px;
        font-size: 12px;
        font-weight: 400;

        i {
          margin-right: 8px;
        }
      }
    }

    .controls {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 196px;
      padding: 23px;
      border-radius: 4px 0 0 4px;

      @include mobile {
        flex: 1;
        align-items: center;
        width: auto;
        padding: 20px 14px;
      }

      .divider {
        position: absolute;
        top: 12px;
        bottom: 12px;
        left: -1px;
        width: 1px;
        background-image: linear-gradient(
          rgba($main, 0.2) 50%,
          transparent 50%
        );
        background-repeat: repeat-y;
        background-size: 1px 10px;

        @include mobile {
          top: 0;
          right: 20px;
          bottom: initial;
          left: 20px;
          width: initial;
          height: 1px;
          background-image: linear-gradient(
            90deg,
            rgba($main, 0.2) 50%,
            transparent 50%
          );
          background-repeat: repeat-x;
          background-size: 10px 1px;
        }
      }

      .userName {
        font-size: 12px;
        margin-bottom: 20px;
        padding-left: 10px;

        .text {
          opacity: 0.5;
          line-height: 14px;
        }

        span {
          text-transform: uppercase;
          line-height: 22px;
          letter-spacing: 1px;
        }

        @include mobile {
          margin-top: 20px;
        }
      }

      .buttonGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 2px;
        background: $main
          radial-gradient(farthest-side, rgba(#5d616c, 1), transparent 100%)
          fixed no-repeat;
        background-size: 0 0;
        margin-bottom: 16px;

        &[disabled] {
          cursor: not-allowed;
          background: rgba($main, 0.7);
          user-select: none;
        }

        @include mobile {
          width: 200px;
        }

        &:not([disabled]):hover {
          background-size: 120px 120px;

          @include tablet {
            background-size: 0;
          }
        }

        &:not([disabled]):active {
          background-color: #5d616c;
        }

        .line {
          width: 1px;
          height: 16px;
          background-color: rgba(#fff, 0.2);
        }

        div {
          width: 50%;
          text-align: center;
        }
      }

      .ticketNumber {
        font-size: 13px;
        font-weight: 600;
        text-align: center;
        line-height: 18px;
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 2px;
        background-color: $light;
        padding: 0 10px;
        letter-spacing: 0.25px;

        span {
          text-transform: initial;
          // font-weight: 300;
          margin-right: 6px;
          opacity: 0.5;
          // text-transform: uppercase;
        }
      }
    }
  }

  .details {
    padding: 5px 24px 24px;

    @include mobile {
      padding: 5px 12px 12px;
    }

    h5 {
      margin-bottom: 20px;
    }

    .stops {
      padding-bottom: 24px;
      border-bottom: 1px solid $light;

      @include mobile {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    .features {
      display: flex;
      // margin-top: 24px;

      // @include mobile {
      //   display: none;
      // }

      .block {
        // width: 50%;

        .busInformation {
          display: flex;
          flex-wrap: wrap;
          margin-top: -7px;

          .img {
            margin: 7px;
            width: 28px;
            height: 28px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        p {
          padding-right: 24px;
          line-height: 18px;
          font-size: 11px;
        }
      }
    }
  }

  & + .ticket {
    margin-top: 26px;

    @include mobile {
      margin-top: 32px;
    }
  }

  &.promo {
    border: 1px solid $red;

    .decorativeСircle {
      background-color: $red;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 10px;
        background-color: $light;
        z-index: 1;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $light;
      }

      &.top {
        &::after {
          top: 0;
        }
      }

      &.bottom {
        &::after {
          bottom: 0;
        }
      }
    }

    .controls {
      .buttonGroup {
        background-color: $red;
      }
    }
  }

  &.lounge {
    border: 1px solid $tan;

    .decorativeСircle {
      background-color: $tan;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 10px;
        background-color: $light;
        z-index: 1;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $light;
      }

      &.top {
        &::after {
          top: 0;
        }
      }

      &.bottom {
        &::after {
          bottom: 0;
        }
      }
    }

    .label {
      background-color: $tan;
    }
  }
}
