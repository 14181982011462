@import 'styles/common';

.list {
  // width: 250;
  width: 100%;
  // background-color: 'lightgrey';
  padding: 8px;

  &.draggingOver {
    // background-color: lightblue;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 100% !important;
  margin: 0 0 8px;
  // padding: 16px;
  height: 40px;
  user-select: none;
  background-color: grey;

  &.dragging {
    background-color: lightgreen;
  }
}
