@import 'styles/common';

.yooKassa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .text {
    margin-bottom: 20px;
  }

  .paymentOption {
    border: 1px solid $light;
    padding: 30px;
    border-radius: 2px;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      border-color: darken($light, 10%);
    }
  }
}
