@import 'styles/common';

.allRoutes {
  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent), url('/images/all-routes/banner.png');
    background-size: cover;

    .banner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto auto 0;
      min-height: 544px;
      padding: 141px 0 40px;

      @include laptop {
        padding-top: 116px;
      }

      @include mobile {
        padding-top: 100px;
      }

      h1 {
        margin-bottom: 45px;
        color: white;
      }
    }
  }

  .connections {
    section {
      .countries {
        padding-top: 100px;

        @include mobile {
          padding-top: 64px;
        }

        &.desktop {
          display: grid;
          grid-gap: 60px 40px;
          grid-template-columns: repeat(auto-fit, minmax(250px, auto));

          @include mobile {
            display: none;
          }
        }

        &.mobile {
          display: none;

          @include mobile {
            display: block;
          }
        }

        .country {
          @include mobile {
            & + .country {
              border-top: 1px solid rgba($main, 0.1);
            }
          }

          .name {
            display: flex;
            align-items: center;
            margin-bottom: 23px;
            height: 42px;
            padding: 0 10px;

            @include mobile {
              margin-bottom: 0;
              height: 62px;
              font-size: 24px;
              letter-spacing: -0.5px;
              cursor: pointer;
            }

            i {
              margin-left: auto;
              font-size: 7px;
            }

            .flag {
              display: flex;
              align-items: center;
              margin-right: 16px;

              img {
                box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
              }
            }
          }

          .roadConnections {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            max-height: 224px;

            @include mobile {
              flex-wrap: nowrap;
              max-height: initial;
              padding: 0 0 20px;
            }
          }

          .direction {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            height: 32px;
            padding: 0 10px;
            font-size: 16px;
            letter-spacing: -0.5px;
            transition: 0.3s;

            @include mobile {
              width: initial;
              max-width: initial;
            }

            div {
              max-width: 140px;
              line-height: normal;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &:first-child {
                flex-basis: 25%;
              }

              &:last-child {
                flex: 1;
                max-width: 100%;
                padding: 0 0 0 20px;
              }
            }

            img {
              margin: 0 40px 0 0;

              @include mobile {
                margin: 0 15px;
              }
            }

            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }
}
