@import 'styles/common';

[class*='ReactCollapse--collapse'] {
  transition: height 200ms;
}

.ticket {
  position: relative;
  // margin-top: 8px; // TODO: check this prop
  font-size: 12px;
  border-radius: 4px;
  background-color: white;

  .notificationsWrap {
    padding: 10px;
    border-top: 1px solid $light;

    .notifications {
      background-color: rgba($green, 0.11);
      border-radius: 2px;
      padding: 12px;

      .notification {
        &+.notification {
          margin-top: 16px;
        }
      }
    }
  }

  .decorativeСircle {
    position: absolute;
    right: 239px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $light;

    &.top {
      top: -11px;

      @include mobile {
        top: initial;
        bottom: -8px;
        left: -11px;
        transform: rotateZ(-90deg);
      }
    }

    &.bottom {
      bottom: -11px;

      @include mobile {
        top: initial;
        right: -11px;
        bottom: -8px;
        transform: rotateZ(-90deg);
      }
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: -1px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    padding: 0 16px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 1px;
    background-color: $tan;
    transform: translateY(-50%);

    @include mobile {
      transform: translateY(-100%);
    }
  }

  .ticketContent {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .main {
      flex: 1;
      padding: 24px;
      border-radius: 0 4px 4px 0;
      // border-right: 1px dashed rgba($main, 0.2);

      @include mobile {
        position: relative;
        padding: 12px 12px 20px;
      }

      .timing {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        @include mobile {
          margin-bottom: 4px;
        }

        .stop {
          text-transform: uppercase;

          div {
            line-height: 18px;
          }

          .name {
            margin-bottom: 2px;
            letter-spacing: 1px;
          }

          .time {
            height: 20px;
            font-size: 24px;

            @include mobile {
              font-size: 20px;
            }
          }

          &.from {
            margin-right: 23px;

            @include mobile {
              margin-right: 12px;
            }
          }

          &.to {
            margin-left: 23px;
            text-align: right;

            @include mobile {
              margin-left: 12px;
            }
          }
        }

        .logo {
          margin: 0 15px;
          height: 20px;

          @include mobile {
            margin: 0 5px;
            height: 15px;
          }

          span {
            height: 100%;
          }

          img {
            height: 100%;
          }
        }

        .divider {
          display: flex;
          flex: 1;
          align-items: center;

          .circle {
            width: 7px;
            height: 7px;
            border: 1px solid rgba($main, 0.2);
            border-radius: 50%;

            &.black {
              margin-right: 4px;
              width: 5px;
              height: 5px;
              background-color: $main;
            }
          }

          .line {
            flex: 1;
            height: 1px;
            background-color: rgba($main, 0.2);
          }

          &.reverse {
            flex-direction: row-reverse;
          }
        }
      }

      .direction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 26px;
        border-bottom: 1px solid $light;

        @include mobile {
          margin-bottom: 18px;
          padding-bottom: 0;
          border-bottom: 0;
        }

        .stop {
          padding-right: 5px;
          line-height: 18px;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;

          &.to {
            // padding-left: 5px;
            padding: 0 0 0 5px;
            text-align: right;
          }
        }
      }

      .info {
        display: flex;
        align-items: center;

        .duration {
          text-transform: uppercase;
        }

        .transfers {
          margin-left: 16px;
          color: $red;
        }

        .tooltip {
          margin-left: 7px;
        }

        .showDetails {
          display: flex;
          align-items: center;
          margin-left: auto;
          cursor: pointer;

          // span {
          //   text-transform: capitalize;
          // }

          img {
            margin-left: 8px;
            width: 10px;
            height: 6px;
          }

          &.show {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }

      .notification {
        margin-top: 20px;
        color: $red;
        line-height: 12px;
        font-size: 12px;
        font-weight: 400;

        i {
          margin-right: 8px;
        }
      }
    }

    .controls {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 246px;
      padding: 24px;
      border-radius: 4px 0 0 4px;

      @include mobile {
        flex: 1;
        align-items: center;
        width: auto;
        padding: 14px;
      }

      .divider {
        position: absolute;
        top: 12px;
        bottom: 12px;
        left: -1px;
        width: 1px;
        background-image: linear-gradient(rgba($main, 0.2) 50%,
            transparent 50%);
        background-repeat: repeat-y;
        background-size: 1px 10px;

        @include mobile {
          top: 0;
          right: 20px;
          bottom: initial;
          left: 20px;
          width: initial;
          height: 1px;
          background-image: linear-gradient(90deg,
              rgba($main, 0.2) 50%,
              transparent 50%);
          background-repeat: repeat-x;
          background-size: 10px 1px;
        }
      }

      .seats {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 13px;

        .seat {
          display: flex;
          align-items: center;
          color: rgba($main, 0.5);
          font-weight: 400;

          &+.seat {
            margin-left: 14px;
          }

          i {
            margin-right: 4px;
            color: rgba($tan, 0.5);
            font-size: 18px;
          }

          &.active {
            color: rgba($main, 0.7);

            i {
              color: $tan;
            }
          }
        }
      }

      .currentTrip {
        height: 40px;
        display: flex;
        align-items: center;
        background-color: $light;
        border-radius: 2px;
        font-size: 12px;
        justify-content: center;
        font-weight: 300;
        margin-bottom: 15px;
        padding: 0 15px;

        i {
          margin-right: 8px;
          color: $green;
        }

        @include mobile {
          min-width: 198px;
        }
      }

      .buttonGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        width: 198px;
        height: 40px;
        color: white;
        text-transform: uppercase;
        cursor: pointer;
        border-radius: 2px;
        transition: 0.15s;
        background: $main radial-gradient(farthest-side, rgba(#5d616c, 1), transparent 100%) fixed no-repeat;
        background-size: 0 0;

        // @include tablet {
        //   background: $red;
        // }

        &:hover {
          background-size: 120px 120px;

          @include tablet {
            background-size: 0;
          }
        }

        &:active {
          background-color: #5d616c;
        }

        .line {
          width: 1px;
          height: 16px;
          background-color: rgba(#fff, 0.2);
        }

        .part {
          flex: 1;
          padding: 0 10px;
          text-align: center;

          &:first-child {
            max-width: 50%;
          }
        }
      }

      .discount {
        margin-bottom: 15px;
        font-weight: 400;
        text-align: center;
      }

      .travelers {
        color: rgba($main, 0.64);
        font-weight: 400;
        text-align: center;
      }

      &.soldOut {
        justify-content: flex-end;
        align-items: center;

        .soldOut {
          height: 40px;
          border-radius: 2px;
          background-color: $light;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          width: 100%;
          text-transform: uppercase;
          margin-bottom: 20px;
          letter-spacing: 1px;
        }

        .hyphens {
          opacity: 0.64;
        }
      }
    }
  }

  .details {
    padding: 5px 24px 24px;

    @include mobile {
      padding: 5px 12px 12px;
    }
  }

  &+.ticket {
    margin-top: 26px;

    @include mobile {
      margin-top: 32px;
    }
  }

  &.firstWithLabel {
    margin-top: 27px;

    @include mobile {
      margin-top: 34px;
    }
  }

  &.promo {
    // border: 1px solid $red;

    .decorativeСircle {
      // background-color: $red;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 10px;
        background-color: $light;
        z-index: 1;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $light;
      }

      &.top {
        &::after {
          top: 0;
        }
      }

      &.bottom {
        &::after {
          bottom: 0;
        }
      }
    }

    .controls {
      .buttonGroup {
        background-color: $red;

        background: $red radial-gradient(farthest-side, rgba($light-red, 1), transparent 100%) fixed no-repeat;

        &:hover {
          background-size: 120px 120px;

          @include tablet {
            background-size: 0;
          }
        }

        &:active {
          background-color: $light-red;
        }
      }
    }

    .label {
      background-color: $red;
    }
  }

  &.lounge {
    // border: 1px solid $tan;

    .decorativeСircle {
      // background-color: $tan;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 16px;
        height: 10px;
        background-color: $light;
        z-index: 1;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $light;
      }

      &.top {
        &::after {
          top: 0;
        }
      }

      &.bottom {
        &::after {
          bottom: 0;
        }
      }
    }

    .label {
      background-color: $tan;
    }
  }
}