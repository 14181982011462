@import 'styles/common';

.modal {
  max-width: 764px;

  h2 {
    color: $red;
  }

  .infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -12px -12px 24px;
    padding: 0 12px;

    @include mobile {
      margin: 0 0 24px;
      padding: 0;
    }

    .info {
      margin: 12px;
      width: 260px;

      @include mobile {
        margin: 0 0 12px;
        width: initial;
      }

      h5 {
        margin-bottom: 3px;
      }
    }
  }

  .list {
    margin-top: 14px;

    .item {
      display: flex;
      align-items: flex-start;
      line-height: 26px;
      font-size: 16px;
      font-weight: 400;

      & + .item {
        margin-top: 8px;
      }

      .circle {
        position: relative;
        top: 6px;
        flex-shrink: 0;
        flex-shrink: 0;
        margin-right: 8px;
        width: 6px;
        height: 6px;
        border: 1px solid $red;
        border-radius: 50%;
      }
    }

    .text {

    }
  }
}
