@import 'styles/common';

.radio {
  input + label {
    display: flex;
    align-items: center;
    line-height: 12px;
    font-size: 12px;
    font-weight: normal;
    font-weight: 400;
    cursor: pointer;

    span {
      position: relative;
      margin-right: 7px;
      width: 14px;
      height: 14px;
      cursor: pointer;
      border: 1px solid $main;
      border-radius: 50%;
      background-color: white;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        opacity: 0;
        border-radius: 50%;
        background-color: $tan;
        transition: opacity 0.3s;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  input {
    display: none;

    &:checked + label span {
      border: 1px solid $tan;
    }

    &:checked + label span::before {
      opacity: 1;
    }

    &:disabled + label {
      opacity: 0.5;

      &,
      span {
        cursor: default;
      }
    }
  }
}
