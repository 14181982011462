@import 'styles/common';

.bus {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 250px;
  // height: 850px;
  min-height: 350px;
  padding: 52px 25px 40px;
  padding: 85px 25px 40px;
  border: 6px solid rgba($main, 0.1);
  border-top-left-radius: 140px 55px;
  border-top-right-radius: 140px 55px;
  border-bottom-right-radius: 140px 55px;
  border-bottom-left-radius: 140px 55px;

  .decorate {
    position: absolute;
    bottom: 45px;
    width: 6px;
    height: 216px;
    background-color: $red;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 6px;
      height: 23px;
      background-color: $tan;
      transform: translateY(-100%);
    }

    &.left {
      left: -6px;
      border-radius: 0 0 0 6px;
      transform: skewY(45deg);
    }

    &.right {
      right: -6px;
      border-radius: 0 0 6px 0;
      transform: skewY(-45deg);
    }
  }

  .steeringWheel {
    position: absolute;
    top: 37px;
    left: 27px;
    color: rgba(#dedede, 0.75);
    font-size: 33px;
    font-weight: 300;
  }

  .seats {
    flex-direction: column;
    display: flex;

    .row {
      align-items: center;
      display: flex;
      justify-content: space-between;

      & + .row {
        margin-top: 16px;
      }
    }
  }

  .seat {
    padding-bottom: 8px;
    width: 32px;

    .icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 400;
      cursor: pointer;
      border-radius: 4px;
      transition: 0.3s;

      &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 32px;
        height: 8px;
        border-radius: 4px 4px 2px 2px;
        background-color: rgba($main, 0.1);
      }
    }

    &.regular {
      .icon {
        width: 32px;
        height: 32px;
        line-height: 15px;
        font-size: 15px;
        background-color: $green;

        &::after {
          width: 32px;
        }
      }
    }

    &.lounge {
      width: 37px;

      &:nth-child(n + 2) {
        margin-left: 16px;
      }

      .icon {
        width: 37px;
        height: 37px;
        background-color: $tan;

        &::after {
          width: 37px;
        }
      }
    }

    &.selected {
      .icon {
        background-color: $red;
        font-weight: 600;
      }
    }

    &.occupied {
      .icon {
        color: rgba($main, 0.4);
        cursor: not-allowed;
        border: 1px solid rgba($main, 0.3);
        background-color: white;

        &::after {
          bottom: -9px;
          left: -1px;
        }
      }
    }

    &.loading {
      .icon {
        animation: colorchange 1s linear infinite;
      }

      @keyframes colorchange {
        0% {
          background-color: rgba($main, 0.3);
        }
        100% {
          background-color: rgba($main, 0.7);
        }
      }
    }
  }
}
