@import 'styles/common';

.modal {
  overflow: initial;
  display: flex;
  flex-direction: column;

  .input {
    border: 1px solid $light;
  }

  .suggestions {
    margin-top: 22px;
    padding: 19px 0;
    overflow: auto;
    background-color: white;

    @keyframes pulse {
      from {
        opacity: 1;
        transform: scale(1);
      }

      to {
        opacity: 0.25;
        transform: scale(0.75);
      }
    }

    .spinnerBox {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      .pulseContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100px;
        padding: 3px;

        .pulseBubble {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: rgba($main, 0.3);
        }

        .pulseBubble1 {
          animation: pulse 0.4s ease 0s infinite alternate;
        }

        .pulseBubble2 {
          animation: pulse 0.4s ease 0.2s infinite alternate;
        }

        .pulseBubble3 {
          animation: pulse 0.4s ease 0.4s infinite alternate;
        }
      }
    }

    .townCountry {
      margin-bottom: 24px;

      i {
        margin-right: 7px;
        color: $tan;
      }
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      padding-left: 18px;
      line-height: 20px;
      font-size: 15px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        margin-right: 8px;
        font-size: 13px;
      }
    }
  }
}
