@import 'styles/common';

.tickets {
  .header {
    align-items: center;
    display: flex;
    min-height: 60px;
    background-color: white;
    padding: 12px 22px 12px 14px;
    border-radius: 2px;
    position: relative;
    margin-bottom: 16px;
    overflow: hidden;

    @include mobile {
      align-items: flex-start;
      flex-direction: column;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      line-height: 24px;

      i {
        font-size: 12px;
        cursor: pointer;
        color: $red;
        margin-right: 8px;
        padding: 0 8px;
        transform: rotateZ(180deg);
      }
    }

    .subTitle {
      padding-left: 36px;
      font-weight: 600;
      line-height: 16px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-left: auto;
      opacity: 0.75;
      white-space: nowrap;

      @include mobile {
        margin-left: initial;
      }
    }
  }

  .touchedTicket {
    & + .touchedTicket {
      margin-top: 16px;
    }
  }
}
