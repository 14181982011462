@import 'styles/common';

.ticketsInput {
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;

  label {
    margin-bottom: 6px;
    color: $main;
    line-height: 14px;
    font-size: 12px;

    .tooltip {
      margin-left: 5px;
    }
  }

  input {
    width: 100%;
    height: 40px;
    padding: 0 16px;
    color: $main;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid $light;
    border-radius: 2px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: black;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: rgba($main, 0.4);
      font-size: 15px;
      font-weight: 400;
    }
  }

  .message {
    margin-top: 6px;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &.withoutMessage {
    padding-bottom: 0;
  }

  &.error {
    padding-bottom: 0;

    input {
      border-color: $red;
    }

    .message {
      color: $red;
    }
  }
}
