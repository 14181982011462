@import 'styles/common';

.modal {
  width: 540px;
  // max-width: 764px;
  padding: 0 !important;

  @include tablet {
    min-width: initial;
    max-width: initial;
  }

  .content {
    // margin: auto;
    padding: 50px 50px 40px;

    .notice {
      margin-bottom: 30px;

      .link {
        color: $red;
        font-weight: 600;
      }
    }

    .priceBlock {
      margin: 40px 0 30px;

      .priceLine {
        align-items: center;
        display: flex;
        font-size: 15px;
        font-weight: 300;
        line-height: 14px;
        padding-bottom: 14px;
        border-bottom: 1px dashed rgba($main, 0.2);
        justify-content: space-between;
        text-transform: capitalize;

        & + .priceLine {
          margin-top: 13px;
        }

        &.total {
          text-transform: initial;
          padding-bottom: 0;
          border-bottom: 0;
          font-size: 18px;
          font-weight: 600;
          margin-top: 20px;
        }
      }
    }

    .controls {
      align-items: center;
      display: flex;

      .button {
        width: 100%;
      }

      .cancel {
        margin-left: 34px;
        cursor: pointer;
        color: $red;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }

  .footer {
    padding: 27px 50px;
    background-color: $light;

    .notice {
      display: flex;
      align-items: flex-start;
      font-weight: 300;

      i {
        color: $tan;
        margin-right: 10px;
      }

      .text {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
