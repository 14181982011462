@import 'styles/common';

.destination {
  .title {
    margin: 0 0 60px;
    max-width: 650px;
    padding-top: 100px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    // background-image: linear-gradient(90deg, rgba(black, 0.85), transparent), url('/images/destination/banner.png');
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      min-height: 544px;
      padding: 141px 0 40px;

      @include laptop {
        padding-top: 116px;
      }

      @include mobile {
        padding-top: 100px;
      }

      h1 {
        margin-bottom: 24px;
        max-width: 600px;
        color: white;
      }

      p {
        margin-bottom: 45px;
        max-width: 595px;
        color: white;
        font-size: 18px;
        font-weight: 400;
      }

      .uploadBanner {
        position: absolute;
        top: 130px;
        right: 170px;
      }
    }
  }

  .sliderContainer {
    position: relative;
    margin-right: calc((-100vw + 1440px) / 2 - #{$container_padding_desktop});

    @include laptop {
      margin-right: -$container_padding_laptop;
    }

    @include tablet {
      margin-right: -$container_padding_tablet;
    }

    @include mobile {
      margin-right: 0;
    }
  }

  .descriptions {
    position: relative;

    .greyBlock {
      top: 0;
      left: 0;
      width: 80vw;
      height: 80vw;
      max-height: 608px;
    }

    .texts {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 74px;

      @include mobile {
        flex-direction: column;
      }

      h4 {
        max-width: 260px;

        @include tablet {
          margin-bottom: 20px;
          max-width: initial;
        }
      }

      .text {
        font-weight: 400;
        line-height: 24px;
        margin-left: 76px;
        max-width: 260px;
        font-size: 16px;

        @include mobile {
          margin: 24px 0 0;
          max-width: initial;
        }
      }
    }

    .slider {
      .button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
      }
    }

    .slide {
      flex-shrink: 0;
      width: 540px;
      height: 361px;

      @include mobile {
        width: 259px;
        height: 177px;
      }

      & + .slide {
        margin-left: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .contorls {
    }
  }

  .events {
    position: relative;

    .greyBlock {
      top: 0;
      left: calc((-100vw + 1440px) / 2 - #{$container_padding_desktop});
      width: 100%;
      height: 444px;

      @include desktop {
        left: -$container_padding_desktop;
        // left: calc((-100vw + 1440px) / 2 - #{$container_padding_desktop});
      }

      @include laptop {
        left: -$container_padding_laptop;
      }

      @include tablet {
        left: -$container_padding_tablet;
      }

      @include mobile {
        left: 0;
      }
    }

    section {
      position: relative;

      .visibility {
        position: absolute;
        top: 60px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 40px;
        background-color: white;

        @include tablet {
          top: 0;
        }

        span {
          color: rgba($main, 0.5);
          text-transform: uppercase;
          letter-spacing: 2px;
        }

        .switch {
          margin-left: 10px;
        }
      }

      .title {
        margin-bottom: 30px;
      }

      .subTitle {
        margin-bottom: 48px;
        max-width: 480px;
        font-size: 16px;
        font-weight: 400;
      }

      .slider {
        position: relative;
        display: flex;
        margin-right: calc(
          (-100vw + 1440px) / 2 - #{$container_padding_desktop}
        );

        @include desktop {
          margin-right: -$container_padding_desktop;
        }

        @include laptop {
          margin-right: -$container_padding_laptop;
        }

        @include tablet {
          margin-right: -$container_padding_tablet;
        }

        @include mobile {
          position: relative;
          flex-direction: column;
          margin-right: 0;
        }

        .greyBlock {
          @include mobile {
            top: 0;
            left: -$container_padding_mobile;
            display: block;
            width: 100vw;
            height: calc(100% - 40px);
            background-color: $light;
          }
        }

        .editSlider {
          position: absolute;
          top: 0;
          right: calc((100vw - 1440px) / 2 + #{$container_padding_desktop});

          @include desktop {
            right: $container_padding_desktop;
          }

          @include laptop {
            right: $container_padding_laptop;
          }

          @include tablet {
            right: $container_padding_tablet;
          }
        }

        .info {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          padding: 60px 0;

          @include tablet {
            padding: 40px 0;
          }

          .date {
            margin-bottom: 15px;
            color: $tan;
            line-height: 24px;
            font-size: 16px;

            @include mobile {
              order: 1;
            }
          }

          .texts {
            display: flex;
            margin-bottom: 20px;
            height: 163px;
            padding-right: 94px;

            @include tablet {
              flex-direction: column;
              height: 214px;
              padding-right: 50px;
            }

            @include mobile {
              order: 2;
              height: initial;
              padding-right: 0;
            }

            h3 {
              max-width: 238px;

              @include tablet {
                margin-bottom: 20px;
                max-width: initial;
              }
            }

            p {
              margin-left: 40px;
              max-width: 260px;
              font-size: 16px;
              font-weight: 400;

              @include tablet {
                margin-bottom: 15px;
                margin-left: 0;
                max-width: initial;
              }
            }
          }
        }

        .slides {
          @include mobile {
            order: 4;
          }
        }

        .slide {
          flex-shrink: 0;
          width: 100%;
          height: 444px;

          @include mobile {
            height: 280px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .button {
          @include mobile {
            order: 3;
            width: 100%;
          }
        }

        .controls {
          margin-top: 40px;

          @include mobile {
            position: static;
            top: initial;
            order: 5;
            margin: 62px auto 0;
          }
        }
      }
    }
  }

  .places {
    .tabs {
      margin-bottom: 96px;

      .header {
        display: flex;
        margin-bottom: 31px;
        padding-top: 32px;

        .panel {
          display: flex;
          margin-left: 31px;

          .date {
            margin: 0 16px;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
          }

          i {
            color: $red;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .places {
        display: flex;
        flex-wrap: wrap;
        margin: -20px -10px 0;

        .place {
          margin: 20px 10px;
          max-width: 288px;

          .photo {
            margin-bottom: 16px;
            height: 202px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .name {
            margin-bottom: 16px;
            font-size: 24px;
            letter-spacing: -0.5px;
          }

          .subTitle {
            font-size: 16px;
          }

          .raiting {
            display: flex;
            align-items: center;
            margin: 5px 0;

            img {
              margin-right: 8px;
            }

            .circle {
              position: relative;
              width: 15px;
              height: 15px;
              border: 1px solid #18a581;
              border-radius: 50%;

              & + .circle {
                margin-left: 1px;
              }

              &::after {
                position: absolute;
                top: 3px;
                left: 3px;
                display: block;
                margin: auto;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: #18a581;
              }

              &.fill {
                &::after {
                  content: '';
                }
              }
            }
          }

          .reviews {
            line-height: 30px;
            font-size: 14px;
          }
        }
      }

      .button {
        display: flex;
        margin: 0 auto;
        width: 316px;

        @include mobile {
          width: 100%;
        }
      }
    }

    h3 {
      margin: 100px 0 40px;
      max-width: 690px;
    }
  }

  &.editMode {
    .greyBlock {
      display: none;
    }
  }
}
