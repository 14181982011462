@import 'styles/common';

.nav {
  position: relative;
  display: none;
  align-items: stretch;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 0 0 2px;
  background-color: white;
  transition: 0.35s;

  @include tablet {
    display: flex;
  }

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 22px;

    @include mobile {
      min-width: 56px;
      padding: 15px;
      max-width: 132px;
    }

    .icon {
      font-size: 20px;
      width: 20px;
      text-align: center;

      &.close {
        font-size: 14px;
      }
    }

    .external {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      white-space: normal;
      color: $red;
      font-size: 11px !important;

      .icon {
        margin-right: 9px;
        font-size: 16px;
      }
    }

    & + .section {
      border-left: 1px solid #f0f0f1;
    }

    .item {
      position: relative;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      white-space: nowrap;

      & + .item {
        margin-left: 40px;
      }

      .wrapList {
        position: absolute;
        top: 100%;
        left: -12px;
        display: none;
        padding-top: 11px;

        @keyframes showNav {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        .list {
          position: relative;
          display: flex;
          flex-direction: column;
          max-width: 204px;
          padding: 24px 16px;
          border-radius: 4px;
          background-color: white;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15),
            0 1px 3px 0 rgba(0, 0, 0, 0.2);

          .wrap {
            position: absolute;
            bottom: 100%;
            left: 14px;
            filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.15));

            .triangle {
              width: 14px;
              height: 7px;
              background-color: white;
              clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            }
          }
        }
      }

      .link {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;

        & + .link {
          margin-top: 16px;
        }

        &:hover {
          color: $red;
        }
      }

      &:hover {
        img {
          transform: rotateZ(180deg);
        }

        .wrapList {
          display: block;
          animation: showNav 250ms ease-in-out both;
        }
      }
    }

    &.subMenu {
      @include mobile {
        display: none;
      }
    }
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 88px 20px 20px;
    overflow: auto;
    background-color: white;
    z-index: -1;
    transition: 0.3s;
    transform: translateX(-100%);

    .block {
      & + .block {
        margin-top: 32px;
      }

      &.routes {
        display: flex;
        flex-direction: column;
        overflow: auto;

        .item {
          display: block;
          flex-shrink: 0;
          padding: 16px 0;
          line-height: 20px;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          border-bottom: 1px solid $light;

          .title {
            display: flex;
            align-items: center;

            .selected {
              margin-left: auto;
            }

            i {
              position: relative;
              top: -1px;
              margin-left: auto;
              font-size: 8px;

              &.open {
                transform: rotateZ(180deg);
              }
            }

            &.withSelected {
              i {
                margin-left: 10px;
              }
            }
          }

          &.asLink {
            .link {
              font-size: 16px;

              &.important {
                color: $red;
              }
            }
          }

          &.user {
            .userTitle {
              display: flex;
              align-items: center;

              .avatar {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                background-color: $tan;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0;
                line-height: initial;
                background-size: cover;
                background-position: center;
              }

              .userInfo {
                display: flex;
                flex-direction: column;
                margin-left: 13px;

                .name {
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: 600;
                  text-transform: capitalize;
                }

                .phone {
                  font-size: 12px;
                  font-weight: 600;
                  letter-spacing: 1px;
                  color: rgba($main, 0.6);
                }
              }
            }
          }

          &[disabled],
          &[disabled]:hover {
            color: rgba($main, 0.7);
            pointer-events: none;
            cursor: not-allowed;
          }
        }

        .list {
          display: none;
          padding: 24px 0 8px;

          &.open {
            display: flex;
            flex-direction: column;
          }

          .link {
            margin: 0;
            font-size: 14px;

            & + .link {
              margin: 24px 0 0;
            }
          }
        }

        .returnTicket {
          color: $main;
          border: 1px solid $light;
          background-color: white;
        }
      }
    }

    .divider {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &.open {
    background-color: #f0f0f1;

    img {
      &.close {
        opacity: 1;
      }

      &.burger {
        opacity: 0;
      }
    }

    .menu {
      transform: translateX(0);
    }
  }

  &.ticketPages {
    display: none;

    @include mobile {
      display: flex;
    }

    .section {
      display: none;

      @include mobile {
        display: flex;
      }
    }
  }
}
