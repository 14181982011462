@import 'styles/common';

.loading {
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .image {
      // width: 100px;
      // height: 100px;
      animation: spin 2s linear infinite;
      will-change: transform;

      span {
        width: 100px;
        height: 100px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
