@import 'styles/common';

.ticketsNotFound {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 85px 0;

  @include tablet {
    padding: 64px 0;
  }

  img {
    width: 206px;
    height: 138px;
    object-fit: contain;
  }

  .title {
    margin: 40px 0 16px;

    @include tablet {
      margin-bottom: 30px;
    }
  }

  .description {
    margin-bottom: 140px;
    line-height: 26px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;

    span {
      font-weight: 600;
    }

    @include tablet {
      margin-bottom: 40px;
    }
  }

  h3 {
    margin-bottom: 20px;
  }
}
