@import 'styles/common';

.profile {
  padding-top: #{$header_height_desktop + 60px};
  background-color: $light;

  @include tablet {
    padding-top: #{$header_height_tablet + 40px};
  }

  section {
    .block {
      display: flex;
      align-items: flex-start;

      .menu {
        margin-right: 16px;
        width: 372px;

        @include laptop {
          width: 320px;
        }

        .item {
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          font-weight: 600px;
          border-radius: 2px;
          font-size: 18px;
          padding: 0 20px 0 24px;
          cursor: pointer;
          background-color: white;
          transform: 0.3s;

          i {
            font-weight: 300;
            font-size: 12px;
          }

          & + .item {
            margin-top: 1px;
          }

          &.active {
            color: $red;

            &:before {
              border-radius: 2px 0 0 2px;
              width: 4px;
              height: 100%;
              left: 0;
              content: '';
              top: 0;
              background-color: $red;
              position: absolute;
            }
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;

        .header {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          padding: 0 32px;
          border-radius: 2px 2px 0 0;
          margin-bottom: 1px;
        }

        .info {
          padding: 26px 32px;
          background-color: white;
          border-radius: 0 0 2px 2px;

          &.personalInfo {
            display: flex;
            align-items: flex-start;

            .photo {
              display: flex;
              height: 302px;
              width: 302px;
              position: relative;
              margin-right: 32px;
              align-items: center;
              justify-content: center;

              @include laptop {
                height: 250px;
                width: 250px;
              }

              &:before {
                content: '';
                position: absolute;
                height: 50%;
                width: 100%;
                background-color: $light;
                border-radius: 2px;
                top: 0;
                left: 0;
              }

              .avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: $tan;
                height: 140px;
                width: 140px;
                position: relative;
                overflow: hidden;

                @include laptop {
                  height: 120px;
                  width: 120px;
                }

                .cap {
                  width: 100%;
                  height: 100%;
                  background-size: cover;
                }

                img {
                }
              }
            }

            .fields {
              display: flex;
              flex-direction: column;
              flex: 1;

              .field {
                & + .field {
                  margin-top: 4px;
                }

                &.radios {
                  display: flex;
                  margin-bottom: 22px;

                  .radio + .radio {
                    margin-left: 32px;
                  }
                }
              }
            }
          }

          .security {
            .fields {
              display: flex;
              flex-direction: column;
              // flex: 1;

              .field {
                max-width: 310px;

                & + .field {
                  margin-top: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
