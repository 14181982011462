@import 'styles/common';

.news {
  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent),
      url('/images/news/banner.png');
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 550px;

      .uploadBanner {
        position: absolute;
        top: 130px;
        right: 170px;
      }

      h1 {
        margin-bottom: 24px;
        max-width: 534px;
        color: white;
        font-size: 42px;
      }

      p {
        color: $tan;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .news {
    article {
      display: flex;
      align-items: flex-start;
      padding-top: 80px;

      @include tablet {
        align-items: stretch;
        flex-direction: column;
      }

      .texts {
        flex: 1;
        max-width: 757px;

        .title {
          margin-bottom: 42px;
        }

        //
        // .quote {
        //   display: flex;
        //   flex-direction: column;
        //   margin: 48px 0 60px;
        //   // padding-top: 38px;
        //   // max-width: 316px;
        //
        //   // @include tablet {
        //   //   margin-top: 24px;
        //   //   padding-top: 32px;
        //   //   width: initial;
        //   // }
        //
        //   i {
        //     color: $red;
        //     font-size: 32px;
        //   }
        //
        //   h4 {
        //     margin-top: 23px;
        //
        //     @include tablet {
        //       margin-top: 16px;
        //     }
        //   }
        //
        //   .author {
        //     align-items: center;
        //     display: flex;
        //     font-weight: 400;
        //
        //     .line {
        //       background-color: $main;
        //       margin-right: 10px;
        //       height: 1px;
        //       width: 37px;
        //     }
        //   }
        // }

        .links {
          display: flex;
          align-items: center;
          margin-top: 32px;

          .link {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            font-size: 16px;
            background-color: $light;
            transition: 0.3s;

            & + .link {
              margin-left: 16px;
            }

            &:hover {
              color: white;
              background-color: $red;
            }
          }

          .copied {
            margin-left: 16px;
            opacity: 0;

            &.animate {
              animation: notify 0.65s;
            }

            @keyframes notify {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        }
      }

      aside {
        flex-shrink: 0;
        margin-left: 131px;
        width: 372px;

        @include tablet {
          margin: 40px 0 0;
          width: auto;
        }

        h3 {
          margin-bottom: 32px;
        }

        .news {
          display: flex;
          flex-direction: column;

          .item {
            & + .item {
              margin-top: 40px;
            }

            .date {
              color: $tan;
            }

            h5 {
              margin: 9px 0 12px;
              transition: 0.3s;
            }

            &:hover {
              h5 {
                color: $red;
              }
            }
          }
        }

        .showLatest {
          margin-top: 40px;
          position: relative;
          display: inline-flex;
          align-items: center;
          padding: 0 0 1px 0;
          color: $main;
          line-height: 18px;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;

          @include mobile {
            display: none;
          }

          i {
            position: relative;
            margin: 0 0 0 6px;
            font-size: 11px;

            @include mobile {
              top: 1px;
            }
          }

          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 1px;
            background: $main;
          }

          &:hover {
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
}
