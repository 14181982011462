@import 'styles/common';

.circle {
  position: relative;

  .text {
    top: 0;
    left: 0;
    position: absolute;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: $red;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .svg {
    display: block;
    flex-shrink: 0;
    transform: rotate(-90deg);
  }

  .svgCircleBg {
    fill: none;
  }

  .svgCircle {
    fill: none;
  }
}

.customerProgram {
  padding-top: #{$header_height_desktop + 60px};
  background-color: $light;

  @include tablet {
    padding-top: #{$header_height_tablet + 40px};
  }

  h2 {
    margin-bottom: 60px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  section {
    .block {
      align-items: flex-start;
      display: flex;

      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-left: 16px;
        flex: 1;
        width: calc(100% - (350px + 16px));

        @include tablet {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  .loading {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .data {
    display: flex;
    margin: -5px -5px 0;

    @include mobile {
      flex-wrap: wrap;
      flex-direction: column;
    }

    .dataBlock {
      flex: 1;
      background-color: white;
      border-radius: 2px;
      padding: 14px 22px;
      display: flex;
      margin: 5px;
      align-items: center;

      @include mobile {
        min-width: 270px;
        flex-wrap: wrap;
        flex: 1;
        padding: 8px 12px;
      }

      .progressBar {
        margin: 10px;
      }

      .info {
        display: flex;
        flex-direction: column;
        margin: 10px;

        .title {
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 6px;
        }

        .subTitle {
          font-weight: 300;
          font-size: 13px;
          opacity: 0.55;
          line-height: 16px;
        }
      }
    }
  }

  .details {
    position: relative;
    display: flex;
    margin-top: 7px;
    background-color: white;
    border-radius: 2px;
    overflow: hidden;

    @include mobile {
      flex-direction: column;
    }

    .left {
      border-right: 1px solid $light;
      display: flex;
      flex-direction: column;
      padding: 32px 40px;
      flex: 2;

      @include mobile {
        padding: 18px 22px;
        border-right: 0;
        border-bottom: 1px solid $light;
      }

      .head {
        margin-bottom: auto;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;

        span {
          color: $red;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-top: 44px;

        .days {
          color: $red;
          font-weight: 600;
          font-size: 20px;
          line-height: 15px;
          margin-bottom: 6px;
        }

        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 26px;
        }

        .subTitle {
          font-weight: 300;
          opacity: 0.55;
          font-size: 13px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      padding: 32px 40px;
      flex: 3;

      @include mobile {
        padding: 18px 22px;
      }

      .item {
        display: flex;

        & + .item {
          margin-top: 24px;
        }

        .img {
          position: relative;

          .check {
            position: absolute;
            height: 20px;
            width: 20px;
            top: -5px;
            right: -5px;
            border: 2px solid white;
            border-radius: 50%;
          }
          img {
            height: 46px;
            width: 46px;
          }
        }

        .info {
          margin-left: 20px;

          .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            text-transform: capitalize;
          }

          .subTitle {
            font-weight: 300;
            font-size: 13px;
            opacity: 0.55;
          }
        }
      }
    }
  }

  .notification {
    background-color: white;
    border-radius: 2px;
    padding: 24px 32px;
    margin-top: 22px;
    position: relative;

    @include mobile {
      padding: 18px 22px;
    }

    .label {
      position: absolute;
      transform: translateY(-50%);
      top: 0;
      left: 0;
      height: 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      padding: 0 16px;
      background-color: $green;
      text-transform: uppercase;
      color: white;
      border-radius: 1px;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 3px;
    }

    .description {
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
      opacity: 0.55;
    }
  }

  .notes {
    background-color: white;
    border-radius: 2px;
    padding: 24px 32px;
    margin-top: 12px;

    @include mobile {
      padding: 18px 22px;
    }

    .notice {
      font-size: 13px;

      & + .notice {
        margin-top: 24px;
      }
    }
  }
}
