@import 'styles/common';

.payment {
  .breadcrumbs {
    @extend %breadcrumbs;
  }

  .paymentFlow {
    section {
      display: flex;
      align-items: flex-start;
      // margin-top: 40px;

      .tripDetails {
        width: 372px;
        flex-shrink: 0;

        @include laptop {
          width: 320px;
        }

        @include tablet {
          width: initial;
        }
      }

      .zeroPayment {
        margin-right: 20px;
        display: flex;
        max-width: calc(100% - 392px); // 372(width) + 20(margin)
        flex-direction: column;
        flex: 1;

        @include laptop {
          max-width: calc(100% - 332px); // 320(width) + 12(margin)
        }

        @include tablet {
          margin-right: 0;
          max-width: 100%;
          flex: 1;
        }

        @include mobile {
          max-width: initial;
          margin: 0 -#{$container_padding_mobile};
        }

        .zeroPaymentBlock {
          display: flex;
          flex-direction: column;
          flex: 1;
          border-radius: 2px;
          background-color: white;

          & + .zeroPaymentBlock {
            margin-top: 16px;
          }

          .header {
            height: 60px;
            display: flex;
            align-items: center;
            padding: 0 40px;
            border-bottom: 1px solid $light;

            @include laptop {
              padding: 0 30px;
            }

            @include mobile {
              padding: 0 10px;
            }
          }

          .info {
            padding: 30px 40px;

            @include laptop {
              padding: 30px;
            }

            @include mobile {
              padding: 20px 10px;
            }

            .notice {
              background-color: rgba($green, 0.1);
              padding: 20px;
            }
          }
        }
      }

      .content {
        margin-right: 20px;
        max-width: calc(100% - 392px); // 372(width) + 20(margin)
        width: 100%;

        @include laptop {
          max-width: calc(100% - 332px); // 320(width) + 12(margin)
        }

        @include tablet {
          margin-right: 0;
          max-width: 100%;
          flex: 1;
        }

        @include mobile {
          max-width: initial;
          margin: 0 -#{$container_padding_mobile};
        }
      }

      .block {
        background-color: white;
        border-top: 1px solid $light;
        // padding: 32px 30px;
        border-radius: 0 0 2px 2px;

        padding: 30px 40px;

        @include laptop {
          padding: 30px;
        }

        @include mobile {
          padding: 20px 10px;
        }

        .link {
          color: $red;
          font-weight: 600;
        }
      }

      .tabs {
        display: flex;
        flex-direction: column;
        flex: 1;
        border-radius: 2px 2px 0 0;
        background-color: white;

        .tabPanel {
          position: relative;
          display: flex;
          border-bottom: 1px solid $light;

          .line {
            position: absolute;
            bottom: -1px;
            left: 0;
            // width: calc(100% / 3);
            // width: calc(100% / 2);
            height: 2px;
            background-color: $red;
            transition: 0.3s;
          }

          .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex: 1;
            width: calc(100% / 3);
            height: 60px;
            color: rgba($main, 0.7);
            cursor: pointer;
            transition: 0.3s;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding: 0 5px;

            @include mobile {
              font-size: 14px;
            }

            span {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            &.active {
              color: $red;
            }
          }
        }

        .tabsContainer {
          overflow: hidden;

          .tabsContents {
            position: relative;
            display: flex;
            align-items: flex-start;
            width: 100%;
            flex: 1;
            transition: 0.3s;
            transform: translateX(0);

            .tabsContent {
              flex-shrink: 0;
              width: 100%;
              padding: 40px;

              @include laptop {
                padding: 30px;
              }

              @include mobile {
                padding: 20px 10px;
              }

              &.bank {
              }

              &.creditCard {
                .adyen {
                  ul {
                    list-style: none;

                    li {
                      padding-left: 0;

                      &:before {
                        content: none;
                      }
                    }
                  }
                }

                .form {
                  display: flex;
                  flex-wrap: wrap;
                  margin-bottom: 32px;

                  .input {
                    width: calc(50% - 12px);

                    @include mobile {
                      width: 100%;
                    }

                    &.date {
                      display: flex;
                      align-items: flex-start;

                      .select {
                        width: 50%;

                        & + .select {
                          margin-left: 16px;
                        }
                      }
                    }

                    &:nth-child(even) {
                      margin-left: 24px;

                      @include mobile {
                        margin-left: 0;
                      }
                    }
                  }
                }
              }

              &.corporateClient {
                .formBlock {
                  display: flex;
                  // flex-wrap: wrap;

                  @include mobile {
                    flex-direction: column;
                  }

                  .form {
                    width: 50%;
                    margin-right: 24px;

                    @include mobile {
                      width: 100%;
                      margin-right: 0;
                    }
                  }

                  .notice {
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }

        &.three {
          .tabPanel {
            .line {
              width: calc(100% / 3);
            }
          }

          &.activeThree {
            .tabPanel {
              .line {
                left: calc(100% / 3 * 2);
              }
            }

            .tabsContainer {
              .tabsContents {
                transform: translateX(-200%);
              }
            }
          }

          &.activeTwo {
            .tabPanel {
              .line {
                left: calc(100% / 3);
              }
            }

            .tabsContainer {
              .tabsContents {
                transform: translateX(-100%);
              }
            }
          }
        }

        &.two {
          .tabPanel {
            .line {
              width: 50%;
            }
          }

          &.activeTwo {
            .tabPanel {
              .line {
                left: 50%;
              }
            }

            .tabsContainer {
              .tabsContents {
                transform: translateX(-100%);
              }
            }
          }
        }
      }
    }
  }
}
