@import 'styles/common';

.aboutUs {
  .title {
    margin: 0 0 60px;
    padding-top: 110px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent),
      url('/images/about-us/banner.png');
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 544px;

      @include mobile {
        min-height: 400px;
      }

      h1 {
        max-width: 540px;
        color: white;
      }

      .uploadBanner {
        position: absolute;
        top: 130px;
        right: 170px;
      }
    }
  }

  .description {
    position: relative;

    .descriptionContainer {
      padding-bottom: 100px;
    }

    .greyBlock {
      top: 0;
      right: 0;
      width: 211px;
      height: 211px;
    }

    .texts {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;

      .block {
        display: flex;
        margin-right: 76px;
        min-width: 280px;

        @include mobile {
          flex-direction: column;
          // max-width: initial;
          margin-right: 0;
        }

        & + .block {
          margin-top: 17px;
        }

        .text {
          max-width: 260px;

          @include mobile {
            max-width: initial;
          }

          & + .text {
            margin-left: 77px;

            @include mobile {
              margin-left: 0;
            }
          }
        }
      }
    }

    .quote {
      display: flex;
      flex-direction: column;
      order: 3;
      width: 316px;

      @include tablet {
        margin-top: 24px;
        padding-top: 32px;
      }

      > i {
        color: $red;
        font-size: 32px;
      }

      h4 {
        margin-top: 23px;

        @include tablet {
          margin-top: 16px;
        }
      }

      .signature {
        display: flex;
        align-items: flex-start;
        margin-top: 24px;
        line-height: 26px;
        font-size: 16px;
        font-weight: 400;

        .line {
          flex-shrink: 0;
          margin-top: 11px;
          margin-right: 6px;
          width: 37px;
          max-width: 176px;
          height: 1px;
          background-color: $main;
        }
      }
    }

    .slider {
      position: relative;
      margin-right: calc((-100vw + 1440px) / 2 - #{$container_padding_desktop});
      padding: 0 0 0 10px;

      @include desktop {
        margin-right: -$container_padding_desktop;
      }

      @include desktop {
        margin-right: -$container_padding_desktop;
      }

      @include laptop {
        margin-right: -$container_padding_laptop;
      }

      @include tablet {
        margin-right: -$container_padding_tablet;
      }

      @include mobile {
        margin-right: 0;
        padding: 0;
      }

      .editSlider {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
      }

      .slide {
        flex-shrink: 0;
        width: 540px;
        height: 361px;

        @include mobile {
          width: 259px;
          height: 177px;
        }

        & + .slide {
          margin-left: 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .facts {
    position: relative;

    @include mobile {
      background-color: $light;
    }

    .greyBlock {
      top: 0;
      left: 0;
      width: calc(100% - 226px);
      height: 100%;

      @include laptop {
        width: calc(100% - 113px);
      }
    }

    .facts {
      display: flex;
      flex-wrap: wrap;
      margin: -46px -38px 0;
      width: 100%;
      padding: 0 0 50px;

      @include mobile {
        margin: -32px -20px 0;
        padding: 0 0 32px;
      }

      .fact {
        margin: 46px 38px;
        max-width: 260px;

        @include mobile {
          flex: 1 0 calc(50% - 40px);
          margin: 32px 20px 0;
          min-width: 280px;
          max-width: initial;
        }

        h3 {
          margin-bottom: 16px;
        }

        p {
          line-height: 26px;
        }
      }
    }
  }

  // .partners {
  //   position: relative;
  //   top: 71px + $main_padding_bottom;
  //   background-color: $main;

  //   @include tablet {
  //     top: calc(#{71px + $main_padding_bottom / 2});
  //   }

  //   section {
  //     display: flex;
  //     align-items: center;
  //     padding: 35px 0;
  //     overflow-x: auto;
  //     overflow-y: hidden;
  //     border-bottom: 1px solid rgba($light, 0.1);

  //     .logo {
  //       & + .logo {
  //         margin-left: 40px;
  //       }
  //     }
  //   }
  // }
}
