@import 'styles/common';

.transfer {
  .breadcrumbs {
    @extend %breadcrumbs;
  }

  .content {
    section {
      display: flex;
      align-items: flex-start;

      .tripDetails {
        width: 372px;

        @include laptop {
          width: 320px;
        }
      }

      .transfers {
        margin-right: 20px;
        display: flex;
        max-width: calc(100% - 392px); // 372(width) + 20(margin)
        flex-direction: column;
        flex: 1;

        @include laptop {
          max-width: calc(100% - 332px); // 320(width) + 12(margin)
        }

        @include tablet {
          margin-right: 0;
          max-width: 100%;
          flex: 1;
        }

        @include mobile {
          max-width: calc(100% + #{$container_padding_mobile * 2});
          margin: 0 -#{$container_padding_mobile};
        }

        .transfersBlockNotice {
          display: flex;
          flex-direction: column;
          flex: 1;
          border-radius: 2px;
          background-color: white;
          padding: 40px;

          @include laptop {
            padding: 30px;
          }

          @include mobile {
            padding: 20px 10px;
          }
        }

        .transfersBlock {
          display: flex;
          flex-direction: column;
          flex: 1;
          border-radius: 2px;
          background-color: white;
          padding: 40px;

          @include laptop {
            padding: 30px;
          }

          @include mobile {
            padding: 20px 10px;
          }

          & + .transfersBlock {
            margin-top: 16px;
          }

          h3 {
            span {
              text-transform: capitalize;
            }
          }

          .mainPassenger {
            .row {
              display: flex;
              justify-content: space-between;
              margin-top: 24px;

              @include mobile {
                flex-direction: column;
              }

              .notice {
                height: 40px;
                align-items: center;

                @include mobile {
                  margin-top: 12px;
                }
              }

              .field {
                width: calc(50% - 10px);

                @include mobile {
                  width: 100%;

                  & + .field {
                    margin-top: 24px;
                  }
                }
              }

              &.isOther {
                align-items: flex-end;

                .field {
                  & + .field {
                    margin-top: 0;
                  }

                  .select {
                    select {
                      background-color: $light;
                    }
                  }
                }
              }
            }
          }

          .mainInfo {
            align-items: center;
            display: flex;
            background-color: $light;
            color: $main;
            font-size: 13px;
            border-radius: 2px;
            font-weight: 600;
            padding: 20px;
            margin-top: 16px;
            line-height: 14px;
            cursor: pointer;

            i {
              margin-right: 9px;
            }

            .check {
              height: 14px;
              width: 14px;
              border-radius: 2px;
              border: 1px solid #bdbdbd;
              position: relative;
              margin-right: 12px;
              flex-shrink: 0;
              cursor: pointer;

              &:after {
                position: absolute;
                border-radius: 2px;
                height: 8px;
                width: 8px;
                top: 2px;
                left: 2px;
                background-color: transparent;
                content: '';
                transition: 0.3s;
              }
            }

            .text {
              font-weight: 300;
              font-size: 15px;
              line-height: 20px;

              strong {
                font-weight: 600;
              }
            }

            &.selected {
              .check {
                &:after {
                  background-color: $red;
                }
              }
            }
          }

          .transferInfo {
            display: flex;
            margin-top: 16px;
            padding-bottom: 30px;
            margin-bottom: 24px;
            border-bottom: 1px solid $light;

            @include mobile {
              flex-direction: column;
              margin-top: 0;
            }

            .block {
              flex: 1;

              & + .block {
                margin-left: 20px;

                @include mobile {
                  margin-left: 0;
                }
              }

              .dropdownWrap {
                display: flex;
                flex-direction: column;

                .label {
                  margin-bottom: 6px;
                  color: $main;
                  line-height: 14px;
                  font-size: 12px;
                  margin-bottom: 6px;
                }
              }

              .notice {
                margin-top: 24px;

                p {
                  font-size: 12px;
                  line-height: 14px;

                  & + p {
                    margin-top: 8px;
                  }
                }

                @include mobile {
                  margin-bottom: 24px;
                }
              }

              .mainTarget {
                height: initial;
                min-height: 50px;
                padding-top: 10px;
                padding-bottom: 10px;
              }

              .target,
              .dropdownOption {
                align-items: center;
                display: flex;
                justify-content: space-between;
                flex: 1;

                .info {
                  display: flex;
                  flex-direction: column;

                  .transferName {
                    display: flex;
                    align-items: center;

                    .transferNameText {
                      font-size: 14px;
                      line-height: 15px;
                      overflow: hidden;
                    }

                    i {
                      font-size: 8px;
                      margin-left: 10px;
                      transition: 0.3s;
                    }
                  }

                  .description {
                    margin-top: 3px;
                    font-size: 11px;
                    line-height: 14px;
                    color: rgba($main, 0.5);
                  }
                }

                .price {
                  flex-shrink: 0;
                  margin-left: 20px;
                  font-size: 15px;
                }

                &.open {
                  i {
                    transform: rotate(180deg);
                  }
                }
              }

              .dropdownOption {
              }

              .map {
                img {
                  cursor: pointer;
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  border-radius: 2px;
                }
              }
            }
          }

          .fields {
            display: flex;
            align-items: flex-end;

            @include mobile {
              align-items: flex-start;
              flex-direction: column;
            }

            .select {
              margin-right: 32px;
              width: 280px;
            }

            .countOfPassengers {
              display: flex;
              align-items: flex-end;
            }

            .notice {
              max-width: 182px;
              margin: 5px 0;

              @include mobile {
                max-width: initial;
                margin: 12px 0 5px;
              }
            }

            .counter {
              display: inline-flex;
              flex-direction: column;
              margin-right: 32px;

              @include mobile {
                margin-right: 0;
              }

              label {
                margin-bottom: 6px;
                color: $main;
                line-height: 14px;
                font-size: 12px;
                margin-bottom: 6px;
              }

              .controls {
                border: 1px solid $light;
                border-radius: 2px;
                display: flex;
                height: 40px;

                .minus {
                  width: 40px;
                  font-size: 20px;
                }

                .plus {
                  padding: 0 20px;
                  font-size: 13px;
                }

                .plus,
                .minus {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  color: $red;
                  user-select: none;

                  &.disabled {
                    opacity: 0.5;
                    cursor: not-allowed;
                  }
                }

                .count {
                  font-size: 15px;
                  width: 60px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-left: 1px solid $light;
                  border-right: 1px solid $light;
                }
              }
            }
          }
        }

        .noTransfersBlock {
          display: flex;
          flex-direction: column;
          flex: 1;
          border-radius: 2px;
          background-color: white;
          padding: 40px;

          @include laptop {
            padding: 30px;
          }

          @include mobile {
            padding: 20px 10px;
          }

          & + .transfersBlock {
            margin-top: 16px;
          }

          .info {
            align-items: center;
            display: flex;
            background-color: $light;
            color: $red;
            font-size: 13px;
            font-weight: 600;
            padding: 20px;
            margin-top: 16px;
            line-height: 14px;
            cursor: pointer;

            i {
              margin-right: 9px;
            }
          }
        }
      }
    }
  }
}
