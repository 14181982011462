@import 'styles/common';

.editRoutes {
  overflow: visible !important;
  overflow-y: visible !important;
}

.home {
  .title {
    margin: 0 0 60px;
    padding-top: 100px;
    color: $red;

    &.titleDestinations {
      padding-top: 80px;

      @include tablet {
        margin: 0 0 32px;
        padding-top: 40px;
      }
    }

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    // background-image: linear-gradient(90deg, rgba(black, 0.85), transparent), url('/images/home/banner.png');
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 544px;
      padding: 141px 0 40px;

      @include laptop {
        padding-top: 116px;
      }

      @include mobile {
        padding-top: 100px;
      }

      .content {
        width: 100%;

        @include tablet {
          margin: initial;
        }

        h1 {
          max-width: 480px;
          color: white;

          &[data-v2] {
            margin: 11px 0 45px;
          }
        }

        .uploadBanner {
          position: absolute;
          top: 130px;
          right: 170px;
        }
      }
    }
  }

  .sliderDestination {
    position: relative;

    .greyBlock {
      top: 0;
      left: 0;
      width: 66%;
      height: 100%;
    }

    section {
      padding: 0;
    }

    .slider {
      position: relative;
      position: relative;
      padding-bottom: 30px;

      .editSlider {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
      }
    }

    .slide {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: space-between;
      width: 260px;
      height: 150px;
      padding: 20px;
      overflow: hidden;

      // min-width: 250px;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0, rgba(black, 0.7), transparent);
        background-position-y: 0;
        z-index: 1;
        transition: 0.3s ease-in-out;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: 0.3s ease-in-out;
      }

      .directions {
        margin-bottom: auto;
        color: white;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -0.5px;
        z-index: 1;
      }

      .tickets {
        color: $tan;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        cursor: pointer;
        z-index: 1;
      }

      &:hover {
        &::before {
          background-position-y: 40px;
        }

        .image {
          transform: scale(1.05);
        }
      }
    }

    .controls {
      display: flex;
      justify-content: flex-end;
      padding: 0 $container_padding_desktop;

      @include laptop {
        padding: 0 $container_padding_laptop;
      }

      @include tablet {
        padding: 0 $container_padding_tablet;
      }

      @include mobile {
        justify-content: center;
        padding: 0 $container_padding_mobile;
      }
    }
  }

  // .popularDestinationsSlider {
  //   margin-right: calc((-100vw + 1440px) / 2 - #{$container_padding_desktop});
  //
  //   @include laptop {
  //     margin-right: -$container_padding_laptop;
  //   }
  //
  //   @include tablet {
  //     margin-right: -$container_padding_tablet;
  //   }
  //
  //   @include mobile {
  //     margin-right: 0;
  //   }
  // }

  .checkDestinations {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }
  }

  .checkDestinationsLink {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 30px 0 0 30px;
    padding: 0 0 1px 0;
    color: #484c55;
    line-height: 18px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: 0.3s;

    @include mobile {
      display: none;
    }

    i {
      position: relative;
      margin: 0 0 0 6px;
      font-size: 11px;

      @include mobile {
        top: 1px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background: #484c55;
      transition: 0.15s;
    }

    &:hover {
      &::after {
        background: transparent;
      }
    }
  }

  .traveling {
    position: relative;

    .greyBlock {
      top: 0;
      right: 0;
      width: 34%;
      height: 34vw;
      max-height: 440px;
    }
  }

  .tabsPanel {
    max-width: 75%;
    border-bottom: 1px solid #c30909;

    .tab {
      border-bottom: 1px solid transparent;
    }

    @include laptop {
      max-width: 75%;
    }

    @include tablet {
      max-width: 50%;
    }

    @include mobile {
      max-width: 100%;
    }
  }

  .tabsPanel {
    max-width: 75%;
    border-bottom: 1px solid #c30909;

    .tab {
      border-bottom: 1px solid transparent;
    }

    @include laptop {
      max-width: 75%;
    }

    @include tablet {
      max-width: 50%;
    }

    @include mobile {
      max-width: 100%;
    }
  }

  .destinations {
    position: relative;

    .map {
      &.notMob {
        position: absolute;
        right: 0;
        bottom: -156px;
        max-width: 752px;
        z-index: -3;

        @include laptop {
          right: -10%;
          bottom: -102px;
          max-width: 60%;
        }

        @include tablet {
          right: -25%;
          bottom: -100px;
        }

        @include mobile {
          display: none;
        }
      }

      &.mob {
        position: static;
        // top: initial;
        // bottom: initial;
        display: none;
        // max-width: initial;
        margin: auto;

        @include mobile {
          display: block;

          img {
            position: relative;
            right: -20px;
          }
        }
      }

      img {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;

        // @include mobile {
        //   height: auto;
        //   width: auto;
        // }
      }
    }

    .slider {
      position: relative;

      .editSlider {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
      }
    }

    .slide {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: flex-end;
      width: 372px;
      height: 372px;
      padding: 20px;
      overflow: hidden;
      z-index: 2;

      & + .slide {
        margin-left: 20px;
      }

      @include mobile {
        width: 320px;
        height: 320px;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: 0.3s ease-in-out;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          0,
          rgba(black, 0.7),
          30.73%,
          transparent 50%
        );
        background-position-y: 0;
        z-index: 1;
        transition: 0.3s ease-in-out;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        object-fit: cover;
        object-position: center;
      }

      .direction {
        position: relative;
        color: white;
        line-height: 32px;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: -1px;
        z-index: 2;
      }

      .servises {
        position: relative;
        margin: 1px 0 12px;
        color: rgba(white, 0.7);
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.5px;
        z-index: 2;
      }

      .discover {
        position: relative;
        color: $tan;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        z-index: 2;
      }

      &:hover {
        &::before {
          background-position-y: 60px;
        }

        .image {
          transform: scale(1.05);
        }
      }
    }

    .controls {
      display: flex;
      align-items: center;
      // padding: 0 $container_padding_desktop;
      //
      // @include laptop {
      //   padding: 0 $container_padding_laptop;
      // }
      //
      // @include tablet {
      //   padding: 0 $container_padding_tablet;
      // }

      @include mobile {
        justify-content: center;
        // padding: 0 $container_padding_mobile;
      }
    }

    .description {
      margin: 62px 0 33px;
      max-width: 480px;

      &.descriptionOperates {
        margin: 100px 0 33px;

        @include tablet {
          margin: 40px 0 33px;
        }
      }

      @include mobile {
        margin: 62px 0 0;
      }

      h3 {
        margin-bottom: 20px;
        line-height: 42px;
        font-size: 32px;

        @include mobile {
          line-height: 30px;
          font-size: 28px;
        }
      }

      p {
        line-height: 24px;
        font-size: 16px;
        letter-spacing: -0.5px;

        @include mobile {
          line-height: 22px;
          font-size: 14px;
        }
      }
    }

    .tabsDirection {
      max-width: 820px;
      min-height: 356px;

      @include mobile {
        min-height: 0;
      }

      .tabContent {
      }

      .roadConnections {
        display: flex;
        flex-wrap: wrap;
        max-height: 604px;
        padding: 10px 0 0;

        @include tablet {
          //flex-wrap: nowrap;
          //flex-direction: column;
        }

        @include mobile {
          flex-direction: column;
          flex-wrap: nowrap;
          max-height: 100%;
        }

        .direction {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          width: 340px;
          height: 38px;
          padding: 0 10px 0 0;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.5px;
          transition: 0.3s;

          @include mobile {
            width: initial;
            max-width: initial;
          }

          .point {
            flex: 1;
            max-width: 60px;
            line-height: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &.to {
              max-width: 140px;
              text-align: left;
            }
          }

          img {
            margin: 0 26px;

            @include mobile {
              margin: 0 15px;
            }
          }

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

  .lounge {
    position: relative;

    .greyBlock {
      position: absolute;
      right: 0;
      bottom: -202px;
      width: 39%;
      height: calc(100% + 202px);

      @include tablet {
        bottom: -115px;
        height: calc(100% + 115px);
      }
    }

    .sliderLounge {
      position: relative;
      display: flex;
      margin-right: calc((-100vw + 1440px) / 2 - #{$container_padding_desktop});

      @include desktop {
        margin-right: -$container_padding_desktop;
      }

      @include laptop {
        margin-right: -$container_padding_laptop;
      }

      @include tablet {
        margin-right: -$container_padding_tablet;
      }

      @include mobile {
        flex-direction: column;
        margin-right: 0;
      }

      .editSlider {
        position: absolute;
        top: 0;
        right: calc((100vw - 1440px) / 2 + #{$container_padding_desktop});
        z-index: 1;

        @include desktop {
          right: $container_padding_desktop;
        }

        @include laptop {
          right: $container_padding_laptop;
        }

        @include tablet {
          right: $container_padding_tablet;
        }
      }

      .slide {
        flex-shrink: 0;
        width: calc(50% - 9px);
        height: 359px;
        object-fit: cover;

        @include tablet {
          height: 288px;
        }

        @include mobile {
          width: 259px;
          min-width: initial;
          height: 177px;
        }

        & + .slide {
          margin-left: 18px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .descriptions {
        display: flex;
        flex-direction: column;
        margin-right: 66px;

        @include mobile {
          margin: 0 0 40px;
        }
      }

      .controls {
        margin-top: auto;

        @include mobile {
          margin: 24px auto 0;
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        margin: 0 0 50px 0;
        width: 204px;

        @include mobile {
          margin: 0;
          width: initial;
        }

        .subTitle {
          margin-bottom: 20px;
          line-height: 30px;
          font-size: 20px;
          font-weight: 600;
          letter-spacing: -0.5px;
        }

        .text {
          line-height: 24px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  .forLuxexpress {
    position: relative;

    .greyBlock {
      position: absolute;
      top: 202px;
      left: 0;
      width: 22%;
      height: 100%;

      @include tablet {
        top: 112px;
      }
    }

    .sliderPeople {
      position: relative;
      display: flex;
      align-items: flex-start;
      padding-top: 60px;

      .editSlider {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
      }

      @include mobile {
        align-items: center;
        flex-direction: column;
      }

      .slides {
        position: relative;
        flex-shrink: 0;
        width: 260px;

        // @include tablet {
        //   margin-right: 81px;
        // }

        @include mobile {
          margin: 0 0 16px;
        }

        .slide {
          width: 100%;
          min-width: 100%;
          height: 260px;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .divider {
        @include mobile {
          bottom: 13px;
        }
      }

      .container {
        display: flex;
        flex-direction: column;
        margin-left: 76px;

        @include mobile {
          margin-left: 0;
        }

        .content {
          display: flex;

          @include tablet {
            flex-direction: column;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-right: 76px;
          width: 260px;
          height: 300px;
          height: 300px;
          max-height: 300px;
          max-height: 300px;
          overflow: hidden;
          overflow: hidden;

          @include tablet {
            margin: 0;
            width: initial;
            height: auto;
            max-height: 100%;
          }

          .description {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            order: 1;

            &:nth-child(2) {
              display: flex;
            }

            .subTitle {
              margin-bottom: 30px;
              line-height: 32px;
              font-size: 26px;
              font-weight: 600;
              letter-spacing: -1px;

              @include tablet {
                margin-bottom: 8px;
                font-size: 24px;
              }
            }

            p {
              // font-size: 16px;
              // line-height: 24px;
              // letter-spacing: -0.5px;

              // @include tablet {
              //   font-size: 14px;
              //   line-height: 22px;
              // }
            }
          }
        }

        .quote {
          display: flex;
          flex-direction: column;
          margin-top: 6px;
          width: 100%;
          max-width: 316px;
          height: 300px;
          max-height: 300px;
          padding-top: 38px;
          overflow: hidden;

          @include tablet {
            margin-top: 24px;
            width: initial;
            max-width: 100%;
            height: auto;
            max-height: 100%;
          }

          i {
            color: $red;
            font-size: 32px;
          }

          h4 {
            margin-top: 23px;

            @include tablet {
              margin-top: 16px;
            }
          }
        }

        .controls {
          margin: 0 0 33px;

          @include mobile {
            margin: 24px auto;
          }
        }
      }

      .contentSLideContainer {
        @include tablet {
          height: 500px;
        }

        @include mobile {
          height: auto;
        }
      }

      .containerSlidesControl {
        width: 100%;
      }
    }
  }

  .icon {
    font-size: 15px;
  }
}
