@import 'styles/common';

.controlPanel {
  padding-top: calc(#{$header_height_desktop + 40px});

  @include tablet {
    padding-top: calc(#{$header_height_tablet + 30px});
  }

  .panel {
    display: flex;
    align-items: center;

    .tab {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      height: 30px;
      padding: 10px;
      color: $main;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      white-space: nowrap;
      cursor: pointer;
      border-bottom: 1px solid $red;
      transition: 0.3s;

      .check {
        margin-left: 10px;
      }

      &.active {
        color: white;
        background-color: $red;
      }
    }
  }

  .tabs {
    .tab {
      flex: 1;
      justify-content: center;

      img {
        width: 12px;
      }

      &.disabled {
        img {
          filter: grayscale(100%);
        }
      }
    }

    .tabContainer {
      padding: 0;
    }

    fieldset {
      padding: 36px 44px;

      @include mobile {
        padding: 18px 0;
      }

      & + fieldset {
        border-top: 2px solid rgba($main, 0.1);
      }

      legend {
        margin-right: -10px;
        padding: 0 10px;
        color: rgba($main, 0.4);
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }

    .switches {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .switchItem {
        display: flex;
        align-items: center;
        margin: 0 20px 20px 0;
        text-transform: uppercase;

        span {
          margin-right: 10px;
          line-height: 18px;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }

    .field {
      position: relative;
      display: flex;
      align-items: center;

      @include mobile {
        align-items: stretch;
        flex-direction: column;
      }

      & + .field {
        margin-top: 28px;
      }

      .suggestions {
        position: absolute;
        bottom: calc(100% + 6px);
        left: 0;
        min-width: 300px;
        max-height: 278px;
        padding: 19px 16px;
        overflow: auto;
        border-radius: 2px;
        background-color: white;
        box-shadow: 0 4px 20px rgba($main, 0.2);
        z-index: 1;

        @keyframes pulse {
          from {
            opacity: 1;
            transform: scale(1);
          }

          to {
            opacity: 0.25;
            transform: scale(0.75);
          }
        }

        .loading {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
        }

        .wrap {
          position: absolute;
          top: 100%;
          left: 14px;
          filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.15));

          .triangle {
            width: 22px;
            height: 12px;
            background-color: white;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
          }
        }

        .townCountry {
          cursor: pointer;

          & + .townCountry {
            margin-top: 24px;
          }

          i {
            margin-right: 7px;
            color: $tan;
          }
        }
      }

      .title {
        align-self: flex-start;
        margin-right: 40px;
        width: 144px;
        line-height: 19px;
        font-size: 16px;

        .info {
          margin-top: 2px;
          color: rgba($main, 0.5);
          line-height: 16px;
          font-size: 13px;
          letter-spacing: -0.2px;
        }
      }

      .input {
        flex: 1;
        border: 1px solid $light;

        .label {
          &.error {
            // color: rgba($red, 0.75);

            .amount {
              color: $red;
            }
          }
        }
      }

      .button {
        margin-left: 16px;
      }

      .explanation {
        margin-left: 29px;
        width: 200px;
        color: rgba($main, 0.5);
        line-height: 16px;
        font-size: 13px;

        @include mobile {
          margin: 10px 0 0;
          width: auto;
        }
      }

      // custom style for some fields

      &.template {
        // @include mobile {
        //   flex-direction: row;
        // }

        .title {
          align-self: center;
        }
      }

      &.slug {
        .inputWithSwitcher {
          position: relative;
          flex: 1;

          .input {
            display: flex;
          }

          .switch {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
          }

          .message {
            position: absolute;
            bottom: -21px;
            left: 0;
            padding: 0 20px;
            color: #2cc309;
            font-size: 14px;

            &.error {
              color: $red;
            }
          }
        }
      }

      &.dropzone {
        .title {
          align-self: center;
        }

        .dropzone {
          width: 200px;
          height: 152px;

          .withImage,
          .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 4px;
            background-size: cover;

            .action {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 44px;
              height: 41px;
              cursor: pointer;
              border-radius: 4px;
              background-color: white;

              & + .action {
                margin-left: 16px;
              }
            }
          }
        }
      }

      &.preview {
        .googlePreview {
          font-weight: normal;
          font-family: arial, sans-serif;

          .searchTitle {
            margin-bottom: 5px;
            color: #12c;
            line-height: 25px;
            font-size: 18px;
          }

          .searchUrl {
            margin-bottom: 1px;
            color: #00852b;
            line-height: 18px;
            font-size: 14px;
          }

          .searchDescription {
            color: #545454;
            line-height: 1.4;
            font-size: 13px;
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
}
