@import 'styles/common';

.banks {
  h3 {
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .loading {
    margin-bottom: 30px;
  }

  .banksList {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 32px;

    .bank {
      cursor: pointer;
      margin: 10px;
      height: 100px;
      padding: 5px;
      width: calc(50% - 20px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: rgba($main, 0.3);
      border-radius: 4px;
      border: 1px solid rgba($main, 0.15);
      transition: 0.3s;
      background-position: center;

      img {
        max-height: 50px;
        max-width: 100px;
        object-fit: contain;
      }

      &:hover {
        border-color: rgba($main, 0.5);
        color: rgba($main, 0.75);
      }
    }
  }
}
