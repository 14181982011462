@import 'styles/common';

.dropzone {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2px;
  transition: opacity 0.3s;

  .loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      margin-bottom: 16px;
      width: 36px;
      height: 36px;
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      0% {
        transform: rotateZ(0deg);
      }

      100% {
        transform: rotateZ(360deg);
      }
    }
  }

  .withImage {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background-position: center;
    background-origin: content-box;
    background-size: contain;

    .placeholder {
      position: absolute;
      top: 0;
      left: 0;
      color: rgba($main, 0.34);
      line-height: 20px;
      font-size: 13px;
    }

    .remove {
      position: absolute;
      top: 10px;
      right: 10px;
      color: white;
      cursor: pointer;
    }
  }

  .empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px dashed rgba($main, 0.3);
    border-radius: 2px;

    .size {
      margin: 16px 0 8px;
      line-height: 22px;
      font-size: 18px;
      letter-spacing: -0.5px;
    }
  }

  &.dragEnter {
    opacity: 0.25;
    cursor: grabbing;
  }
}
