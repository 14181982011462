@import 'styles/common';

.content {
  @include mobile {
    height: calc(100% - 65px);
  }
}

.editOrderWidget {
  position: relative;

  @include mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .items {
    width: 652px;
    height: 315px;
    overflow: auto;

    @include mobile {
      width: 100%;
      height: 100%;
      padding-bottom: 70px;
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;

      .emptyText {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light;
        font-size: 40px;
        text-transform: uppercase;
      }
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      padding: 14px 16px;
      border: 1px solid $light;
      border-radius: 2px;

      .dots {
        display: flex;
        flex-direction: column;
        margin-right: 32px;

        .dot {
          width: 4px;
          height: 4px;
          background-color: $light;

          & + .dot {
            margin-top: 4px;
          }
        }
      }

      .title {
      }

      .panel {
        display: flex;
        align-items: center;
        margin-left: auto;

        .control {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          color: white;
          cursor: pointer;
          border-radius: 2px;
          background-color: $red;

          & + .control {
            margin-left: 16px;
          }
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    background-color: white;

    @include mobile {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    button {
      margin-right: 20px;
    }

    span {
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: $red;
      }
    }

    .addItem {
      margin-right: auto;
      color: $red;
      background: transparent;
    }
  }
}
