@import 'styles/common';

.menu {
  min-width: 320px;
  // width: 350px;

  @include laptop {
    // width: 320px;
    min-width: 270px;
  }

  @include tablet {
    display: none;
  }

  .item {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: 600px;
    border-radius: 2px;
    font-size: 16px;
    padding: 0 20px 0 24px;
    text-transform: initial;
    cursor: pointer;
    background-color: white;
    transform: 0.3s;
    letter-spacing: 0;

    i {
      font-weight: 300;
      font-size: 12px;
    }

    & + .item {
      margin-top: 1px;
    }
  }

  .active {
    color: $red;

    &:before {
      border-radius: 2px 0 0 2px;
      width: 4px;
      height: 100%;
      left: 0;
      content: '';
      top: 0;
      background-color: $red;
      position: absolute;
    }
  }
}
