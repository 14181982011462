@import 'styles/common';

.editSliderModalContent {
  overflow: visible !important;
  overflow-y: visible !important;
}

.route {
  .title {
    margin: 0 0 60px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .infoOptions {
    max-width: 504px;

    @include tablet {
      max-width: 100%;
    }

    p {
      max-width: 380px;

      @include tablet {
        max-width: 100%;
      }
    }
  }

  .visibility {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    background-color: white;

    span {
      color: rgba($main, 0.5);
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .switch {
      margin-left: 10px;
    }
  }

  .bannerContainer {
    background-color: $main;
    background-image: url('/images/route/map.png');
    background-position-x: right;
    background-size: contain;

    @include tablet {
      background-image: none !important;
    }

    @include tablet {
      background-image: none !important;
    }

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 544px;
      padding: 141px 0 40px;

      @include laptop {
        padding-top: 116px;
      }

      @include mobile {
        padding-top: 100px;
      }

      .uploadBanner {
        position: absolute;
        top: 130px;
        right: 170px;
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;

        h1 {
          margin-bottom: 24px;
          color: white;
        }

        .details {
          display: flex;
          align-items: flex-start;

          @include mobile {
            flex-wrap: wrap;
          }

          p {
            max-width: 426px;
            color: white;

            @include mobile {
              flex: 100%;
              margin-bottom: 24px;
              max-width: initial;
            }
          }

          .dateTime {
            display: flex;
            flex-direction: column;
            margin-left: 50px;
            display: none;

            @include mobile {
              margin-left: 0;

              & + .dateTime {
                padding-left: 40px;
              }
            }

            .key {
              line-height: 32px;
              font-size: 24px;
              letter-spacing: -0.5px;
            }
          }
        }
      }
    }
  }

  .mainStops {
    section {
      position: relative;

      .shadow {
        width: $container_padding_desktop;
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 1;

        @include laptop {
          width: $container_padding_laptop;
        }

        @include tablet {
          width: $container_padding_tablet;
        }

        @include mobile {
          width: $container_padding_mobile;
        }

        &.left {
          left: 0;
          background-image: linear-gradient(
            left,
            white,
            rgba(255, 255, 255, 0.01)
          );
        }

        &.right {
          right: 0;
          background-image: linear-gradient(
            right,
            white,
            rgba(255, 255, 255, 0.01)
          );
        }
      }

      .stops {
        position: relative;
        display: flex;
        align-items: flex-start;
        // margin-bottom: 80px;
        overflow: hidden;
        overflow-x: auto;
        padding: 0 calc((100vw - 1440px) / 2 + #{$container_padding_desktop});
        position: relative;

        @include laptop {
          padding: 0 $container_padding_laptop;
        }

        @include tablet {
          padding: 0 $container_padding_tablet;
        }

        @include mobile {
          padding: 0 $container_padding_mobile;
        }

        .editSlider {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
        }

        .stop {
          display: flex;
          flex: 1;
          flex-direction: column;
          min-width: 175px;

          & + .stop {
            margin-left: 8px;
          }

          .line {
            display: flex;
            align-items: center;
            margin-bottom: 18px;

            .l {
              flex: 1;
              margin-left: 8px;
              height: 1px;
              background-color: rgba($main, 0.1);
            }
          }

          .dot {
            width: 9px;
            height: 9px;
            border: 1px solid $main;
            border-radius: 50%;
          }

          .onMap {
            display: inline-flex;
            margin: 8px 0;
            color: $tan;
            line-height: 14px;
            font-size: 10px;
            cursor: pointer;

            &::before {
              content: '';
              position: relative;
              display: block;
              width: 13px;
              height: 13px;
              background-image: url('/images/route/map-icon.svg');
              background-size: contain;
            }
          }

          .address {
            max-width: 162px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
          }

          .dropOff {
            margin-top: 12px;
            color: $red;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;
          }

          &.firstLast {
            .dot {
              background-color: $main;
            }

            h5 {
              line-height: 42px;
              font-size: 32px;
              letter-spacing: -2px;
            }
          }
        }
      }
    }
  }

  .calendar {
    .mainInfo {
      display: flex;
      margin-top: 80px;
      // flex-wrap: wrap;

      @include tablet {
        flex-direction: column;
      }

      .calendar {
        flex: 1;
        margin-right: 20px;

        @include tablet {
          margin: 0 0 20px;
          max-width: initial;
        }
      }

      .schedule {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 484px;
        padding: 40px;
        border: 1px solid rgba($main, 0.1);
        border-radius: 2px;

        @include tablet {
          max-width: initial;
        }

        @include mobile {
          padding: 15px;
        }

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 48px;

          h3 {
            text-transform: capitalize;
          }

          .date {
            color: $red;
          }
        }

        .item {
          display: flex;

          & + .item {
            margin-top: 30px;
            padding-top: 30px;
            border-top: 1px solid rgba($main, 0.1);

            @include mobile {
              margin-top: 16px;
              padding-top: 16px;
            }
          }

          .duration {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-around;
            color: rgba($main, 0.4);
            font-size: 12px;
            text-transform: uppercase;

            @include mobile {
              justify-content: space-between;
            }

            .text {
              color: $red;
              text-transform: uppercase;

              @include mobile {
                div + div {
                  margin-top: 5px;
                  text-align: center;
                }
              }
            }
          }

          .point {
            flex: 1;
            font-size: 12px;
            text-transform: uppercase;

            h5 {
              margin-top: auto;
            }

            .name {
              margin-bottom: 4px;
              line-height: 15px;
            }
          }

          .from {
          }

          .to {
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            .name {
              text-align: right;
            }
          }
        }

        .controls {
          margin: 60px auto 0;
        }
      }
    }
  }

  .seats {
    margin-top: 80px;

    section {
      display: flex;
      padding: 60px 56px;
      background-color: $light;

      @include laptop {
        flex-direction: column;
        padding: 45px;
      }

      @include mobile {
        padding: 20px;
      }

      h2 {
        margin: 0;
        padding-top: 0;
      }

      p {
        margin: 10px 0 31px;
      }

      .seats {
        display: flex;
        align-items: center;
        margin-top: 0;
        margin-left: 50px;

        @include laptop {
          flex-wrap: wrap;
          margin: 40px 0 0;
        }

        @include mobile {
          align-items: flex-start;
          flex-direction: column;
        }

        .seat {
          & + .seat {
            margin-left: 67px;

            @include mobile {
              margin: 30px 0 0;
            }
          }

          @include mobile {
            display: flex;
            align-items: center;
            height: 52px;
          }

          img {
            height: 100%;
            object-fit: contain;

            @include mobile {
              width: 123px;
            }
          }

          h5 {
            margin-top: 26px;

            @include mobile {
              margin: 0 0 0 15px;
            }
          }
        }
      }
    }
  }

  .traveling {
    position: relative;

    .greyBlock {
      top: 60px;
      right: 0;
      width: 47%;
      height: 35vw;
      max-height: 440px;
    }
  }

  .transportation {
    section {
      // align-items: center;
      display: flex;
      align-items: center;
      padding-top: 60px;

      @include mobile {
        flex-direction: column-reverse;
      }

      .image {
        position: relative;
        width: 40%;

        &.empty {
          padding-top: 40%;
          background-color: $light;
        }

        @include laptop {
          flex-shrink: 0;
          max-width: 400px;
        }

        @include mobile {
          margin-top: 40px;
          width: auto;
        }

        img {
          width: 100%;
        }

        .uploadBanner {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .info {
        flex: 1;
        margin-left: 77px;

        @include mobile {
          margin-left: 0;
        }

        h2 {
          max-width: 450px;
          padding-top: 0;
        }

        .texts {
          max-width: 580px;

          @include laptop {
            max-width: 100%;
          }

          p + p {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .transfer {
    position: relative;
    margin-top: 100px;

    .greyBlock {
      top: 0;
      right: 0;
      width: calc((100vw - 1280px) / 2 + 300px);
      height: 440px;

      @include laptop {
        height: 350px;
      }
    }

    section {
      padding-top: 60px;

      .content {
        display: flex;
        flex-wrap: wrap;

        @include tablet {
          flex-direction: column;
        }

        .info {
          flex: 1;

          @include tablet {
            flex: 50%;
          }

          h2 {
            margin: 0 0 40px;
            padding-top: 0;
          }

          h5 {
            margin: 0 0 32px;
          }

          .link {
            margin-top: 32px;
            font-size: 14px;
            text-decoration: underline;
            text-transform: uppercase;
            letter-spacing: 1px;

            i {
              margin-left: 8px;
            }
          }

          .linkButton {
            position: relative;
            display: inline-flex;
            align-items: center;
            padding: 0 0 1px 0;
            color: #484c55;
            line-height: 18px;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;

            i {
              position: relative;
              margin: 0 0 0 6px;
              font-size: 11px;

              @include mobile {
                top: 1px;
              }
            }

            &::after {
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              height: 1px;
              background: #484c55;
            }

            &:hover {
              &::after {
                content: none;
              }
            }
          }
        }

        .list {
          flex: 1;
          padding: 0 76px;

          @include tablet {
            flex: 50%;
            margin-top: 40px;
            padding: 0;
          }

          .item {
            display: flex;
            align-items: flex-start;
            line-height: 26px;
            font-size: 16px;
            font-weight: 400;

            & + .item {
              margin-top: 8px;
            }

            .circle {
              position: relative;
              top: 6px;
              flex-shrink: 0;
              flex-shrink: 0;
              margin-right: 8px;
              width: 6px;
              height: 6px;
              border: 1px solid $red;
              border-radius: 50%;
            }
          }

          .text {
          }
        }

        .image {
          position: relative;
          flex: 1;
          min-width: 500px;

          @include laptop {
            min-width: 350px;
          }

          @include tablet {
            flex: 100%;
            margin-top: 40px;
            min-width: initial;
          }

          &.empty {
            padding-top: 40%;
            background-color: $light;
          }

          img {
            width: 100%;
          }

          .uploadBanner {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }

    @include mobile {
      margin-top: 0;
    }
  }

  .otherRoutes {
    margin-top: 80px;

    section {
      padding-top: 60px;

      h3 {
        margin-bottom: 40px;
      }
    }

    @include mobile {
      margin-top: 0;
    }
  }

  &.editMode {
    .greyBlock {
      display: none;
    }
  }
}
