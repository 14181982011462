@import 'styles/common';

.seatDetails {
  .breadcrumbs {
    @extend %breadcrumbs;
  }

  .passangers {
    section {
      display: flex;
      align-items: flex-start;

      .tripDetails {
        width: 372px;

        @include laptop {
          width: 320px;
        }
      }

      .tabs {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: 20px;
        max-width: calc(100% - 392px); // 372(width) + 20(margin)
        border-radius: 2px;
        background-color: white;

        @include laptop {
          max-width: calc(100% - 332px); // 320(width) + 12(margin)
        }

        @include tablet {
          flex: 1;
          margin-right: 0;
          max-width: 100%;
        }

        @include mobile {
          margin: 0 -#{$container_padding_mobile};
          max-width: calc(100% + #{$container_padding_mobile * 2});
        }

        .tabPanel {
          position: relative;
          display: flex;
          width: 100%;
          border-bottom: 1px solid $light;

          .line {
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $red;
            transition: 0.3s;
          }

          .tab {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 60px;
            padding: 0 5px;
            color: $main;
            line-height: 15px;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            transition: 0.3s;

            @include mobile {
              font-size: 14px;
            }

            span {
              line-height: 22px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            i {
              margin: 0 16px;
              font-size: 10px;

              @include mobile {
                margin: 0 8px;
                font-size: 7px;
              }
            }

            &.active {
              color: $red;
            }
          }
        }

        .tabsContainer {
          overflow: hidden;

          .tabsContents {
            position: relative;
            display: flex;
            flex: 1;
            align-items: flex-start;
            width: 100%;
            transition: 0.3s;
            transform: translateX(0);

            .tabsContent {
              flex-shrink: 0;
              width: 100%;
              padding: 40px;

              @include laptop {
                padding: 30px;
              }

              @include mobile {
                padding: 20px 5px;
              }

              .seatTypes {
                display: none;

                &.show {
                  display: flex;
                }
              }

              .info {
                display: flex;
                align-items: flex-start;

                .loading {
                  width: 100%;
                }

                .autobus {
                  margin-right: 40px;

                  @include tablet {
                    margin-right: 30px;
                  }

                  @include mobile {
                    margin-right: 0;
                  }

                  .noSeatsBus {
                    position: relative;
                    width: 250px;

                    .texts {
                      position: absolute;
                      right: 0;
                      left: 0;
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      margin: auto;
                      width: 100%;
                      max-width: 145px;
                      padding-top: 88px;

                      .title {
                        margin-bottom: 9px;
                        line-height: 20px;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: center;
                      }

                      .subTitle {
                        line-height: 14px;
                        font-size: 12px;
                        font-weight: 300;
                        text-align: center;
                      }
                    }

                    img {
                      width: 100%;
                    }
                  }
                }

                .ticketInfo {
                  flex: 1;
                  transition: 0.25s;

                  .notice {
                    margin-bottom: 20px;
                  }

                  @include mobile {
                    width: 100%;
                  }
                }

                .autobus {
                  & + .ticketInfo {
                    max-width: calc(100% - 290px); // 250(width) + 40(margin)

                    @include laptop {
                      // max-width: calc(100% - 290px); // 250(width) + 30(margin)
                    }

                    @include tablet {
                      max-width: calc(100% - 280px); // 250(width) + 30(margin)
                    }

                    @include mobile {
                      margin-left: 0;
                      max-width: 100%;
                    }
                  }
                }
              }
            }
          }
        }

        &.openDropDown {
          .tabsContainer {
            // overflow: initial;
            .ticketInfo {
              padding-bottom: 288px;
            }
          }
        }

        &.activeTwo {
          .tabPanel {
            .line {
              left: 50%;
            }
          }

          .tabsContainer {
            .tabsContents {
              transform: translateX(-100%);
            }
          }
        }

        // &.activeOne {
        //   .tabPanel {
        //     .line {
        // width: 100%;
        //     }
        //   }
        // }

        &.two {
          .tabPanel {
            .line {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
