@import 'styles/common';

.countdown {
  display: inline-flex;
  flex-direction: column;

  .numbers {
    display: flex;
    padding: 8px 0;
    border: 1px solid rgba($main, 0.1);
    border-radius: 2px;

    div {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      min-width: 64px;
      height: 32px;
      padding: 0 16px;
      color: $red;
      font-size: 24px;
      letter-spacing: -0.5px;

      & + div {
        border-left: 1px solid rgba($main, 0.1);
      }
    }
  }

  .text {
    display: flex;
    margin-top: 6px;

    div {
      display: flex;
      flex: 1;
      justify-content: center;
      min-width: 64px;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}
