@import 'styles/common';

.tripDetails {
  .countdown {
    transform: scale(0.65);
    position: absolute;
    top: 100%;
    right: -23px;
    z-index: 5;
  }

  .block {
    padding: 16px 24px;
    border-radius: 2px;
    background-color: white;

    @include tablet {
      padding: 16px $container_padding_tablet;
    }

    @include mobile {
      padding: 16px $container_padding_mobile;
    }

    &.voucherBlock {
      padding: 0 24px 16px;

      @include tablet {
        padding: 0 $container_padding_tablet 16px;
      }

      @include mobile {
        padding: 0 $container_padding_mobile 16px;
      }
    }

    .approvedVoucher {
      display: flex;
      align-items: center;
      height: 54px;
      border-bottom: 1px solid $light;

      i {
        margin-right: 9px;
        color: $green;
      }

      .code {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      .count {
        font-size: 16px;
        line-height: 20px;
        opacity: 0.7;
        margin-left: auto;
      }
    }

    .approvedVoucher + .price,
    .voucher + .price {
      margin-top: 16px;
    }

    .title {
      @include tablet {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
      }
    }

    .info {
      margin-top: 15px;
      padding-top: 24px;
      border-top: 1px solid $light;

      & + .info {
        margin-top: 24px;
      }

      .type {
        display: flex;
        align-items: center;
        margin-bottom: 17px;
        line-height: 12px;
        font-size: 12px;
        font-weight: 400;

        .circle {
          margin-right: 10px;
          width: 14px;
          height: 14px;
          padding: 2px;
          border: 1px solid $tan;
          border-radius: 50%;
          background-color: $tan;
          background-clip: content-box;
        }

        .clear {
          margin-left: auto;
          color: $red;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }

      h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        line-height: 20px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;

        i {
          font-size: 10px;
          transition: 0.3s;
        }
      }

      .details {
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;

        span {
          font-weight: 600;
        }
      }

      .stops {
        margin-top: 16px;
      }

      &.open {
        h4 {
          i {
            transform: rotateZ(180deg);
          }
        }
      }
    }

    .price {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: space-between;
      height: 32px;
      font-weight: 400;

      // &.first {
      //   margin-top: 16px;
      // }

      .text {
        flex-shrink: 0;
      }

      .description {
        margin: 0 18px;
        line-height: 20px;
        text-overflow: ellipsis;
        text-transform: initial;
        opacity: 0.7;
        overflow: hidden;
        white-space: nowrap;
      }

      span {
        opacity: 0.7;
        white-space: nowrap;
      }

      &.total {
        margin-top: 17px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 600;

        span {
          opacity: 1;
        }

        @include tablet {
          display: none;
        }
      }
    }

    & + .block {
      margin-top: 8px;

      @include tablet {
        margin-top: auto;
        padding: 32px inherit;
        border-top: 1px solid $light;
        border-bottom: 1px solid $light;
      }
    }
  }

  .button {
    margin-top: 16px;
    width: 100%;
    line-height: 40px;
    height: 40px;

    @include tablet {
      display: none;
    }
  }

  &.default {
    position: sticky;
    top: 0;
    flex-shrink: 0;
    // margin-left: 20px;
    // width: 372px;

    // @include laptop {
    //   width: 320px;
    // }

    @include tablet {
      display: none;
      // position: initial;
      // top: initial;
      // margin-left: 0;
    }
  }

  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 94px - #{$header_height_desktop});
    background-color: white;
    z-index: 1;
    transition: 0.5s;
    transform: translateY(100%);
    will-change: transform;

    @include tablet {
      height: calc(var(--vh, 1vh) * 100 - 81px - #{$header_height_tablet});
    }

    &.open {
      bottom: 94px;
      overflow: auto;
      transform: translateY(0);

      @include tablet {
        bottom: 81px;
      }
    }
  }
}

.tripDetailsFixed {
  position: fixed;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10;

  @include tablet {
    display: block;
  }

  .countdown {
    background-color: white;
    transform: scale(0.65);
    position: absolute;
    bottom: calc(100% - 24px);
    right: -23px;
    z-index: 5;

    &.hide {
      display: none;
    }
  }

  section.section {
    position: relative;
    display: flex;
    align-items: center;
    height: 94px;
    padding-top: 0 !important;
    background-color: white;
    z-index: 10;

    @include tablet {
      height: 81px;
    }

    .total {
      display: flex;
      align-items: center;
      margin-right: auto;

      .price {
        display: flex;
        align-items: center;
        margin-left: 24px;

        @include mobile {
          align-items: flex-start;
          flex-direction: column;
          margin-left: 14px;
        }

        span {
          margin-right: 16px;
          font-size: 12px;
          text-transform: uppercase;
        }

        div {
          font-size: 24px;

          @include mobile {
            margin-top: 4px;
            font-size: 20px;
          }
        }
      }

      i {
        font-size: 9px;
        opacity: 0.7;
        transition: 0.2s;
        transform: rotateZ(180deg);
      }
    }

    .button {
      height: 40px;
      line-height: 40px;
    }
  }

  &.open {
    section.section {
      .total {
        i {
          transform: rotateZ(0);
        }
      }
    }

    .countdown {
      right: -2px;
      bottom: initial;
      top: 0px;
    }
  }
}
