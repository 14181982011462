@import 'styles/common';

.selectWrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;
  cursor: pointer;

  &.withoutMessage {
    padding-bottom: 0;
  }

  label {
    margin-bottom: 6px;
    color: $main;
    line-height: 14px;
    font-size: 12px;
  }

  .select {
    position: relative;

    i {
      position: absolute;
      top: 20px;
      right: 16px;
      font-size: 8px;
      color: $main;
      transition: 0.35s;
      transform: translateY(-50%);
      z-index: 1;
    }

    select {
      width: 100%;
      height: 40px;
      line-height: 20px;
      border: 1px solid $light;
      border-radius: 2px;
      padding: 0 32px 0 16px;
      color: $main;
      font-size: 15px;
      font-weight: 600;
      user-select: none;
      background-color: transparent;
      outline: none;
      appearance: none;

      &:active {
        & + .i {
          transform: rotateX(180deg) translateY(-50%);
          transform-origin: top;
        }
      }
    }
  }

  .message {
    margin-top: 6px;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  &.error {
    padding-bottom: 0;

    select {
      border-color: $red;
    }

    .message {
      color: $red;
    }
  }
}
