@import 'styles/common';

.faqTopic {
  .title {
    margin: 0 0 60px;
    padding-top: 40px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
    }
  }

  .titleSupport {
    margin: 0 0 10px;

    @include mobile {
      margin: 0 0 16px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent),
      url('/images/faq/banner.jpg');
    background-size: cover;

    .banner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      max-width: 978px;
      min-height: 550px;
      padding-bottom: 40px;

      @include mobile {
        min-height: 400px;
      }

      h1 {
        margin-bottom: 45px;
        color: white;
      }

      .search {
        display: flex;

        @include mobile {
          flex-direction: column;
        }

        .button {
          margin-left: 20px;

          @include mobile {
            margin: 20px 0 0;
          }
        }
      }
    }
  }

  .questions {
    position: relative;

    .greyBlock {
      right: 0;
      bottom: -$main_padding_bottom;
      width: 37%;
      height: 295px;
    }

    section {
      padding: 60px 0;

      .back {
        text-transform: uppercase;

        i {
          margin-right: 13px;
          font-size: 12px;
        }
      }

      .topics {
        display: flex;
        flex-direction: column;
        max-width: 660px;

        .topic {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & + .topic {
            margin-top: 32px;
          }

          .name {
            display: flex;
            flex: 1;
            align-items: baseline;
            margin-bottom: 8px;
            transition: 0.3s;

            span {
              margin-right: 30px;
            }

            i {
              margin-left: auto;
              font-size: 12px;
            }

            &:hover {
              color: $red;
            }
          }
        }
      }
    }
  }

  .contactUs {
    position: relative;

    .greyBlock {
      right: 0;
      bottom: -$main_padding_bottom;
      width: 37%;
      height: 295px;
    }

    p {
      margin-bottom: 27px;
    }
  }
}
