@import 'styles/common';

.suggestions {
  position: absolute;
  bottom: calc(100% + 6px);
  left: 0;
  min-width: 300px;
  max-height: 278px;
  padding: 19px 16px;
  overflow: auto;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 4px 20px rgba($main, 0.2);
  z-index: 1;

  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0.25;
      transform: scale(0.75);
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .wrap {
    position: absolute;
    top: 100%;
    left: 14px;
    filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.15));

    .triangle {
      width: 22px;
      height: 12px;
      background-color: white;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }

  .townCountry {
    margin-bottom: 24px;

    i {
      margin-right: 7px;
      color: $tan;
    }
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 18px;
    line-height: 20px;
    font-size: 15px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      margin-right: 8px;
      font-size: 13px;
    }
  }

  &.onlyCity {
    .townCountry {
      cursor: pointer;
    }
  }
}
