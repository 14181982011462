@import 'styles/common';

.adyenPayment {
  .notice {
    margin-top: 20px;
  }

  // .notice {
  //   display: flex;
  //   align-items: flex-start;
  //   font-size: 12px;
  //   line-height: 16px;
  //   margin-top: 26px;

  //   .link {
  //     color: $red;
  //     font-weight: 600;
  //   }

  //   i {
  //     margin-right: 10px;
  //   }
  // }

  .status {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px 0;

    img {
      margin-bottom: 30px;
    }

    .statusTitle {
      margin-bottom: 8px;
    }

    .text {
      line-height: 26px;
      font-weight: 300;
    }
  }

  .savedCards {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    border-bottom: 1px solid $light;
  }

  .card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-size: 14px;

    & + .card {
      border-top: 1px solid $light;
    }

    .catdTitle {
      height: 55px;
      align-items: center;
      display: flex;

      .check {
        height: 18px;
        width: 18px;
        position: relative;
        border: 1px solid darken($light, 8%);
        border-radius: 50%;
        margin-right: 15px;
        transition: 0.3s;

        &:after {
          position: absolute;
          top: 3px;
          left: 3px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: $red;
        }

        &.checked {
          &:after {
            content: '';
          }
        }
      }

      .logo {
        margin-right: 11px;
      }

      .name {
        margin-right: 15px;
      }

      .controls {
        margin-left: auto;

        i {
          transition: 0.3s;
          font-size: 14px;
          color: $red;
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 2px;

          & + i {
            margin-left: 20px;
          }
        }

        &.confirmRemove {
          i {
            &.remove {
              background-color: $red;
              color: white;
            }

            &.cancel {
              background-color: $light;
              color: $main;
            }
          }
        }
      }
    }

    &:hover {
      .catdTitle {
        .check:not(.checked) {
          border-color: darken($light, 15%);
        }
      }
    }
  }

  .addNewCard {
    color: $red;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
  }

  .pay {
    margin-top: 26px;
    width: 100%;

    i {
      color: white;
      margin-right: 12px;
    }
  }

  .adyenForm {
    padding-left: 33px;
  }
}
