@import 'styles/common';

.modal {
  min-width: 500px;
  max-width: 764px;

  @include tablet {
    min-width: initial;
    max-width: initial;
  }

  .contols {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      text-align: center;
      margin: 40px 0;
    }

    p {
      text-align: center;
      margin-bottom: 40px;
    }
  }
}
