@import 'styles/common';

.search {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .button {
    margin-left: 20px;

    @include mobile {
      margin: 20px 0 0;
    }
  }

  .wrapper {
    position: relative;
    flex: 1;
  }

  .results {
    background: #FFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    padding: 16px 0;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    max-height: 308px;
    overflow-y: auto;
    z-index: 3;
  }

  .list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .item {
    padding: 8px 24px;
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;

    b {
      font-weight: bold;
    }

    &:before {
      content: none;
    }
  }

  .link {
    color: #484C55;

    span {
      font-size: 18px;
      line-height: 20px;
      color: #484C55;
      opacity: 0.7;
    }
  }
}