@import 'styles/common';

.resetPassword {
  section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: calc(100vh - 200px);
    padding-top: 100px;

    @include tablet {
      max-width: 422px;
      margin: auto;
      flex-direction: column;
      justify-content: flex-start;
    }

    &.status {
      flex-direction: column;
      justify-content: center;

      span {
        text-align: center;
      }

      p {
        text-align: center;
      }

      h5 {
        margin: 42px 0 18px;
      }

      .button {
        margin: 36px 0;
      }
    }

    .image {
      @include tablet {
        margin-bottom: 50px;
      }

      @include mobile {
        max-width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .form {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 2px;
      padding: 10px;

      @include mobile {
        padding: 10px 0;
      }
    }

    h2 {
      margin-bottom: 40px;
      color: $red;
    }

    h5 {
      white-space: nowrap;
      margin-bottom: 4px;
      font-size: 24px;
    }

    .text {
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 27px;

      span {
        font-size: 14px;
      }
    }

    .input {
      width: 100%;
    }

    .error {
      font-size: 12px;
      margin-top: 10px;
      color: $red;
    }

    .controls {
      display: flex;

      .button {
        & + .button {
          margin-left: 10px;
          min-width: 115px;
        }
      }
    }
  }
}
