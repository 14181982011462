@import 'styles/common';

.editor {
  &.empty {
    border: 1px solid $light;
    border-radius: 2px;
  }

  strong {
    font-weight: 700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 32px 0 24px;
  }

  i {
    font-style: italic;
  }

  p {
    margin-bottom: 26px;
    line-height: 26px;
  }

  img {
    max-width: 100%;
  }

  figure {
    margin: 32px 0;

    figcaption {
      margin-top: 16px;
      padding-left: 32px;
    }
  }

  a {
    color: $red;
    background: transparent !important;
  }

  ol,
  ul {
    margin-bottom: 32px;

    li {
      position: relative;
      padding-left: 12px;
      line-height: 24px;
      font-size: 16px;
      font-weight: 400;

      & + li {
        margin-top: 12px;
      }
    }
  }

  ol {
    list-style-position: inside;
    list-style-type: decimal;

    li {
      padding-left: 0;
    }
  }

  ul {
    li {
      &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 0;
        width: 5px;
        height: 5px;
        border: 1px solid $red;
        border-radius: 50%;
      }
    }
  }

  blockquote {
  }

  table {
    width: 100%;
    height: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px double $light;
    // border: 0;
    // border-left: 0 !important;
    // border-right: 0 !important;

    thead {
      tr {
        th {
          // text-align: left;
          padding: 12px 16px;
          line-height: 24px;
          // border: 0;
          font-size: 16px;
          font-weight: 600;
          vertical-align: middle;
          // background-color: rgba($light, 0.5);
          border: 1px solid $light;

          // &:first-child {
          //   border-left: 0 !important;
          //   // padding-left: 0;
          // }

          // &:last-child {
          //   border-right: 0 !important;
          //   // padding-right: 0;
          // }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 12px 16px;
          line-height: 24px;
          // border: 0;
          font-size: 16px;
          font-weight: 400;
          vertical-align: middle;
          border: 1px solid $light;

          // &:first-child {
          //   border-left: 0 !important;
          //   // padding-left: 0;
          // }

          // &:last-child {
          //   border-right: 0 !important;
          //   // padding-right: 0;
          // }
        }
      }
    }
  }

  // [class*=ck-editor] {
  //
  // }

  // &:hover {
  //   cursor: text;
  // }
}
