@import 'styles/common';

.modal {
  min-width: 500px;
  max-width: 764px;

  @include tablet {
    min-width: initial;
    max-width: initial;
  }

  .content {
    margin: auto;
  }

  .controls {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      margin: 40px 0 16px;
      text-align: center;
    }

    p {
      text-align: center;
    }

    .button {
      margin: 30px 0 20px;
    }

    .link {
      font-size: 12px;
      line-height: 15px;
      color: $red;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}
