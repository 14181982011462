@import 'styles/common';

.manageBooking {
  .title {
    margin-bottom: 41px;
    color: $red;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  section {
    display: flex;
    // align-items: center;
    // justify-content: space-evenly;
    min-height: calc(100vh - 200px);
    padding-top: 100px;

    @include tablet {
      max-width: 420px;
      margin: auto;
      flex-direction: column;
      justify-content: flex-start;
    }

    .form {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 2px;
      padding: 10px;

      width: 100%;

      @include mobile {
        padding: 10px 0;
      }

      h1 {
        margin-bottom: 40px;
        color: $red;
      }

      .content {
        max-width: 420px;

        p {
          font-size: 16px;
          margin-bottom: 26px;
        }

        .input {
          width: 100%;
          position: relative;

          .forgotPassword {
            position: absolute;
            font-weight: 600;
            font-size: 12px;
            color: $red;
            top: 0;
            right: 0;
            line-height: 14px;
            cursor: pointer;
          }
        }

        .error {
          font-size: 12px;
          margin-bottom: 10px;
          color: $red;
        }

        .button {
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }

      .colorRed {
        color: $red;
        font-size: 16px;
      }
    }
  }
}
