@import 'styles/common';

.peopleSlide {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 652px;

  @include mobile {
    width: 100%;
  }

  .input {
    margin-bottom: 20px;
    max-width: 540px;
    border: 1px solid $light;
    border-radius: 2px;
  }

  .dropzone {
    max-width: 540px;
    height: 360px;
  }

  .controls {
    display: flex;
    align-items: center;
    margin-top: 20px;

    button {
      & + button {
        margin-left: 20px;
      }
    }
  }
}
