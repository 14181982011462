@import 'styles/common';

.editSliderRoutes {
  overflow: visible !important;
  overflow-y: visible !important;
}

.promo {
  .title {
    margin: 0 0 60px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent),
      url('/images/promo/banner.png');
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 544px;

      .uploadBanner {
        position: absolute;
        top: 130px;
        right: 170px;
      }

      @include mobile {
        min-height: 400px;
      }

      h1 {
        max-width: 650px;
        color: white;
      }
    }
  }

  .information {
    section {
      display: flex;
      align-items: flex-start;

      @include tablet {
        flex-direction: column;
      }
    }

    h5 {
      margin-bottom: 16px;
    }

    .texts {
      margin-right: 110px;
      min-width: 280px;
      max-width: 373px;
      padding-top: 100px;

      @include tablet {
        margin-right: 0;
        min-width: initial;
        max-width: initial;
        padding: 60px 0;
      }

      .description {
        margin-bottom: 30px;

        span {
          color: $red;
        }
      }
    }

    .info {
      padding: 100px 71px;
      background-color: $light;

      @include tablet {
        padding: 60px 0;
      }

      .data {
        display: flex;
        flex-wrap: wrap;
        margin: -8px -20px;
        padding: 8px 20px;

        @include mobile {
          margin: -8px -15px;
          padding: 8px 15px;
        }

        .item {
          display: flex;
          margin: 8px 20px;
          min-width: calc(50% - 40px);
          height: 60px;
          padding: 7px 0;

          @include mobile {
            margin: 8px 15px;
            min-width: calc(50% - 30px);
          }

          .icon {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            justify-content: center;
            margin-right: 16px;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-color: rgba($main, 0.08);

            i {
              font-size: 18px;
            }
          }

          .text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 18px;

            .key {
              color: $red;
            }

            .value {
              display: flex;
              align-items: center;
              line-height: 27px;
              white-space: nowrap;
            }
          }
        }
      }

      .routes {
        margin-top: 24px;
        padding: 24px 0 0 62px;
        border-top: 1px solid rgba($main, 0.1);

        @include mobile {
          padding: 24px 20px 0;
        }

        h5 {
          text-transform: capitalize;
        }

        .noRoutes {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .text {
            margin-bottom: 10px;
            color: rgba($main, 0.5);
            font-size: 30px;
            text-transform: uppercase;
            letter-spacing: 1px;
          }
        }

        .addNew {
          color: $red;
          text-transform: uppercase;

          &:hover {
            border-color: $red;
          }
        }
      }

      .controls {
        display: flex;
        align-items: center;
        margin-top: 48px;
        padding-left: 62px;

        @include mobile {
          flex-direction: column;
          padding: 0 20px;
        }

        .button {
          margin-right: 32px;

          @include mobile {
            margin: 0 0 32px;
            width: 100%;
          }
        }

        .campaingRules {
          display: flex;
          align-items: center;

          span {
            font-size: 14px;
            text-decoration: underline;
            text-transform: uppercase;
            letter-spacing: 1px;
            white-space: nowrap;
          }

          i {
            margin-left: 11px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .traveling {
    position: relative;

    .greyBlock {
      top: 0;
      right: 0;
      width: 34%;
      height: 34vw;
      max-height: 440px;
    }

    section {
      padding-bottom: 100px;

      @include mobile {
        padding-bottom: 0;
      }
    }

    .features {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mobile {
        flex-wrap: wrap;
        justify-content: center;
      }

      .item {
        display: flex;
        align-items: center;
        line-height: 28px;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.5px;
        white-space: nowrap;

        @include tablet {
          flex-direction: column;
          margin-bottom: 16px;
        }

        @include mobile {
          width: 100%;
          max-width: calc(100% / 3);
          font-size: 18px;
        }

        .circle {
          display: flex;
          align-items: center;
          flex-shrink: 0;
          justify-content: center;
          margin-right: 11px;
          width: 46px;
          height: 46px;
          border-radius: 50%;
          background-color: #f0f0f1;

          @include tablet {
            margin: 0 0 8px;
          }

          @include mobile {
            width: 39px;
            height: 39px;
          }
        }
      }
    }
  }

  .services {
    margin-top: 60px;

    @include mobile {
      margin-top: 40px;
    }
  }
}
