@import 'styles/common';

.container {
  margin: auto;
  max-width: 1440px;
  padding: 0 $container_padding_desktop;

  @include laptop {
    padding: 0 $container_padding_laptop;
  }

  @include tablet {
    padding: 0 $container_padding_tablet;
  }

  @include mobile {
    padding: 0 $container_padding_mobile;
  }

  &.full {
    max-width: 100%;
    padding: 0;
  }
}
