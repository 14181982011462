@import 'styles/common';

.uploadImages {
  .dropzone {
    width: 652px;
    height: 315px;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      margin-right: 20px;
    }

    span {
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: $red;
      }
    }
  }
}
