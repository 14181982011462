@import 'styles/common';

.info {
  position: relative;
  width: 240px;
  padding: 32px;

  @include mobile {
    width: 100%;
    padding: 16px;
  }

  .decorativeСircle {
    position: absolute;
    left: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $light;

    &.top {
      top: -9px;

      @include mobile {
        top: initial;
        bottom: -8px;
        left: -11px;
        transform: rotateZ(-90deg);
      }
    }

    &.bottom {
      bottom: -9px;

      @include mobile {
        top: initial;
        left: initial;
        right: -11px;
        bottom: -8px;
        transform: rotateZ(-90deg);
      }
    }
  }

  .divider {
    position: absolute;
    top: 17px;
    bottom: 17px;
    left: -1px;
    width: 1px;
    background-image: linear-gradient(rgba($main, 0.2) 50%, transparent 50%);
    background-repeat: repeat-y;
    background-size: 1px 10px;

    @include mobile {
      top: initial;
      right: 20px;
      bottom: 0;
      left: 20px;
      width: initial;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        rgba($main, 0.2) 50%,
        transparent 50%
      );
      background-repeat: repeat-x;
      background-size: 10px 1px;
    }
  }

  .bn {
    align-items: center;
    display: inline-flex;
    white-space: nowrap;
    height: 30px;
    background-color: $light;
    border-radius: 2px;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.25px;

    span {
      margin-right: 6px;
      opacity: 0.5;
    }
  }

  .data {
    margin-top: 24px;

    @include mobile {
      display: none;
    }

    span {
      font-size: 12px;
      opacity: 0.5;
      line-height: 14px;
    }

    .item {
      text-transform: uppercase;
      line-height: 16px;
      font-size: 12px;
      letter-spacing: 1px;
      margin-top: 6px;
    }
  }
}
