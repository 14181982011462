@import 'styles/common';

.phoneNumberInput {
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;

  label {
    margin-bottom: 6px;
    color: $main;
    line-height: 14px;
    font-size: 12px;
  }

  .input {
    border: 1px solid $light;
    border-radius: 2px;
    height: 40px;
    padding: 0 16px;
    color: $main;
    font-size: 15px;
    font-weight: 400;
  }

  .message {
    margin-top: 6px;
    line-height: 16px;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .arrow {
    font-size: 8px;
    margin-left: 8px;
  }

  &.error {
    padding-bottom: 0;

    .input {
      border-color: $red;
    }

    .message {
      color: $red;
    }
  }
}
