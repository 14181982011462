@import 'styles/common';

.seatTypes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @include mobile {
    background-color: white;
    padding: 20px 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    align-items: center;
    font-weight: 400;

    @include mobile {
      width: calc(50% - 10px);

      &:nth-child(even) {
        margin-left: 20px;
      }

      &:nth-child(n + 3) {
        margin-top: 25px;
      }
    }

    .icon {
      position: relative;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      border-radius: 2px;

      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 16px;
        height: 4px;
        border-radius: 2px 2px 1px 1px;
        background-color: rgba($main, 0.3);
      }
    }

    .text {
      padding-top: 4px;
      line-height: 15px;
      font-size: 15px;

      @include mobile {
        font-size: 12px;
      }
    }

    &.regular {
      .icon {
        background-color: $green;
      }
    }

    &.lounge {
      .icon {
        background-color: $tan;
      }
    }

    &.selected {
      .icon {
        background-color: $red;
      }
    }

    &.occupied {
      .icon {
        border: 1px solid rgba($main, 0.3);
        background-color: white;

        &::after {
          bottom: -5px;
          left: -1px;
        }
      }
    }
  }
}
