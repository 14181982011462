@import 'styles/common';

.cookieBanner {
  position: fixed;
  padding: 32px 0;
  bottom: 0;
  width: 100%;
  background-color: $light;
  display: flex;
  align-items: center;
  transition: 0.35s;
  z-index: 10;

  &.hide {
    transform: translateY(100%);
  }

  @include tablet {
    padding: 20px 0;
  }

  @include mobile {
    padding: 12px 0 18px;
  }

  .container {
    width: 100%;

    section {
      align-items: center;
      display: flex;
      justify-content: center;
      max-width: 816px;
      margin: auto;

      @include mobile {
        flex-direction: column;
      }

      p {
        font-size: 14px;
        line-height: 21px;
        max-width: 600px;
        margin-right: 20px;

        @include mobile {
          margin: 0 0 20px;
        }

        .link {
          display: inline;
          font-size: 14px;
          line-height: 21px;
          color: $tan;
          margin-left: 5px;
        }
      }
    }
  }
}
