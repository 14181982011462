@mixin phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: 1279px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin line-clamp($lines) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }
}
