@import 'styles/common';

.seats {
  .title {
    margin: 0 0 60px;
    padding-top: 100px;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .loungeSeatsTitle {
    padding-top: 56px;

    @include tablet {
      padding-top: 0;
    }
  }

  .regularSeatsTitle {
    padding-top: 100px;

    @include tablet {
      padding-top: 0;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    background-position: center;
    // background-image: linear-gradient(90deg, rgba(black, 0.85), transparent), url('/images/home/banner.png');
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 544px;
      padding: 141px 112px 40px;

      @include laptop {
        padding: 116px 40px 40px;
      }

      @include tablet {
        padding: 116px 112px 40px;
      }

      @include mobile {
        padding: 100px 0 40px;
      }

      .content {
        width: 100%;

        @include tablet {
          margin: initial;
        }

        h1 {
          max-width: 480px;
          color: white;

          &[data-v2] {
            margin: 11px 0 45px;
          }
        }

        .uploadBanner {
          position: absolute;
          top: 130px;
          right: 170px;
        }
      }
    }
  }

  .descriptionLounge {
    position: relative;

    section {
      .description {
        display: flex;

        @include tablet {
          flex-direction: column-reverse;
        }

        .texts {
          display: flex;
          margin-right: 75px;
          margin-bottom: 24px;

          @include tablet {
            margin-top: 40px;
            margin-right: 0;
            width: 100%;
          }

          @include mobile {
            flex-direction: column;
            height: auto;
          }

          .text {
            max-width: 260px;

            @include mobile {
              max-width: initial;
            }

            & + .text {
              margin-left: 77px;

              @include mobile {
                margin-left: 0;
              }
            }
          }
        }

        .image {
          flex: 1;

          img {
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }

  .loungeSeats {
    section {
      .loungeSeats {
        position: relative;
        display: flex;
        margin-right: calc(
          (-100vw + 1440px) / 2 - #{$container_padding_desktop}
        );

        @include desktop {
          margin-right: -$container_padding_desktop;
        }

        @include desktop {
          margin-right: -$container_padding_desktop;
        }

        @include laptop {
          margin-right: -$container_padding_laptop;
        }

        @include tablet {
          margin-right: -$container_padding_tablet;
        }

        @include mobile {
          flex-direction: column;
          margin-right: 0;
        }

        .editSlider {
          position: absolute;
          top: 0;
          right: calc((100vw - 1440px) / 2 + #{$container_padding_desktop});

          @include desktop {
            right: $container_padding_desktop;
          }

          @include laptop {
            right: $container_padding_laptop;
          }

          @include tablet {
            right: $container_padding_tablet;
          }
        }

        .slide {
          flex-shrink: 0;
          width: calc(50% - 9px);
          height: 359px;
          object-fit: cover;

          @include tablet {
            height: 288px;
          }

          @include mobile {
            width: 259px;
            min-width: initial;
            height: 177px;
          }

          & + .slide {
            margin-left: 18px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .descriptions {
          display: flex;
          flex-direction: column;
          margin-right: 66px;

          @include mobile {
            margin: 0 0 40px;
          }
        }

        .controls {
          margin-top: auto;

          @include mobile {
            margin: 24px auto 0;
          }
        }

        .description {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          margin: 0 0 50px 0;
          width: 204px;

          @include mobile {
            margin: 0;
            width: initial;
          }

          .subTitle {
            margin-bottom: 20px;
            line-height: 30px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.5px;
          }

          .text {
            line-height: 24px;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .luxExpress {
    margin-top: 100px;

    @include mobile {
      margin-top: 40px;
    }

    @include mobile {
      margin-top: 40px;
    }

    @include mobile {
      background-color: $light;
    }

    section {
      position: relative;
      position: relative;
      display: flex;
      padding: 80px;
      background-color: $light;

      @include tablet {
        flex-direction: column;
      }

      @include mobile {
        padding: 40px 0;
        background-color: transparent;
      }

      .map {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;

        @include mobile {
          display: none;
        }
        span {
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .description {
        max-width: 350px;

        @include tablet {
          max-width: initial;
        }

        h2 {
          padding: 0;
        }

        h5 {
          margin-bottom: 20px;
        }
      }

      .details {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: 76px;

        @include tablet {
          margin: 60px 0 0;
        }

        @include mobile {
          align-items: center;
          margin: 60px 0 0;
        }

        .options {
          display: flex;
          align-items: center;
          margin-bottom: 40px;

          @include mobile {
            flex-direction: column;
          }

          .option {
            display: flex;
            align-items: center;

            .text {
              margin-left: 15px;
            }

            & + .option {
              margin-left: 60px;

              @include mobile {
                margin: 30px 0 0;
              }
            }
          }
        }

        .image {
          width: 100%;

          @include mobile {
            max-width: 176px;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .descriptionRegular {
    position: relative;

    section {
      .description {
        display: flex;
        flex-direction: row-reverse;

        @include tablet {
          flex-direction: column-reverse;
        }

        .texts {
          display: flex;
          margin-bottom: 24px;
          margin-left: 75px;
          width: 50%;

          @include tablet {
            margin-top: 40px;
            margin-left: 0;
            width: 100%;
          }

          @include mobile {
            flex-direction: column;
          }

          .text {
            max-width: 260px;

            @include mobile {
              max-width: initial;
            }

            & + .text {
              margin-left: 77px;

              @include mobile {
                margin-left: 0;
              }
            }
          }
        }

        .image {
          flex: 1;

          img {
            object-fit: cover;
            width: 100%;
          }
        }
      }
    }
  }

  .regularSeats {
    section {
      .regularSeats {
        position: relative;
        display: flex;
        margin-right: calc(
          (-100vw + 1440px) / 2 - #{$container_padding_desktop}
        );

        @include desktop {
          margin-right: -$container_padding_desktop;
        }

        @include desktop {
          margin-right: -$container_padding_desktop;
        }

        @include laptop {
          margin-right: -$container_padding_laptop;
        }

        @include tablet {
          margin-right: -$container_padding_tablet;
        }

        @include mobile {
          flex-direction: column;
          margin-right: 0;
        }

        .editSlider {
          position: absolute;
          top: 0;
          right: calc((100vw - 1440px) / 2 + #{$container_padding_desktop});

          @include desktop {
            right: $container_padding_desktop;
          }

          @include laptop {
            right: $container_padding_laptop;
          }

          @include tablet {
            right: $container_padding_tablet;
          }
        }

        .slide {
          flex-shrink: 0;
          width: calc(50% - 9px);
          height: 359px;
          object-fit: cover;

          @include tablet {
            height: 288px;
          }

          @include mobile {
            width: 259px;
            min-width: initial;
            height: 177px;
          }

          & + .slide {
            margin-left: 18px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .descriptions {
          display: flex;
          flex-direction: column;
          margin-right: 66px;

          @include mobile {
            margin: 0 0 40px;
          }
        }

        .controls {
          margin-top: auto;

          @include mobile {
            margin: 24px auto 0;
          }
        }

        .description {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          margin: 0 0 50px 0;
          width: 204px;

          @include mobile {
            margin: 0;
            width: initial;
          }

          .subTitle {
            margin-bottom: 20px;
            line-height: 30px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.5px;
          }

          .text {
            line-height: 24px;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .traveling {
    position: relative;

    .greyBlock {
      top: 0;
      right: 0;
      width: 34%;
      height: 34vw;
      max-height: 440px;
    }
  }

  .travelingBlock {
    padding-top: 40px;

    @include mobile {
      padding-top: 20px;
    }
  }

  .travelingBlock {
    padding-top: 40px;

    @include mobile {
      padding-top: 20px;
    }
  }

  .services {
    margin-top: 60px;

    @include mobile {
      margin-top: 40px;
    }

    @include mobile {
      margin-top: 0;
    }
  }
}
