@import 'styles/common';

.searchInput {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 15px;
  font-weight: 600;

  input {
    width: 100%;
    padding: 22px 20px 0;
    color: $main;
    font-size: inherit;
    font-weight: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 0;
    border-radius: 2px;
    background-color: white;
    outline: none;
    transition: 0.3s;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: black;
    }

    &::placeholder {
      color: rgba($main, 0.4);
      font-weight: 600;
      // text-transform: uppercase;
      // letter-spacing: 1px;
    }

    &[disabled] {
      color: rgba($main, 0.6);
      // background-color: #EFEFEF; // TODO: to name and move to variables
      cursor: not-allowed;
    }

    & ~ label {
      position: absolute;
      top: 12px;
      left: 20px;
      max-width: calc(100% - 28px);
      color: rgba($main, 0.7);
      line-height: 20px;
      font-size: 13px;
      font-weight: 600;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      pointer-events: none;
      transition: 0.2s;
      transform: translateY(0);
    }
  }

  &.l {
    input {
      height: 64px;
      padding: 22px 20px 0;

      & ~ label {
        top: 9px;
      }
    }
  }

  // &.m {
  //   input {
  //     height: 46px;
  //     padding: 10px 20px 0;
  //
  //     & ~ label {
  //       top: 6px;
  //     }
  //   }
  // }

  &.withIcon {
    i {
      position: absolute;
      top: 25px;
      left: 21px;
      color: $tan;
      font-size: 14px;
      transition: 0.2s;

      @include mobile {
        font-size: 13px;
      }
    }

    label {
      left: 41px;
    }

    input {
      padding-left: 41px;

      & ~ label {
        left: 22px;
      }

      & ~ i {
        top: 37px;
      }
    }

    // &.hasValue {
    //   label {
    //     left: 22px;
    //   }
    //
    //   i {
    //     top: 34px;
    //   }
    // }
  }
}
