@import 'styles/common';

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 596px;
  // margin-left: 132px;
  height: 335px;
  padding: 40px;
  overflow: hidden;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 0;

  // @include tablet {
  //   margin: 0 auto 24px;
  // }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    background-position-x: 0;
    background-image: linear-gradient(90deg, rgba(black, 0.8), transparent);
    // background-image:
    //   linear-gradient(
    //     90deg,
    //     rgba(black, 0.8) 30%,
    //     transparent 50%
    //   );
    z-index: -1;
  }

  .image {
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    z-index: -1;

    span {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .editBanner {
    position: absolute;
    top: 0;
    right: 0;
  }

  .logo {
    margin-bottom: auto;
  }

  .description {
    max-width: 220px;

    .title {
      margin-bottom: 7px;
      padding-bottom: 7px;
      color: white;
      line-height: 24px;
      font-size: 18px;
      border-bottom: 1px solid #f0f0f1;
    }

    .text {
      color: white;
      line-height: 27px;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  &:hover {
    &:after {
      background-position-x: -100px;

      @include laptop {
        background-position-x: -75px;
      }

      @include tablet {
        background-position-x: -100px;
      }

      @include mobile {
        background-position-x: -75px;
      }
    }

    .image {
      transform: scale(1.05);
    }
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 40px;
    background-color: $light;
    box-shadow: 0 0 3px 0 rgba($main, 0.5);

    &::before {
      content: none;
    }

    .text {
      margin-bottom: 25px;
      color: white;
      font-size: 45px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 5px;

      @include mobile {
        font-size: 55px;
      }
    }
  }

  &.vertical {
    max-width: 372px;
    height: 583px;
  }
}
