@import 'styles/common';

.modal {
  min-width: 500px;
  max-width: 850px !important;

  @include tablet {
    min-width: initial;
    max-width: initial;
  }

  .content {
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}
