@import 'styles/common';

.textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 22px;
  font-size: 15px;
  font-weight: 500;

  textarea {
    width: 100%;
    min-height: 89px;
    padding: 34px 20px 8px;
    color: $main;
    font-size: inherit;
    font-weight: inherit;
    resize: none;
    border: 0;
    border-radius: 2px;
    background-color: white;
    outline: none;
    transition: 0.3s;
    // overflow-y: hidden;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
      -webkit-text-fill-color: black;
    }

    &::placeholder {
      color: rgba($main, 0.35);
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &[disabled] {
      color: rgba($main, 0.5);
      // background-color: #EFEFEF; // TODO: to name and move to variables
      cursor: not-allowed;
    }

    & ~ label {
      position: absolute;
      top: 22px;
      left: 20px;
      color: rgba($main, 0.34);
      line-height: 20px;
      font-size: 13px;
      font-weight: 600;
      pointer-events: none;
      // transform: translateY(-50%);
      transition: 0.2s;
    }

    &:focus ~ label {
      top: 9px;
      transform: translateY(0);
    }
  }

  &.hasValue {
    // padding-top: 33px;
    textarea {
      // padding-top: 33px;
    }

    label {
      top: 9px;
      transform: translateY(0);
    }
  }

  &.withIcon {
    i {
      position: absolute;
      top: 25px;
      left: 21px;
      color: $tan;
      font-size: 12px;
      transition: 0.2s;
    }

    label {
      left: 41px;
    }

    textarea {
      padding-left: 41px;

      &:focus ~ label {
        left: 22px;
      }

      &:focus ~ i {
        top: 34px;
      }
    }

    &.hasValue {
      label {
        left: 22px;
      }

      i {
        top: 34px;
      }
    }
  }
}
