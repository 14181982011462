@import 'styles/common';

.mainInfo {
  align-items: center;
  display: flex;
  background-color: $light;
  color: $main;
  font-size: 13px;
  border-radius: 2px;
  font-weight: 600;
  padding: 20px;
  margin-top: 16px;
  line-height: 14px;
  cursor: pointer;

  i {
    margin-right: 9px;
  }

  .check {
    height: 14px;
    width: 14px;
    border-radius: 2px;
    border: 1px solid #bdbdbd;
    position: relative;
    margin-right: 12px;
    flex-shrink: 0;
    cursor: pointer;

    &:after {
      position: absolute;
      border-radius: 2px;
      height: 8px;
      width: 8px;
      top: 2px;
      left: 2px;
      background-color: transparent;
      content: '';
      transition: 0.3s;
    }
  }

  .text {
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;

    strong {
      font-weight: 600;
    }
  }

  &.selected {
    .check {
      &:after {
        background-color: $red;
      }
    }
  }
}
