@import 'styles/common';

.paymentMethods {
  padding-top: #{$header_height_desktop + 60px};
  background-color: $light;

  @include tablet {
    padding-top: #{$header_height_tablet + 40px};
  }

  h2 {
    margin-bottom: 60px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  section {
    .block {
      align-items: flex-start;
      display: flex;

      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        margin-left: 16px;
        width: calc(100% - (350px + 16px));

        @include tablet {
          width: 100%;
          margin-left: 0;
        }

        .header {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          padding: 0 32px;
          border-radius: 2px 2px 0 0;
          margin-bottom: 1px;
        }

        .info {
          background-color: white;
          border-radius: 0 0 2px 2px;

          @include mobile {
            padding: 12px;
          }

          .noVouchers {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 60px 32px;

            img {
              margin-bottom: 38px;
              width: 166px;
            }

            h3 {
              margin-bottom: 8px;
              font-size: 24px;
              line-height: 30px;
            }

            p {
              text-align: center;
              font-size: 16px;
              opacity: 0.75;
              max-width: 294px;
            }
          }

          .vouchers {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            padding: 8px 32px 24px;

            @include mobile {
              padding: 0;
            }

            .notice {
              margin-top: 22px;
            }

            .voucher {
              border-bottom: 1px solid $light;
              height: 54px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              // display: grid;
              // grid-template-columns: auto repeat(2, 1fr) auto;
              // grid-gap: 10px;

              // @include mobile {
              //   grid-template-columns: auto repeat(2, 1fr) auto;
              // }

              @include mobile {
                flex-wrap: wrap;
                height: auto;
                padding: 16px 0;
              }

              .code {
                line-height: 19px;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                align-items: center;

                div {
                  width: 105px;
                }

                i {
                  color: $red;
                  opacity: 0;
                  transition: 0.3s;
                  margin-left: 12px;

                  @include mobile {
                    opacity: 1;
                  }
                }
              }

              .dates {
                display: flex;
                align-items: center;

                @include mobile {
                  flex: 100%;
                  order: 5;
                  margin-top: 13px;
                }

                img {
                  margin: 0 10px;
                }

                .createdOn {
                  font-size: 12px;
                  line-height: 16px;
                  font-weight: 300;
                  opacity: 0.6;
                  white-space: nowrap;
                }

                .validUntil {
                  border-radius: 23px;
                  height: 22px;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 16px;
                  display: inline-flex;
                  align-items: center;
                  padding: 0 10px;

                  white-space: nowrap;

                  // background-color: rgba($red, 0.1);
                  color: $red;
                  background-color: rgba($green, 0.1);
                  color: $green;

                  i {
                    // color: $red;
                    color: $green;
                    margin-right: 6px;
                  }

                  // &.noExpiryDate {
                  //   background-color: rgba($green, 0.1);
                  //   color: $green;

                  //   i {
                  //     color: $green;
                  //   }
                  // }
                }
              }

              .currency {
                border-radius: 36px;
                height: 19px;
                padding: 0 7px;
                opacity: 0.6;
                margin-right: 10px;
                display: flex;
                align-items: center;
                background-color: $light;
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
              }

              .amount {
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                width: 50px;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: flex-end;
              }

              &:hover {
                .code {
                  i {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .addNewCard {
            margin-top: 20px;
            color: $red;
          }

          .controls {
            display: flex;

            .button {
              & + .button {
                margin-left: 12px;
              }
            }
          }

          .form {
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;

            .input {
              width: calc(50% - 12px);

              @include tablet {
                width: 100%;
              }

              &.date {
                align-items: flex-start;
                display: flex;

                .select {
                  width: 50%;

                  & + .select {
                    margin-left: 16px;
                  }
                }
              }

              &:nth-child(even) {
                margin-left: 24px;

                @include tablet {
                  margin-left: 0;
                }
              }
              // }
            }
          }
        }
      }
    }
  }
}
