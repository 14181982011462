@import 'styles/common';

.content {
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modalWrap {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, 0.4);
  z-index: 100;
  transition: 0.2s;
  height: calc(var(--vh, 1vh) * 100);

  &.open {
    display: flex;
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 764px;
  max-height: calc(100% - 40px);
  padding: 50px;
  background-color: white;
  border-radius: 2px;

  @include mobile {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 20px;
    overflow: hidden;
    border-radius: 0;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px;
    font-size: 10px;
    cursor: pointer;

    @include tablet {
      font-size: 20px;
    }
  }

  .header {
    margin-bottom: 35px;

    h2 {
      color: $red;
    }
  }

  .content {
    &.withoutHeaderHeight {
      height: calc(100% - 65px);
      overflow: initial;
      display: flex;
      flex-direction: column;
    }
  }
}
