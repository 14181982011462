@import 'styles/common';

.tooltipInput {
  position: relative;
  display: inline-block;

  .target {
    height: 100%;
    cursor: pointer;

    i {
      color: $red;
    }
  }

  .content {
    position: absolute;
    left: calc(50% - 22px);
    padding: 16px;
    opacity: 0;
    visibility: hidden;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: 0.2s;
    transform: scale(0.3);
    transform-origin: left bottom;
    z-index: 1;

    .wrap {
      position: absolute;
      top: calc(100% - 1px);
      left: 14px;
      filter: drop-shadow(0 3px 2px rgba(0, 0, 0, 0.15));

      .triangle {
        width: 14px;
        height: 7px;
        background-color: white;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
      }
    }

    .input {
      width: 240px;
      border: 1px solid $light;
      border-radius: 2px;
    }
  }

  &.top {
    .content {
      bottom: calc(100% + 10px);

      .wrap {
        top: 100%;
      }
    }
  }

  &.bottom {
    .content {
      top: calc(100% + 10px);

      .wrap {
        bottom: 100%;
      }
    }
  }

  &.show {
    .content {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
}
