@import 'styles/common';

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  transition: background-color 0.25s;
  background: $red
    radial-gradient(farthest-side, rgba($light-red, 1), transparent 100%) fixed
    no-repeat;
  background-size: 0 0;

  @include tablet {
    background: $red;
  }

  &:not(:disabled):hover {
    background-size: 120px 120px;

    @include tablet {
      background-size: 0;
    }
  }

  &:active {
    background-color: $light-red;
  }

  // types
  &.link {
    color: white;
  }

  // sizes
  &.s {
    min-width: auto;
    min-width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 12px;

    &.icon {
      width: 36px;
    }
  }

  &.m {
    min-width: 144px;
    height: 46px;
    padding: 0 30px;
    line-height: 46px;
    font-size: 12px;

    &.icon {
      width: 46px;
    }
  }

  &.l {
    min-width: 184px;
    height: 64px;
    padding: 0 60px;
    line-height: 64px;
    font-size: 16px;

    &.icon {
      width: 64px;
    }
  }

  // important: .icon must be placed after sizes
  &.icon {
    min-width: auto;
    padding: 0;
  }

  &.flat {
    color: $tan;
    // border: 1px solid $light;
    border-radius: 1px;
    background-color: transparent;

    &:hover {
      border-color: $tan;
    }

    &:disabled {
      color: rgba(white, 0.3);
      cursor: not-allowed;
      border: 0;
      background-color: rgba(white, 0.3);
    }
  }

  .iconLeft {
    margin-right: 18px;
  }

  .iconRight {
    margin-left: 18px;
  }

  // colors
  &.red {
    background-color: $red;
    // box-shadow: 0 4px 10px rgba(5,89,253,.1);
  }

  // &.green {
  //   background-color: #21D37B; // TODO: change to one of color variables
  //   // box-shadow: 0 4px 10px rgba(5,89,253,.1);
  // }

  &[disabled] {
    color: rgba(white, 0.6);
    // box-shadow: 0 4px 10px rgba($grey,.1);
    cursor: not-allowed;
    background-color: #6b6e73;
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}
