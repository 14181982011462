@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'icomoon';
  src: url('/fonts/icons/luxexpress.ttf') format('truetype'),
    url('/fonts/icons/luxexpress.woff') format('woff'),
    url('/fonts/icons/luxexpress.svg#luxexpress') format('svg');
  font-display: block;
}

i {
  line-height: 1;
  font-weight: normal;
  font-style: normal;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-variant: normal;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.copy:before {
    content: '\e921';
  }
  &.clock:before {
    content: '\e933';
  }
  &.ticketAlt:before {
    content: '\e920';
  }
  &.warning_v2:before {
    content: '\e91e';
  }
  &.subtract:before {
    content: '\e91f';
  }
  &.steeringWheel:before {
    content: '\e91d';
  }
  &.externalReference:before {
    content: '\e915';
  }
  &.lock:before {
    content: '\e913';
  }
  &.search:before {
    content: '\e912';
  }
  &.seat:before {
    content: '\e90e';
  }
  &.basket_v2:before {
    content: '\e90d';
  }
  &.arrowRight_v3:before {
    content: '\e904';
  }
  &.userCircle:before {
    content: '\e905';
  }
  &.exchange:before {
    content: '\e900';
  }
  &.registration::before {
    content: '\e901';
  }
  &.camera::before {
    content: '\e902';
  }
  &.eyeSlash::before {
    content: '\e903';
  }
  &.youtube::before {
    content: '\e906';
  }
  &.webform::before {
    content: '\e907';
  }
  &.warning::before {
    content: '\e908';
  }
  &.vk::before {
    content: '\e909';
  }
  &.upoading::before {
    content: '\e90a';
  }
  &.upload::before {
    content: '\e90b';
  }
  &.twitter::before {
    content: '\e90c';
  }
  &.tripadvisor::before {
    content: '\e90f';
  }
  &.tag::before {
    content: '\e910';
  }
  &.success::before {
    content: '\e911';
  }
  &.roadConnection::before {
    content: '\e914';
  }
  &.quote::before {
    content: '\e916';
  }
  &.point::before {
    content: '\e917';
  }
  &.plus_v2::before {
    content: '\e918';
  }
  &.plus::before {
    content: '\e919';
  }
  &.passenger::before {
    content: '\e91a';
  }
  &.minus::before {
    content: '\e91b';
  }
  &.menu::before {
    content: '\e91c';
  }
  &.loading::before {
    content: '\e922';
  }
  &.link::before {
    content: '\e923';
  }
  &.instagram::before {
    content: '\e924';
  }
  &.infoCircle::before {
    content: '\e925';
  }
  &.facebook::before {
    content: '\e926';
  }
  &.emptyDropzone::before {
    content: '\e927';
  }
  &.edit::before {
    content: '\e928';
  }
  &.close::before {
    content: '\e929';
  }
  &.calendar::before {
    content: '\e92a';
  }
  &.bus::before {
    content: '\e92b';
  }
  &.basket::before {
    content: '\e92c';
  }
  &.badgePercent::before {
    content: '\e92d';
  }
  &.arrowBottom::before {
    content: '\e92e';
  }
  &.arrowRight::before {
    content: '\e92f';
  }
  &.arrowRight_v2::before {
    content: '\e930';
  }
  &.arrowLeft::before {
    content: '\e931';
  }
  &.airplane::before {
    content: '\e932';
  }

  // &.pen::before {
  //   content: '\e921';
  // }

  // &.search::before {
  //   content: '\e922';
  // }

  // &.basket::before {
  //   content: '\e91b';
  // }

  // &.edit::before {
  //   content: '\e91a';
  // }

  // &.plus_v2::before {
  //   content: '\e918';
  // }

  // &.minus::before {
  //   content: '\e914';
  // }

  // &.point::before {
  //   content: '\e913';
  // }

  // &.bus::before {
  //   content: '\e90d';
  // }

  // &.airplane::before {
  //   content: '\e90e';
  // }

  // &.link::before {
  //   content: '\e90b';
  // }

  // &.twitter::before {
  //   content: '\e90c';
  // }

  // &.warning::before {
  //   content: '\e90a';
  // }

  // &.badgePercent::before {
  //   content: '\e908';
  // }

  // &.tag::before {
  //   content: '\e909';
  // }

  // &.infoCircle::before {
  //   content: '\e907';
  // }

  // &.close::before {
  //   content: '\e969';
  // }

  // &.menu::before {
  //   content: '\e96a';
  // }

  // &.arrowRight_v2::before {
  //   content: '\e96b';
  // }

  // &.facebook::before {
  //   content: '\e917';
  // }

  // &.vk::before {
  //   content: '\e900';
  // }

  // &.tripadvisor::before {
  //   content: '\e901';
  // }

  // &.instagram::before {
  //   content: '\e902';
  // }

  // &.youtube::before {
  //   content: '\e903';
  // }

  // &.arrowRight::before {
  //   content: '\e904';
  // }

  // &.quote::before {
  //   content: '\e905';
  // }

  // &.arrowLeft::before {
  //   content: '\e906';
  // }

  // &.calendar::before {
  //   content: '\e90f';
  // }

  // &.passenger::before {
  //   content: '\e910';
  // }

  // &.plus::before {
  //   content: '\e911';
  // }

  // &.success::before {
  //   content: '\e912';
  // }

  // &.arrowBottom::before {
  //   content: '\e916';
  // }
}
