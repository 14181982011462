@import 'styles/common';

.traveling {
  position: relative;

  .title {
    margin: 0 0 60px;
    padding-top: 100px;
    color: $red;

    @include laptop {
      padding-top: 60px;
    }

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .greyBlock {
    position: absolute;
    top: 100px;
    right: 0;
    width: 34%;
    height: 34vw;
    max-height: 440px;
    background-color: $light;
    z-index: -1;

    @include laptop {
      top: 60px;
    }

    @include mobile {
      display: none;
    }
  }

  section {
    .info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid rgba($main, 0.1);

      @include tablet {
        flex-direction: column-reverse;
      }

      .articles {
        display: grid;
        flex: 1;
        justify-content: space-between;
        // grid-template-columns: repeat(2, minmax(auto, 204px));
        grid-template-columns: repeat(2, auto);
        grid-template-rows: auto auto;
        grid-gap: 40px 60px;

        @include laptop {
          grid-template-columns: repeat(2, minmax(204px, auto));
        }

        @include tablet {
          justify-content: center;
        }

        @include mobile {
          grid-gap: 25px;
          grid-template-columns: auto;
        }

        // article {
        //   max-width: 204px;
        // }

        h5 {
          margin-bottom: 20px;

          @include tablet {
            margin-bottom: 8px;
          }
        }

        p {
          line-height: 24px;
          font-size: 16px;
          letter-spacing: -0.5px;

          @include tablet {
            line-height: 22px;
            font-size: 14px;
          }
        }
      }

      .banner {
        margin-left: 100px;

        @include tablet {
          margin: 0 auto 24px;
        }
      }

      // .image {
      //   background-position: center;
      //   background-size: cover;
      //   background-repeat: no-repeat;
      //   border-radius: 2px;
      //   display: flex;
      //   flex-direction: column;
      //   margin-left: 132px;
      //   height: 335px;
      //   max-width: 596px;
      //   overflow: hidden;
      //   padding: 40px;
      //   position: relative;
      //   width: 100%;
      //   z-index: 0;
      //
      //   @include tablet {
      //     margin: 0 auto 24px;
      //   }
      //
      //   &:before {
      //     background-image: linear-gradient(90deg, rgba(black, 0.8) 30%, transparent 50%);
      //     content: '';
      //     position: absolute;
      //     height: 100%;
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     z-index: -1;
      //   }
      //
      //   .logo {
      //     margin-bottom: auto;
      //   }
      //
      //   .description {
      //     max-width: 220px;
      //
      //     .title {
      //       border-bottom: 1px solid #F0F0F1;
      //       color: white;
      //       font-size: 18px;
      //       line-height: 24px;
      //       padding-bottom: 7px;
      //       margin-bottom: 7px;
      //     }
      //
      //     .text {
      //       color: white;
      //       font-size: 18px;
      //       font-weight: 400;
      //       line-height: 27px;
      //     }
      //   }
      //
      //   .divider {
      //     height: 3px;
      //     position: absolute;
      //     bottom: 0;
      //     left: 0;
      //     width: 100%;
      //   }
      // }
    }
  }

  &.home {
    .greyBlock {
      position: absolute;
      top: 0;
      right: 0;
      width: 34%;
      height: 34vw;
      max-height: 440px;
      background-color: $light;
      z-index: -1;

      @include mobile {
        display: none;
      }
    }
  }
}
