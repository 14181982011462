@import 'styles/common';

.discounts {
  .title {
    margin: 0 0 60px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
      padding-top: 60px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent), url('/images/info/banner.png'),;
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 400px;
      padding-bottom: 40px;

      h1 {
        margin-bottom: 50px;
        max-width: 540px;
        color: white;
      }

      .uploadBanner {
        position: absolute;
        top: 130px;
        right: 170px;
      }
    }
  }

  .information {
    position: relative;

    .greyBlock {
      right: 0;
      bottom: -$main_padding_bottom;
      width: 37vw;
      height: 22vw;
    }

    section {
      max-width: 650px;
      padding-top: 80px;
    }
  }
}
