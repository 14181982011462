// @font-face {
//   font-family: 'Manrope VF';
//   src: url('/fonts/manrope/variable/ManropeGX.ttf') format('truetype-variations');
//   font-style: normal;
//   font-weight: 200 800;
// }

// @font-face {
//   font-family: 'Manrope';
//   src: url('/fonts/manrope/web/Manrope-ExtraBold.woff2') format('woff2'),
//   url('/fonts/manrope/ttf/Manrope-ExtraBold.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 800;
// }

// @font-face {
//   font-family: 'Manrope';
//   src: url('/fonts/manrope/web/Manrope-Bold.woff2') format('woff2'),
//   url('/fonts/manrope/ttf/Manrope-Bold.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 700;
// }

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: 'Manrope';
  src:
    url('/fonts/manrope/web/Manrope-SemiBold.woff2') format('woff2'),
    url('/fonts/manrope/ttf/Manrope-SemiBold.ttf') format('truetype');
  font-display: swap;
}

// @font-face {
//   font-family: 'Manrope';
//   src: url('/fonts/manrope/web/Manrope-Medium.woff2') format('woff2'),
//   url('/fonts/manrope/ttf/Manrope-Medium.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 500;
// }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Manrope';
  src:
    url('/fonts/manrope/web/Manrope-Regular.woff2') format('woff2'),
    url('/fonts/manrope/ttf/Manrope-Regular.ttf') format('truetype');
  font-display: swap;
}

// @font-face {
//   font-family: 'Manrope';
//   src: url('/fonts/manrope/web/Manrope-Light.woff2') format('woff2'),
//   url('/fonts/manrope/ttf/Manrope-Light.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 300;
// }
//
// @font-face {
//   font-family: 'Manrope';
//   src: url('/fonts/manrope/web/Manrope-ExtraLight.woff2') format('woff2'),
//   url('/fonts/manrope/ttf/Manrope-ExtraLight.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 200;
// }
