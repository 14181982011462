@import 'styles/common';

.text {
  position: relative;
  display: inline-block;
  cursor: text;
  transition: 0.3s;

  .tooltip {
    position: absolute;
    bottom: calc(100% + 20px);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    opacity: 0;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    transform: scale(0);

    i {
      position: absolute;
      top: 1px;
      left: 5px;
      width: 10px;
      height: 10px;
      color: $red;
      font-size: 10px;
      opacity: 1;
      transition: 0.3s;
    }

    .info {
      display: flex;
      flex-direction: column;
      opacity: 0;

      div {
        white-space: nowrap;
      }
    }

    &.focused {
      justify-content: flex-start;
      width: auto;
      height: auto;
      padding: 12px;
      color: rgba($main, 0.6);
      opacity: 1;
      transform: scale(1);

      i {
        opacity: 0;
        transform: scale(0);
      }

      .info {
        opacity: 1;
      }
    }

    &.warning {
      span {
        color: $red;
      }

      i {
        color: $red;
      }
    }
  }

  .contentEditable {
    outline: none;
    // overflow-wrap: break-word;

    &::before {
      content: attr(placeholder);
      opacity: 0.35;
    }

    &:empty::before {
      content: attr(placeholder);
    }

    &:not(:empty)::before {
      content: '';
    }
  }

  &:hover {
    .tooltip {
      opacity: 1;
      transform: scale(1);
    }
  }
}
