@import 'styles/common';

.ticketSalesRules {
  .title {
    margin-bottom: 41px;
    color: $red;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  .greyBlock {
    position: absolute;
    right: 0;
    bottom: -$main_padding_bottom;
    width: 37vw;
    height: 22vw;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  section {
    max-width: 650px;
    padding-top: 180px;

    @include tablet {
      padding-top: 120px;
    }

    @include mobile {
      padding-top: 96px;
    }

    p {
      & + p {
        margin-top: 32px;
      }
    }

    h5 {
      margin: 76px 0 24px;

      @include mobile {
        margin: 32px 0;
      }
    }

    button {
      @include mobile {
        width: 100%;
      }
    }
  }
}
