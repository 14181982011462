@import 'styles/common';

.trips {
  padding-top: #{$header_height_desktop + 60px};
  padding-bottom: 70px !important;
  background-color: $light;

  @include tablet {
    padding-top: #{$header_height_tablet + 40px};
  }

  h2 {
    margin-bottom: 60px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  section {
    .block {
      display: flex;
      align-items: flex-start;

      .content {
        display: flex;
        flex: 1;
        align-items: stretch;
        flex-direction: column;
        margin-left: 16px;
        width: calc(100% - (350px + 16px));

        @include tablet {
          margin-left: 0;
          width: 100%;
        }

        .tabs {
          display: flex;
          flex: 1;
          flex-direction: column;
          border-radius: 2px;

          @include mobile {
            margin: 0 -20px;
          }

          .tabPanel {
            position: relative;
            display: flex;
            margin-bottom: 16px;
            overflow: hidden;
            border-radius: 2px;
            border-bottom: 1px solid $light;
            background-color: white;

            .line {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 50%;
              height: 2px;
              background-color: $red;
              transition: 0.3s;
            }

            .tab {
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
              width: calc(100% / 3);
              height: 60px;
              padding: 0 5px;
              color: rgba($main, 0.7);
              line-height: 15px;
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              cursor: pointer;
              transition: 0.3s;

              @include mobile {
                font-size: 14px;
              }

              span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }

              &.active {
                color: $red;
              }
            }
          }

          .tabsContainer {
            overflow: hidden;

            .tabsContents {
              position: relative;
              display: flex;
              flex: 1;
              align-items: flex-start;
              width: 100%;
              transition: 0.3s;
              transform: translateX(0);

              .tabsContent {
                flex-shrink: 0;
                width: 100%;

                @include mobile {
                  padding: 0 20px;
                }

                > h5:first-child {
                  margin: 0;
                }

                .date {
                  margin: 25px 0 0;
                  text-transform: uppercase;
                  letter-spacing: 1px;

                  @include mobile {
                    padding: 0 10px;
                  }
                }

                .finalStops {
                  margin: 15px 0 10px;
                  font-weight: 600;

                  @include mobile {
                    padding: 0 10px;
                  }
                }

                .touchedTicket {
                  & + .touchedTicket {
                    margin-top: 20px;
                  }
                }

                .noTickets {
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  // padding: 20px;
                  padding-top: 64px;

                  h5 {
                    margin: 25px 0 4px;
                  }

                  p {
                    margin-bottom: 32px;
                  }
                }
              }
            }
          }

          // &.three {
          //   .tabPanel {
          //     .line {
          //       left: calc(100% / 3 * 2);
          //     }
          //   }

          //   .tabsContainer {
          //     .tabsContents {
          //       transform: translateX(-200%);
          //     }
          //   }
          // }

          &.two {
            .tabPanel {
              .line {
                left: 50%;
              }
            }

            .tabsContainer {
              .tabsContents {
                transform: translateX(-100%);
              }
            }
          }

          &.one {
            .tabPanel {
              .line {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}
