@import 'styles/common';

.contacts {
  .title {
    margin: 0 0 32px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      padding-top: 40px;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent),
      url('/images/contacts/banner.png');
    background-size: cover;

    .banner {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 544px;
      padding: 141px 0 40px;

      @include laptop {
        padding-top: 116px;
      }

      @include mobile {
        min-height: 400px;
        padding-top: 100px;
      }

      h1 {
        margin-bottom: 50px;
        max-width: 540px;
        color: white;
      }

      .uploadBanner {
        position: absolute;
        top: 130px;
        right: 170px;
      }
    }
  }

  .information {
    .tabs {
      position: relative;
      top: -35px;

      .tab {
        color: white;
      }

      .subTitle {
        margin-bottom: 32px;
        max-width: 550px;
      }

      .links {
        display: grid;
        grid-gap: 24px 56px;
        // grid-template-columns: repeat(3, minmax(280px, auto));
        grid-template-columns: repeat(
          auto-fill,
          minmax(auto, calc(100% / 3 - 38px))
        );

        @include mobile {
          grid-template-columns: repeat(auto-fill, 280px);
        }

        .contentClass {
          height: 100%;
        }

        .item {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 19px 16px;
          cursor: pointer;
          border: 1px solid rgba($main, 0.1);
          border-radius: 2px;
          transition: 0.3s;

          img {
            margin-right: 16px;
          }

          &:hover {
            border-color: rgba($main, 0.2);
          }
        }
      }

      .info {
        display: flex;
        flex-wrap: wrap;
        margin: 40px 0;
        padding: 40px 0 0;
        border-top: 1px solid rgba($main, 0.1);

        &:empty {
          margin-bottom: 20px;
          padding: 0;
        }

        @include tablet {
          flex-direction: column;
          margin: 24px 0;
          padding-bottom: 24px;
        }

        .tooltip {
          top: 2px;
          margin-left: 18px;
        }

        .tooltipContent {
          min-width: 280px;
        }

        .infoCircle {
          margin-left: 18px;
          color: $red;
        }

        .editorTooltip {
          line-height: 18px;
          font-size: 12px;
          font-weight: 400;

          @include tablet {
            margin-top: 15px;
          }

          ul {
            margin: 0;

            li {
              line-height: 18px;
              font-size: 12px;
              font-weight: 400;

              &::before {
                top: 6px;
              }
            }
          }

          p {
            margin-bottom: 0;
          }
        }

        .list {
          width: 308px;

          @include tablet {
            margin-top: 16px;
          }

          @include mobile {
            width: initial;
            max-width: 320px;
          }

          .item {
            display: flex;
            align-items: flex-start;
            line-height: 18px;
            font-size: 12px;
            font-weight: 400;

            .circle {
              position: relative;
              top: 6px;
              flex-shrink: 0;
              flex-shrink: 0;
              margin-right: 8px;
              width: 4px;
              height: 4px;
              border: 1px solid $red;
              border-radius: 50%;
            }
          }

          .text {
          }
        }

        .block {
          display: flex;
          flex-direction: column;

          & + .block {
            margin-left: 89px;

            @include tablet {
              margin: 24px 0 0;
            }
          }

          .key {
            margin-bottom: 17px;
            color: rgba($main, 0.7);
            font-size: 18px;
            font-weight: 400;
          }

          .value {
          }
        }
      }

      address {
      }
    }
  }
}
