@import 'styles/variables';

.errorBoundary {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.icon {
  margin-bottom: 20px;
  width: 125px;
  height: 125px;

  path {
    fill: $tan;
  }
}

.button {
  margin-top: 40px;
}
