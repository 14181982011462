@import 'styles/common';

.modal {
  min-width: 500px;
  max-width: 764px;

  @include tablet {
    min-width: initial;
    max-width: initial;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;

    h1 {
      text-align: center;
      margin: 40px 0 16px;
    }

    p {
      text-align: center;
    }

    .controls {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 20px;

      .button {
        & + .button {
          margin-top: 20px;
        }
      }

      .cancel {
        font-size: 12px;
        line-height: 15px;
        color: $red;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}
