@import 'styles/common';

.voucher {
  @include tablet {
    &.open {
      flex: 1;
    }
  }

  .addVoucher {
    color: $red;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    height: 54px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light;

    @include tablet {
      border-bottom: 0;
    }
  }

  .voucherInput {
    height: 54px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light;

    @include tablet {
      border-bottom: 0;
    }

    .input {
      padding-bottom: 0;

      input {
        border: 0;
        padding-left: 26px;
      }
    }

    i {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      &.subtract {
        color: rgba($main, 0.4);
      }

      &.warning_v2 {
        color: $red;
      }

      &.success {
        color: $green;
      }
    }

    .action {
      color: $red;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;

      &.disabled {
        .apply {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}
