@import 'styles/common';

.calendar {
  // padding: 40px;
  position: relative;
  display: block;
  flex: 1;
  border: 1px solid rgba($main, 0.1);
  border-radius: 2px;

  // @include mobile {
  //   padding: 15px 5px;
  // }

  .day {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    height: 91px;
    height: 92px;
    padding: 10px 7px 16px;
    font-size: 14px;
    // outline: none;

    @include mobile {
      height: 50px;
      padding: 5px 3px 8px;
      font-size: 12px;
    }

    .date {
      text-align: left;
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;

      h5 {
        font-size: 12px;
      }

      &.red {
        color: $red;
      }
    }
  }

  .navBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    // margin-bottom: 25px;
    padding: 30px 35px;
    text-transform: uppercase;

    @include mobile {
      flex-direction: column;
      padding: 15px 20px;
    }

    .controls {
      margin-top: 0;

      @include mobile {
        margin-top: 15px;
      }
    }
  }

  [class='DayPicker-wrapper'] {
    padding: 0;
    outline: none;
  }

  [class*='DayPicker-Caption'] {
    display: none;
  }

  [class='DayPicker-Months'] {
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
  }

  [class='DayPicker-Month'] {
    margin: 0;
    width: 100%;
  }

  [class*='DayPicker-Weekday'] {
    padding-bottom: 17px;
    color: $main;
    text-transform: uppercase;
    border-bottom: 2px solid $red;

    @include mobile {
      padding-bottom: 10px;
      font-size: 12px;
    }
  }

  [class*='DayPicker-Day'] {
    width: calc(100% / 7);
    min-width: 70px;
    padding: 0;
    border: 1px solid $light;
    border-radius: 0;
    outline: none;

    &:hover {
      background-color: $light !important;
    }
  }

  [class*='DayPicker-Day--selected'] {
    .day {
      color: $main;
      background-color: white;
      // border: 1px solid $red !important;
      outline: 1px solid $red !important;
      // outline: none;
      // margin: -1px;
      // top: 0;
      // height: calc(100% + 2px);
      // height: 92px;
      // position: relative;
      // z-index: 2;
    }
  }

  [class*='DayPicker-Day--today'] {
    .day {
      padding: 6px 4px 16px;
      // border: 1px solid $light;
      color: $main;
      background-color: white;
      outline: none;
      // margin: -1px;
      // top: 0;
      // position: relative;
      z-index: 2;

      @include mobile {
        padding: 2px 2px 8px;
      }

      .date {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        color: white;
        border-radius: 2px;
        background-color: $red;

        @include mobile {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  [class*='DayPicker-Day--outside'],
  [class*='DayPicker-Day--disabled'] {
    color: rgba($main, 0.4);
  }

  [class*='DayPicker-Day--disabled'] {
    pointer-events: none;
  }
}
