@import 'styles/common';

.navLink {
  position: relative;
  color: $main;
  line-height: 13px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: 0.5s;

  &:hover {
    color: $red;
  }

  &.disabled {
    color: rgba($main, 0.3);
  }
}

.active {
  color: $red;
}
