@import 'styles/common';

.modal {
  min-width: 525px;
  max-width: 764px;

  @include tablet {
    min-width: initial;
    max-width: initial;
  }

  h1 {
    margin: 0 0 37px;
  }

  .prices {
    .priceClass {
      align-items: center;
      display: flex;
      height: 48px;
      border-radius: 2px;
      border: 1px solid $light;
      padding: 0 16px;
      cursor: pointer;

      .check {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid #bdbdbd;
        position: relative;
        margin-right: 12px;
        flex-shrink: 0;

        &:after {
          position: absolute;
          border-radius: 50%;
          height: 8px;
          width: 8px;
          top: 2px;
          left: 2px;
          background-color: transparent;
          content: '';
          transition: 0.3s;
        }
      }

      .price {
        margin-left: auto;
      }

      & + .priceClass {
        margin-top: 8px;
      }

      &.selected {
        background-color: $light;

        .check {
          &:after {
            background-color: $red;
          }
        }
      }
    }
  }

  .controls {
    align-items: center;
    // justify-content: center;
    display: flex;
    // flex-direction: column;
    width: 100%;
    margin-top: 30px;

    .button {
      width: 100%;
      margin-right: 20px;

      @include mobile {
        & + .button {
          margin-top: 20px;
        }
      }
    }

    .cancel {
      font-size: 12px;
      line-height: 15px;
      color: $red;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;
    }
  }
}
