@import 'styles/common';

.popularDestination {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 652px;

  @include mobile {
    width: 100%;
  }

  .input {
    position: relative;
    margin-bottom: 225px;
    width: 100%;
    max-width: 540px;
    border: 1px solid $light;
    border-radius: 2px;
  }

  .controls {
    display: flex;
    align-items: center;
    margin-top: 20px;

    button {
      & + button {
        margin-left: 20px;
      }
    }
  }

  .listSuggestions {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    min-width: 300px;
    max-height: 278px;
    padding: 19px 16px;
    overflow: auto;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0 4px 20px rgba($main, 0.2);
    z-index: 1;

    @keyframes pulse {
      from {
        opacity: 1;
        transform: scale(1);
      }

      to {
        opacity: 0.25;
        transform: scale(0.75);
      }
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }

    .wrap {
      position: absolute;
      top: 100%;
      left: 14px;
      filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.15));

      .triangle {
        width: 22px;
        height: 12px;
        background-color: white;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
      }
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      padding-left: 18px;
      line-height: 20px;
      font-size: 15px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
