@import 'styles/common';

.faq {
  .title {
    margin: 0 0 60px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .titleSupport {
    margin: 0 0 10px;

    @include mobile {
      margin: 0 0 16px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent),
      url('/images/faq/banner.jpg');
    background-size: cover;

    .banner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      max-width: 978px;
      min-height: 550px;
      padding-bottom: 40px;

      @include mobile {
        min-height: 400px;
      }

      h1 {
        margin-bottom: 45px;
        color: white;
      }
    }
  }

  .questions {
    .topics {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(260px, 260px));
      grid-gap: 36px 130px;
      max-width: 1040px;

      @include laptop {
        grid-gap: 36px 90px;
      }

      @include tablet {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        max-width: 100%;
      }

      .topic {
        width: 260px;

        @include mobile {
          width: initial;
        }

        .name {
          display: flex;
          align-items: baseline;
          margin-bottom: 8px;
          transition: 0.3s;

          i {
            margin-left: 30px;
            font-size: 12px;
          }

          &:hover {
            color: $red;
          }
        }
      }
    }
  }

  .contactUs {
    position: relative;

    .greyBlock {
      right: 0;
      bottom: -$main_padding_bottom;
      width: 37%;
      height: 295px;
    }

    p {
      margin-bottom: 27px;
    }
  }
}
