@import 'styles/common';

.passenger {
  position: relative;
  padding: 24px;

  @include tablet {
    padding: 24px 12px;
  }

  .target {
    border: 1px solid $light;
    padding: 14px 20px;
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    justify-content: flex-start;

    .info {
      display: flex;
      flex-direction: column;
      line-height: 12px;
      font-size: 12px;

      .seatType {
        font-size: 15px;
        line-height: 15px;
        text-transform: capitalize;
      }

      .fareClass {
        margin-top: 3px;
        // font-weight: 300;
        text-transform: capitalize;
        font-size: 15px;
        line-height: 15px;
      }
    }

    .arrow {
      font-size: 10px;
      transition: 1s;
      transform: rotateZ(0);
      margin-left: 5px;
    }

    .price {
      font-size: 13px;
      line-height: 18px;
      margin-left: auto;

      &.crossedOut {
        text-decoration: line-through;
        opacity: 0.7;
      }
    }

    &.open {
      .arrow {
        transform: rotateZ(180deg);
      }
    }
  }

  & + .passenger {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 24px;
      width: calc(100% - 48px);
      height: 1px;
      background-color: $light;
    }
  }

  .line {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 4px;
    height: calc(100% + 2px);
    background-color: transparent;
    transition: 0.3s;
  }

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    cursor: pointer;

    i {
      font-size: 19px;
    }

    .text {
      margin-left: 8px;
    }

    .edit {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;

      i {
        margin-left: 8px;
        font-size: 15px;
      }
    }
  }

  .selectSeat {
    .caption {
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 300;
    }

    .choose {
      display: flex;
      align-items: center;
      height: 42px;
      padding: 0 13px;
      color: rgba($main, 0.7);
      border: 1px solid $light;
      border-radius: 2px;
    }

    .fareClass {
      display: flex;
      align-items: center;
      height: 42px;
      padding: 0 13px;
      color: rgba($main, 0.7);
      border: 1px solid $light;
      border-radius: 2px;
    }

    .campaign {
      display: flex;
      background-color: $light;
      border-radius: 2px;
      align-items: center;
      height: 52px;
      padding-left: 16px;
      margin-top: 8px;

      .check {
        height: 14px;
        width: 14px;
        border-radius: 2px;
        border: 1px solid #bdbdbd;
        position: relative;
        margin-right: 12px;
        cursor: pointer;
        flex-shrink: 0;

        &:after {
          position: absolute;
          border-radius: 2px;
          height: 8px;
          width: 8px;
          top: 2px;
          left: 2px;
          background-color: transparent;
          content: '';
          transition: 0.3s;
        }
      }

      .campaignInfo {
        .title {
          line-height: 15px;
          font-size: 15px;
          font-weight: 600;
        }

        .text {
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
        }
      }

      .price {
        margin-left: auto;
        min-width: 36px;
        position: relative;
        align-self: stretch;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        font-size: 13px;
        color: $red;

        &:after,
        &:before {
          content: '';
          position: absolute;
          left: -5px;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          background-color: white;
        }

        &:after {
          top: -5px;
        }

        &:before {
          bottom: -5px;
        }
      }

      &.active {
        .campaignInfo {
          .title {
            color: $red;
          }
        }

        .check {
          &:after {
            background-color: $red;
          }
        }
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    .dropdownOption {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .text {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 15px;

        .text {
          margin-right: 15px;
          text-transform: capitalize;
        }

        // .price {
        //   margin-left: auto;
        //   white-space: nowrap;
        // }
      }

      span {
        line-height: 14px;
        font-size: 11px;
        font-weight: 400;
        opacity: 0.5;
      }

      .divider {
        position: absolute;
        bottom: -8px;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 16px;
        text-transform: uppercase;
        background-color: white;
        z-index: 1;

        div {
          position: relative;
          padding: 0 10px;
          color: rgba($main, 0.5);
          line-height: 16px;
          font-size: 12px;
          background-color: white;
          z-index: 2;
        }

        &::after {
          // left and width depend from dropdown option padding
          content: '';
          position: absolute;
          top: 50%;
          left: 21px;
          width: calc(100% - 42px);
          height: 1px;
          background-color: $light;
        }
      }

      &.withDivider {
        padding-bottom: 32px;

        .text {
          div {
            color: $red;
          }
        }

        .price {
          color: $red;
        }
      }
    }
  }

  &.active {
    .line {
      background-color: $red;
    }
  }
}
