@import 'styles/common';

.allDestinations {
  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    @include mobile {
      display: none;
    }
  }

  .bannerContainer {
    background-image: linear-gradient(90deg, rgba(black, 0.85), transparent), url('/images/all-destinations/banner.png');
    background-size: cover;

    .banner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      min-height: 550px;
      padding: 141px 0 40px;

      @include laptop {
        padding-top: 116px;
      }

      @include mobile {
        padding-top: 100px;
      }

      h1 {
        margin-bottom: 45px;
        color: white;
      }
    }
  }

  .connections {
    position: relative;

    .greyBlock {
      &.top {
        top: 0;
        left: 0;
        width: 36%;
        height: 31vw;
      }

      &.bottom {
        right: 0;
        bottom: -$main_padding_bottom;
        width: 65%;
        height: 35vw;
      }
    }

    section {
      padding-top: 100px;
    }

    .country {
      display: flex;
      align-items: flex-start;

      @include mobile {
        flex-direction: column;
      }

      & + .country {
        margin-top: 60px;

        @include mobile {
          margin-top: 32px;
        }
      }

      .name {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 24px;
        width: 250px;
        text-transform: capitalize;

        @include mobile {
          width: initial;
          font-size: 24px;
          letter-spacing: -0.5px;
        }

        .flag {
          margin-right: 16px;

          img {
            box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .directions {
        display: flex;
        flex-wrap: wrap;
        margin: -8px;
        width: 100%;
      }

      .button {
        margin-top: 32px;
        width: 100%;
        color: $main;
      }
    }
  }
}
