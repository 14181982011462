@import 'styles/common';

.paymentMethods {
  padding-top: #{$header_height_desktop + 60px};
  background-color: $light;

  @include tablet {
    padding-top: #{$header_height_tablet + 40px};
  }

  h2 {
    margin-bottom: 60px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  section {
    .block {
      align-items: flex-start;
      display: flex;

      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        margin-left: 16px;
        width: calc(100% - (350px + 16px));

        @include tablet {
          width: 100%;
          margin-left: 0;
        }

        .header {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          padding: 0 32px;
          border-radius: 2px 2px 0 0;
          margin-bottom: 1px;
        }

        .info {
          // padding: 60px 32px;
          background-color: white;
          border-radius: 0 0 2px 2px;

          @include mobile {
            padding: 30px 12px;
          }

          .cards {
            display: flex;
            flex-direction: column;
            padding: 8px 32px 24px;

            @include mobile {
              padding: 0;
            }

            .card {
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid $light;

              .catdTitle {
                height: 55px;
                align-items: center;
                display: flex;

                .check {
                  height: 18px;
                  width: 18px;
                  position: relative;
                  border: 1px solid $light;
                  border-radius: 50%;
                  margin-right: 15px;

                  &:after {
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: $red;
                  }

                  &.checked {
                    &:after {
                      content: '';
                    }
                  }
                }

                .logo {
                  margin-right: 11px;

                  img {
                    height: 20px;
                    max-width: 40px;
                  }
                }

                .name {
                  margin-right: 15px;
                }

                .controls {
                  margin-left: auto;

                  i {
                    transition: 0.3s;
                    font-size: 14px;
                    color: $red;
                    height: 32px;
                    width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 2px;

                    & + i {
                      margin-left: 20px;
                    }
                  }

                  &.confirmRemove {
                    i {
                      &.remove {
                        background-color: $red;
                        color: white;
                      }

                      &.cancel {
                        background-color: $light;
                        color: $main;
                      }
                    }
                  }
                }
              }
            }

            .notice {
              margin-top: 24px;
            }
          }

          .noCards {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 60px 32px;

            img {
              margin-bottom: 38px;
              width: 166px;
            }

            h3 {
              margin-bottom: 8px;
              font-size: 24px;
              line-height: 30px;
            }

            p {
              text-align: center;
              font-size: 16px;
              opacity: 0.75;
              max-width: 294px;
            }
          }

          .addNewCard {
            margin-top: 20px;
            color: $red;
          }

          .controls {
            display: flex;

            .button {
              & + .button {
                margin-left: 12px;
              }
            }
          }

          .form {
            padding-top: 10px;
            display: flex;
            flex-wrap: wrap;

            .input {
              width: calc(50% - 12px);

              @include tablet {
                width: 100%;
              }

              &.date {
                align-items: flex-start;
                display: flex;

                .select {
                  width: 50%;

                  & + .select {
                    margin-left: 16px;
                  }
                }
              }

              &:nth-child(even) {
                margin-left: 24px;

                @include tablet {
                  margin-left: 0;
                }
              }
              // }
            }
          }
        }
      }
    }
  }
}
