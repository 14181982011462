@import 'styles/common';

.modal {
  form {
    .inputs {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      .input {
        flex: 1 0 calc(50% - 20px);

        @include mobile {
          flex: 1 0 calc(100% - 20px);
        }
      }

      .input,
      .textarea {
        margin: 10px;
        border: 1px solid $light;
        border-radius: 2px;
      }
    }

    .attachments {
      display: flex;
      align-items: center;
      margin: 26px 0 36px;
      font-size: 12px;
      letter-spacing: 1px;

      i {
        margin-right: 10px;
      }
    }

    button {

    }
  }

  .tabs {
    .tabContent {
      padding-top: 30px;
    }

    h5 {
      margin: 22px 0 20px;
    }

    .list {
      margin-top: 14px;

      .item {
        display: flex;
        align-items: flex-start;
        line-height: 26px;
        font-size: 16px;
        font-weight: 400;

        & + .item {
          margin-top: 8px;
        }

        .circle {
          position: relative;
          top: 6px;
          flex-shrink: 0;
          flex-shrink: 0;
          margin-right: 8px;
          width: 6px;
          height: 6px;
          border: 1px solid $red;
          border-radius: 50%;
        }
      }
    }

    .button {
      margin-top: 40px;
    }
  }
}
