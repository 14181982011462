/* colors */

$main: #484c55;
$red: #c30909;
$light-red: #e70707;
$tan: #c6b791;
$light: #f0f0f1;
$green: #2bba83;

/* breakpoints */
$phone: '(max-width: 480px)';
$mobile: '(max-width: 767px)';
$tablet: '(max-width: 1024px)';
$laptop: '(max-width: 1279px)';
$desktop: '(min-width: 1440px)';

/* distances */

$header_height_desktop: 71px;
$header_height_tablet: 56px;

$container_padding_mobile: 20px;
$container_padding_tablet: 32px;
$container_padding_laptop: 24px;
$container_padding_desktop: 90px;

$main_padding_bottom: 120px;

$operationalNotificationsHeight: 42px;
