@import 'styles/common';

.verifyCode {
  .title {
    margin: 0 0 60px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  section {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 100px;

    .form {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 450px;
      padding: 10px;
      border: 1px solid $light;
      border-radius: 2px;

      h5 {
        white-space: nowrap;
      }

      .input {
        margin-bottom: 30px;
        border: 1px solid $light;
        border-radius: 2px;
      }
    }
  }
}
