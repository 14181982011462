@import 'styles/common';

.passengers {
  position: absolute;
  top: calc(100% + 13px);
  left: 0;
  width: 315px;
  padding: 0 20px;
  border-radius: 2px;
  background-color: white;
  box-shadow: 0 4px 20px rgba($main, 0.2);
  z-index: 5;

  .wrap {
    position: absolute;
    bottom: calc(100% + -1px);
    left: 14px;
    filter: drop-shadow(0 -2px 1px rgba(0, 0, 0, 0.15));

    .triangle {
      width: 22px;
      height: 12px;
      background-color: white;
      // clip-path: polygon(50% 100%, 0 0, 100% 0); // bottom
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%); // top
    }
  }

  .type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    & + .type {
      border-top: 1px solid $light;
    }

    .name {
      padding-right: 5px;

      .text {
        line-height: 20px;
        font-size: 15px;
        text-transform: capitalize;
      }

      .info {
        color: rgba($main, 0.5);
        line-height: 16px;
        font-size: 10px;
      }
    }

    .controls {
      display: flex;
      align-items: center;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
        border-radius: 2px;
        background-color: $tan;

        &.minus {
          i {
            font-size: 10px;
          }
        }

        i {
          color: white;
          font-size: 10px;
        }

        &.disabled {
          cursor: not-allowed;
          background-color: rgba($main, 0.1);
        }
      }

      .count {
        margin: 0 12px;
        min-width: 20px;
        text-align: center;
      }
    }
  }
}
