@import 'styles/common';

.tags {
  display: flex;
  flex-wrap: wrap;
  margin: -5px -8px;

  .tag {
    display: flex;
    align-items: center;
    margin: 5px 8px;
    height: 36px;
    padding: 0 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    border: 1px solid rgba($main, 0.1);
    border-radius: 2px;
    transition: 0.3s;

    &:hover {
      border: 1px solid rgba($main, 0.2);
    }
  }
}
