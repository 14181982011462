@import 'styles/common';

.modal {
  .input {
    border: 1px solid $light;
  }

  .passengers {
    display: flex;
    flex-direction: column;

    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;

      & + .type {
        border-top: 1px solid $light;
      }

      .name {
        .text {
          line-height: 20px;
          font-size: 15px;
          text-transform: capitalize;
        }

        .info {
          color: rgba($main, 0.5);
          line-height: 20px;
          font-size: 10px;
        }
      }

      .controls {
        display: flex;
        align-items: center;

        .button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          cursor: pointer;
          border-radius: 2px;
          background-color: $tan;

          &.minus {
            i {
              font-size: 10px;
            }
          }

          i {
            color: white;
            font-size: 10px;
          }

          &.disabled {
            cursor: not-allowed;
            background-color: rgba($main, 0.1);
          }
        }

        .count {
          margin: 0 12px;
          min-width: 20px;
          text-align: center;
        }
      }
    }
  }
}
