%breadcrumbs {
  margin-bottom: 40px;
  border-top: 1px solid $light;
  background-color: white;

  @include mobile {
    margin-bottom: 16px;
  }

  section {
    display: flex;
    align-items: center;
    height: 67px;

    .item {
      display: flex;
      align-items: center;
      margin-left: 18px;
      font-size: 15px;
      color: rgba($main, 0.7);
      cursor: pointer;

      @include mobile {
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          i {
            display: none;
          }
        }
      }

      i {
        margin-right: 18px;
        font-size: 10px;
        transform: rotateZ(-90deg);
      }

      &.active {
        color: $main;

        // @include mobile {
        //   i {
        //     display: none;
        //   }
        // }
      }

      &:not(.active) {
        @include mobile {
          span {
            display: none;
          }
        }
      }
    }
  }
}

%ticketsHeader {
	$sortHeightDesktop: 38px;
	$indentToFirstTicket: 20px;

	.tickets {
		.ticketsSection {
			padding-top: 40px;
	
			@include mobile {
				padding-top: 24px;
			}
	
			.title {
				margin-bottom: 6px;
	
				@include mobile {
					margin-bottom: 12px;
				}
			}
	
			.ticketsHeader {
				display: flex;
				align-items: center;
	
				font-size: 15px;
				font-weight: 400;
				line-height: 18px;
				margin: $indentToFirstTicket 0;
	
				&:first-of-type {
					height: $sortHeightDesktop;
					margin-bottom: $indentToFirstTicket;

					@include phone {
						height: initial;
					}
				}
	
				@include mobile {
					font-size: 14px;
					line-height: 16px;
				}
				
				@include phone {
					
					align-items: flex-start;
					flex-direction: column-reverse;
					margin: 16px 0;
				}
	
				.ticketsTitle {
					flex-shrink: 0;
	
					margin-right: auto;
					
					padding-right: 10px;
					display: flex;
					align-items: center;
	
					.countText {
						font-weight: 600;
						margin-right: 6px;
					}
	
					.titleText {
						flex-shrink: 0;
						opacity: 0.7;
					}
				}
	
				.sort {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: auto;
	
					@include phone {
						flex-direction: column;
						align-items: flex-start;
						margin-left: initial;
						margin-bottom: 16px;
						width: 100%;
					}
	
					.text {
						padding-right: 12px;
						text-align: right;
						opacity: 0.7;
	
						flex-shrink: 0;
	
						@include line-clamp(1);
	
						@include phone {
							margin-bottom: 8px;
						}
					}
	
					.targetWrap {
						z-index: 6;
						flex-shrink: 0;
	
						@include phone {
							width: 100%;
						}
	
						.target {
							position: relative;
							background-color: white;
	
							display: flex;
							align-items: center;
	
							padding: 10px 12px;
	
							border-radius: 2px;
							box-shadow: 0 1px 0 rgba(0,0,0,.06);
	
							&:after,
							&:before {
								content: "";
								position: absolute;
								right: 21px;
								width: 10px;
								height: 10px;
								border-radius: 50%;
								background-color: $light;
								z-index: 1;
							}
	
							&:after {
								bottom: -6px;
								box-shadow: inset 0 1px 0 rgba(0,0,0,.06);
							}
	
							&:before {
								top: -6px;
							}
							
							.selectedSort {
								position: relative;
								z-index: 2;
								font-weight: 600;
							}
							
							.arrowWrap {
								position: relative;
								z-index: 2;
								transform: rotateX(0);                
	
								padding-left: 8px;
								margin-left: auto;
	
								transition: 0.3s;
								
								.arrow {
									font-size: 10px;
								}
	
								&.open {
									transform: rotateX(180deg);
								}
							}
						}
	
						.dropdownOption {
							font-size: 14px;
							white-space: nowrap;
	
							.iconWrap {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 24px;

                .checkmark {
                  position: relative;
                  width: 24px;
                  height: 24px;

                  &:before,
                  &:after {
                    content: '';
                    position: absolute;
                    background-color: $main;
                    border-radius: 2px;
                  }
                  
                  &::before {
                    width: 2px;
                    height: 7px;
                    transform: rotate(-45deg);
                    top: 10px;
                    left: 4px;
                  }
                  
                  &::after {
                    width: 2px;
                    height: 12px;
                    transform: rotate(45deg);
                    top: 5px;
                    left: 10px;
                  }
                }
                
								// .icon {
								// 	font-weight: 600;
								// }
							}
							
							.action {
								margin-left: 6px;
								font-family: 400;
								transition: 0.3s;
							}
			
							&.disabled {
								color: rgba($main, 0.5);
							}
			
							&:not(.disabled):hover {
								color: $red;
							}
						}
					}
				}
			}
	
			.content {
				display: flex;
				align-items: flex-start;
	
				.ticketsList {
					flex: 1;
					margin-right: 20px;
	
					@include tablet {
						width: 100%;
						margin-right: 0;
					}
				}
	
				.ticketsHeader:nth-child(1) {
					margin-top: 0 !important;
				}
	
				.tripDetails {
					width: 372px;
	
					@include laptop {
						width: 320px;
					}
				}
	
				.aside {
					margin-top: calc(#{$sortHeightDesktop + $indentToFirstTicket});
				}
			}
		}
	}
}
