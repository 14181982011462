@import 'styles/common';

.registration {
  section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: calc(100vh - 200px);
    padding-top: 100px;

    @include tablet {
      max-width: 422px;
      margin: auto;
      flex-direction: column;
      justify-content: flex-start;
    }

    .image {
      @include tablet {
        margin-bottom: 50px;
      }

      @include mobile {
        max-width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .form {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 2px;
      padding: 10px;
      max-width: 420px;
      width: 100%;

      @include mobile {
        padding: 10px 0;
      }

      h2 {
        margin-bottom: 40px;
        color: $red;
      }

      h5 {
        margin-bottom: 4px;
        font-size: 24px;
        line-height: 32px;
      }

      .text {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 24px;

        span {
          font-size: 14px;
        }
      }

      .step {
        width: 100%;
      }

      .step1 {
        .input {
          width: 100%;
        }
      }

      .step2 {
        .input {
          .codeInput {
            width: 46px;
            padding: 0;
            font-size: 24px;
            font-weight: 600;

            input {
              text-align: center;
              height: 46px;
              padding: 0;
              transition: 0.3s;
              font-weight: 600;

              &::placeholder {
                color: $main;
              }

              &:focus {
                border-color: $red;
              }
            }
          }

          & + .input {
            margin-left: 13px;
          }
        }
      }

      .code {
        display: flex;
        margin-bottom: 16px;

        .input {
          .codeInput {
            input:focus::placeholder {
              color: transparent;
            }
          }
        }
      }

      .checkbox {
        align-items: center;
        display: flex;
        font-size: 12px;
        font-weight: 600;

        .link {
          color: $red;
          font-size: 12px;
          font-weight: 600;
          margin-left: 5px;
        }

        .check {
          margin-right: 5px;
          height: 14px;
          width: 14px;
          border-radius: 2px;
          border: 1px solid #bdbdbd;
          position: relative;
          margin-right: 12px;
          cursor: pointer;

          &:after {
            position: absolute;
            border-radius: 2px;
            height: 8px;
            width: 8px;
            top: 2px;
            left: 2px;
            background-color: transparent;
            content: '';
            transition: 0.3s;
          }

          &.active {
            &:after {
              background-color: $red;
            }
          }

          &.checkError {
            border-color: $red;
          }
        }
      }

      .error {
        font-size: 12px;
        margin-top: 10px;
        color: $red;
      }

      .button {
        margin-top: 10px;
      }
    }
  }
}
