@import 'styles/common';

.loading {
  min-height: 100vh;
}

.editPage {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  .controlPanel {
    height: 0;
    padding: 0;
    overflow: hidden;

    &.show {
      height: auto;
      padding-top: 111px;
      overflow: initial;
    }
  }

  .mainControl {
    position: fixed;
    bottom: 0;
    left: 50%;
    display: flex;
    align-items: stretch;
    height: 70px;
    border: 1px solid $light;
    border-radius: 2px 2px 0 0;
    background-color: white;
    z-index: 5;
    // overflow: auto;
    // max-width: 100%;
    transition: 0.3s;
    transform: translateX(-50%);

    .item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 10px;
      line-height: 18px;
      font-size: 14px;
      letter-spacing: 1px;

      .wrapList {
        position: absolute;
        bottom: 100%;
        left: 50%;
        display: none;
        padding-bottom: 11px;
        transform: translateX(-50%);

        @keyframes showNav {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        .list {
          position: relative;
          display: flex;
          flex-direction: column;
          padding: 24px 16px;
          border-radius: 4px;
          background-color: white;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15),
            0 1px 3px 0 rgba(0, 0, 0, 0.2);

          .wrap {
            position: absolute;
            top: 100%;
            left: calc(50% - 7px);
            filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.15));

            .triangle {
              width: 14px;
              height: 7px;
              background-color: white;
              clip-path: polygon(50% 100%, 0% 0%, 100% 0);
            }
          }

          .link {
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;

            & + .link {
              margin-top: 16px;
            }

            &:not([disabled]):hover {
              color: $red;
            }

            &[disabled],
            &[disabled]:hover {
              color: rgba($main, 0.7);
              cursor: not-allowed;
              // pointer-events: none;
            }
          }
        }
      }

      > span {
        margin-right: 8px;
        text-transform: uppercase;
        white-space: nowrap;
      }

      &.create {
        color: $red;
        border-right: 1px solid $light;

        > span {
          margin: 0 0 0 8px;
        }

        > i {
          padding: 7px;
          font-size: 11px;
          cursor: pointer;
        }
      }

      &.update,
      &.cancel {
        .button {
          min-width: initial;
          padding: 0 10px;
        }
      }

      &.cancel {
        .button {
          color: $red;
          border: 1px solid $red;
          background-color: white;
        }

        [disabled] {
          color: rgba($main, 0.6);
          border-color: rgba($main, 0.6);
        }
      }

      &.update,
      &.cancel {
        .button {
          min-width: initial;
          padding: 0 10px;
        }
      }

      &.cancel {
        .button {
          color: $red;
          border: 1px solid $red;
          background-color: white;
        }

        [disabled] {
          color: rgba($main, 0.6);
          border-color: rgba($main, 0.6);
        }
      }

      &:hover {
        .wrapList {
          display: block;
          animation: showNav 250ms ease-in-out both;
        }
      }
    }
  }
}
