@import 'styles/common';

.dropdown {
  position: relative;

  .target {
    cursor: pointer;
  }

  .triangleWrap {
    position: absolute;
    z-index: 5;
    top: calc(100% + -4px);
    top: calc(100%);
    left: 10px;
    transform-origin: bottom;
    transform: scale(0.3);

    opacity: 0;
    filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.1));

    transition: 0.2s;

    .triangle {
      width: 20px;
      height: 10px;
      background-color: white;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }

  .menu {
    position: absolute;
    z-index: 1;
    top: calc(100% + 10px);
    left: 0;
    transform-origin: top;
    transform: scale(0.3);

    overflow-x: hidden;
    overflow-y: auto;

    min-width: 100%;
    max-height: 360px;
    padding: 10px 0;

    visibility: hidden;
    opacity: 0;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

    transition: 0.2s;

    .option {
      cursor: pointer;

      display: flex;
      align-items: center;

      padding: 14px 20px;

      transition: 0.3s;

      &:not([disabled]):hover {
        background-color: $light;
      }

      &[disabled] {
        cursor: not-allowed;
        color: rgba($main, 0.35);
      }
    }

    &.smallMenu {
      font-size: 14px;
      line-height: 150%;
      
      .option {
        padding: 14px 24px 14px 18px;
      }
    }
  }

  &.top {
    .triangleWrap {
      top: initial;
      bottom: calc(100% + -4px);
      transform-origin: top;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));

      .triangle {
        clip-path: polygon(50% 100%, 0 0, 100% 0);
      }
    }

    .menu {
      top: initial;
      bottom: calc(100% + 6px);
      transform-origin: bottom;
    }
  }

  &.right {
    .triangleWrap {
      right: 10px;
      left: initial;
    }
    .menu {
      right: 0;
      left: initial;
    }
  }

  &.open {
    .triangleWrap {
      transform: scale(1);
      opacity: 1;
    }

    .menu {
      transform: scale(1);
      visibility: visible;
      opacity: 1;
    }
  }
}
