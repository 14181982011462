@import 'styles/common';

.header {
  position: absolute;
  top: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: $header_height_desktop;
  z-index: 11;

  @include tablet {
    height: $header_height_tablet;
  }

  &.hasNotifications {
    top: 42px;
  }

  // .logo {
  //   align-items: center;
  //   display: flex;
  //   margin: 0 20px;
  // }

  .logo {
    display: flex;
    align-items: center;
    margin: 0 58px;

    @include laptop {
      margin: 0 30px;
    }

    @include tablet {
      margin: 0 15px;
    }
  }

  &.ticketPages {
    background-color: white;
  }
}
