@import 'styles/common';
@import 'styles/variables';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.route {
  padding-bottom: $main_padding_bottom;
  flex: 1;

  @include tablet {
    padding-bottom: $main_padding_bottom / 2;
  }
}

[class='tickets-ui'] {
  main {
    background-color: $light;
  }

  .route {
    padding-top: $header_height_desktop;
    // background-color: $light;

    @include tablet {
      padding-top: $header_height_tablet;
    }

    &.withAlert {
      padding-top: $operationalNotificationsHeight + $header_height_desktop;

      @include tablet {
        padding-top: $operationalNotificationsHeight + $header_height_tablet;
      }
    }
  }
}
