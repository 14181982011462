@import 'styles/common';

.modal {
  background-color: $light !important;
  padding-bottom: 100px !important;

  .content {
    display: flex;
    flex-direction: column;
    overflow: initial;
    height: calc(100% - 60px);
  }

  .main {
    overflow: auto;
  }

  .bus {
    margin: 30px auto 0;
  }

  .confirm {
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    position: fixed;
    bottom: 3px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 25;
  }
}
