@import 'styles/common';

.controls {
  display: flex;
  align-items: center;
  margin-top: 30px;
  height: 16px;
  font-size: 12px;
  white-space: nowrap;

  .count {
    margin: 0 28px;
  }

  .button {
    display: flex;
    color: $red;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;

    &.prev {
      i {
        margin-right: 13px;
      }
    }

    &.next {
      i {
        margin-left: 13px;
      }
    }

    &[disabled] {
      color: rgba($main, 0.3);
      cursor: not-allowed;
      // pointer-events: none;
    }
  }
}
