@import 'styles/common';

.login {
  section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-height: calc(100vh - 200px);
    padding-top: 100px;

    @include tablet {
      max-width: 420px;
      margin: auto;
      flex-direction: column;
      justify-content: flex-start;
    }

    .image {
      @include tablet {
        margin-bottom: 50px;
      }

      @include mobile {
        max-width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .form {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 2px;
      padding: 10px;
      max-width: 420px;
      width: 100%;

      @include mobile {
        padding: 10px 0;
      }

      h2 {
        margin-bottom: 40px;
        color: $red;
      }

      h5 {
        margin-bottom: 4px;
        line-height: 32px;
        font-size: 24px;
      }

      .text {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 24px;

        .link {
          color: $red;
          font-size: 14px;
          font-weight: 600;
          margin-left: 5px;
        }
      }

      .input {
        width: 100%;
        position: relative;

        .forgotPassword {
          position: absolute;
          font-weight: 600;
          font-size: 12px;
          color: $red;
          top: 0;
          right: 0;
          line-height: 14px;
          cursor: pointer;
        }
      }

      .error {
        font-size: 12px;
        margin-bottom: 10px;
        color: $red;
      }

      .button {
        margin-top: 10px;
      }
    }
  }
}
