@import 'styles/common';

.blog {
  .title {
    margin: 0 0 60px;
    padding-top: 60px;
    color: $red;

    @include tablet {
      margin: 0 0 32px;
      padding-top: 40px;
    }
  }

  .greyBlock {
    position: absolute;
    background-color: $light;
    z-index: -1;

    // @include mobile {
    //   display: none;
    // }
  }

  .bannerContainer {
    // background-image: linear-gradient(90deg, rgba(black, 0.85), transparent), url('/images/blog/banner.png');
    background-size: cover;

    .banner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 550px;

      p {
        color: $tan;
      }

      h1 {
        margin: 16px 0 32px;
        max-width: 524px;
        color: white;
        font-size: 42px;
      }

      p {
        color: $tan;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .news {
    .newsList {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(316px, auto));
      justify-content: space-between;
      grid-gap: 60px;

      @include mobile {
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(280px, auto));
        margin: 0 0 60px;
      }

      .item {
        max-width: 316px;

        .date {
          margin-bottom: 9px;
          color: $tan;
          line-height: 24px;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .unpublishedText {
          border-radius: 4px;
          background-color: darken($light, 15%);
          color: $main;
          padding: 5px 9px;
          text-transform: uppercase;
          font-size: 10px;
          letter-spacing: 0.5px;
          font-weight: 600;
          display: inline-flex;
          align-items: center;
          height: 25px;
        }

        .itemTitle {
          display: flex;
          align-items: center;
          transition: 0.3s;

          &.unpublished {
            color: rgba($main, 0.7);
          }
        }

        &:hover {
          h5 {
            color: $red;
          }
        }
      }
    }

    .showMore {
      margin-top: 40px;
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0 0 1px 0;
      color: $main;
      line-height: 18px;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      cursor: pointer;

      i {
        position: relative;
        margin: 0 0 0 6px;
        font-size: 11px;

        @include mobile {
          top: 1px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background: $main;
        transition: 0.15s;
      }

      &:hover {
        &::after {
          background: transparent;
        }
      }
    }
  }
}
