@import 'styles/common';

.footer {
  background-color: $main;

  section {
    display: flex;
    align-items: flex-start;
    padding: 46px 0 18px;

    @include tablet {
      flex-direction: column;
      align-items: stretch;
    }

    .col {
      &:first-of-type {
        margin-right: 140px;
      }

      &:nth-child(2) {
        flex: 1;
        padding-top: 6px;
      }
    }

    .row {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
    }

    .logo {
      margin-bottom: 37px;
    }

    .last {
      align-items: center;
      justify-content: space-between;

      @include tablet {
        align-items: flex-start;
        flex-direction: column;
      }
    }

    .policy {
      display: flex;
      flex: 66.6666%;
      flex-wrap: wrap;
      order: 1;

      .policyLink {
        margin-right: 12px;
        padding: 12px;
        color: #bdbdbd; // TODO: check color
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        white-space: nowrap;

        @include tablet {
          margin-right: 6px;
        }
      }

      @include mobile {
        flex: 1;
      }
    }

    .services {
      display: flex;
      flex: 1%;
      align-self: stretch;
      align-items: center;
      flex-wrap: wrap;
      order: 2;
      padding: 0;

      .service {
        & + .service {
          margin-left: 25px;

          @include laptop {
            margin-left: 15px;
          }
        }

        &.madeBy {
          margin-left: auto;
          color: rgba(white, 0.4);
          text-transform: uppercase;
          letter-spacing: 1px;
          width: calc(100% / 3);
          font-size: 10px;
          font-weight: 400;

          @include mobile {
            width: calc(50% - 12px);
          }
        }
      }
    }

    .info {
      flex: calc(100% / 4);
      align-items: flex-start;
      margin-bottom: 40px;

      @include mobile {
        flex: 100%;
      }

      .title {
        margin-bottom: 25px;
        color: white;
        font-size: 18px;

        @include mobile {
          margin-bottom: 16px;
          font-size: 16px;
        }
      }

      &.routes {
        display: flex;
        flex-direction: column;

        .routes {
          display: flex;
          align-self: stretch;
          flex-direction: column;
          flex-wrap: wrap;
          margin: 0 -12px 0;

          @include tablet {
            margin: 0 -6px 0;
          }

          @include mobile {
            flex-direction: row;
          }

          .route {
            padding: 12px 20px 12px 12px;
            color: $tan;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
            text-transform: uppercase;
            transition: 0.3s;

            @include tablet {
              padding: 12px 14px 12px 6px;
            }

            @include mobile {
              overflow-wrap: break-word;
              width: 50%;
            }

            &:hover {
              color: lighten($tan, 25);
            }

            &.app {
              padding: 12px 20px 0 12px;
              border-radius: 4px;
              background-color: $main;
              color: white;
              display: flex;

              img {
                width: 140px;
              }

              @include tablet {
                padding: 12px 14px 12px 6px;
              }

              @include mobile {
                padding: 12px 0 0;
                overflow-wrap: break-word;
                width: 50%;
              }
            }
          }
        }
      }

      &.socials {
        display: flex;
        flex-direction: column;

        .socials {
          @include mobile {
            display: flex;
            justify-content: space-between;
          }

          .social {
            display: flex;
            flex: 1;
            align-items: center;
            color: $tan;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 16px;
            padding: 12px 0;
            transition: 0.3s;

            &:hover {
              color: lighten($tan, 25);
            }

            @include mobile {
              flex: initial;
              justify-content: center;
            }

            // & + .social {
            //   margin-top: 20px;

            //   @include mobile {
            //     margin-top: 0;
            //   }
            // }

            span {
              @include mobile {
                display: none;
              }
            }

            i {
              margin-right: 20px;
              width: 26px;
              color: rgba(white, 0.4);
              font-size: 16px;
              text-align: center;
            }
          }
        }
      }

      &.subscribe {
        display: flex;
        flex-direction: column;

        p {
          // TODO designers: font size
          margin-bottom: 30px;
          color: white;
          font-size: 14px;
          font-weight: 400;
        }

        button {
          margin-top: auto;

          @include mobile {
            width: 100%;
          }
        }
      }
    }
  }
}
