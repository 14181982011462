@import 'styles/common';

.stopsNew {
  display: flex;
  flex-direction: column;

  .stop {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    min-height: 95px;
    position: relative;

    .transferText {
      color: $red;
      position: absolute;
      top: -1px;
      left: 0;
      padding: 0 13px 0 58px;
      transform: translateY(-50%);
      background-color: white;
      height: 40px;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        margin-right: 6px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 43px;
        width: 1px;
        height: 100%;
        border-right: 1px dashed rgba($main, 0.2);
        top: 0;
      }
    }

    .toggle {
      cursor: pointer;
      position: absolute;
      right: -10px;
      padding: 10px;
      top: 40px;
      transition: 0.3s;

      i {
        font-size: 10px;
      }
    }

    .destinations {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      max-width: 40%;

      @include mobile {
        // max-width: 200px;
        max-width: initial;
        width: initial;
      }

      .station {
        display: flex;
        align-items: center;

        & + .station {
          margin-top: 11px;
        }

        .time {
          position: relative;
          margin-right: 15px;
          width: 43px;
          padding-right: 13px;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;

          .circle {
            position: absolute;
            top: 4px;
            right: -4px;
            width: 7px;
            height: 7px;
            border: 1px solid $main;
            border-radius: 50%;
            background-color: white;

            &::before {
              content: '';
              position: absolute;
              left: 2px;
              top: 6px;
              width: 1px;
              height: 17px;
              background-color: rgba($main, 0.2);
            }
          }
        }

        .stopName {
          // max-width: 218px;
          font-size: 12px;
          line-height: 17px;
          font-weight: 400;
          padding-right: 15px;
        }

        .endingStation {
          font-weight: 600;
        }

        &:last-child {
          .circle {
            background-color: $main;

            &::before {
              content: none;
            }
          }
        }
      }
    }

    .logo {
      height: 20px;
      width: 72px;

      span {
        height: 100%;

        img {
          height: 100%;
        }
      }
    }

    .busInformation {
      display: flex;
      flex-direction: column;
      flex-basis: 240px;
      margin-left: 20px;

      @include mobile {
        padding: 16px 0;
        flex-basis: initial;
      }

      .equipment {
        display: flex;
        flex-wrap: wrap;
        margin: -7px;

        .img {
          margin: 7px;
          width: 28px;
          height: 28px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .title {
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        margin-bottom: 7px;
      }

      .info {
        font-size: 11px;
        line-height: 18px;
        opacity: 0.6;
      }
    }

    &:first-child {
      padding-top: 0;
      min-height: initial;

      .toggle {
        top: 7px;
      }
    }

    &:last-child {
      padding-bottom: 0;
    }

    & + .stop {
      border-top: 1px solid rgba($main, 0.2);
    }

    &.showEquipments {
      .toggle {
        transform: rotateZ(180deg);
      }
    }

    &.showAllStops {
      .destinations {
        .station {
          .time {
            .circle {
              &::before {
                height: 24px;
              }
            }
          }
        }
      }
    }
  }

  &.likeMobile {
    .stop {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .destinations {
        max-width: 100%;
      }

      .logo {
        display: none;
      }

      & + .stop {
        border-top: 0;
      }
    }
  }
}
