@import 'styles/common';

.corporateClient {
  display: flex;
  // flex-wrap: wrap;

  @include mobile {
    flex-direction: column;
  }

  .form {
    width: 50%;
    margin-right: 24px;

    @include mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  .notice {
    margin-top: 20px;

    a {
      margin-left: 3px;
      color: $red;
      cursor: pointer;
    }
  }
}
