@import 'styles/common';

.personalInfo {
  padding-top: #{$header_height_desktop + 60px};
  background-color: $light;

  @include tablet {
    padding-top: #{$header_height_tablet + 40px};
  }

  h2 {
    margin-bottom: 60px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  section {
    .block {
      align-items: flex-start;
      display: flex;

      .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        margin-left: 16px;
        width: calc(100% - (350px + 16px));

        @include tablet {
          width: 100%;
          margin-left: 0;
        }

        .header {
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: white;
          padding: 0 32px;
          border-radius: 2px 2px 0 0;
          margin-bottom: 1px;

          .action {
            color: $red;
            text-transform: uppercase;
            cursor: pointer;
            font-size: 12px;
            letter-spacing: 1px;

            &[disabled] {
              color: rgba($main, 0.4);
              cursor: not-allowed;
            }
          }
        }

        .info {
          padding: 26px 32px;
          background-color: white;
          border-radius: 0 0 2px 2px;
          display: flex;
          align-items: flex-start;

          @include mobile {
            padding: 12px;
            align-items: center;
            flex-direction: column;
          }

          .photo {
            display: flex;
            flex-direction: column;
            height: 302px;
            width: 302px;
            max-width: 100%;
            position: relative;
            margin-right: 32px;
            align-items: center;
            justify-content: center;

            @include laptop {
              height: 250px;
              width: 250px;
            }

            @include mobile {
              margin-right: 0;
            }

            &:before {
              content: '';
              position: absolute;
              height: 50%;
              width: 100%;
              background-color: $light;
              border-radius: 2px;
              top: 0;
              left: 0;
            }

            .avatar {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: $tan;
              height: 140px;
              width: 140px;
              position: relative;
              overflow: hidden;
              cursor: pointer;

              @include laptop {
                height: 120px;
                width: 120px;
              }

              .cap {
                width: 100%;
                height: 100%;
                background-size: cover;
              }

              img {
              }
            }

            .error {
              font-size: 12px;
              margin-top: 10px;
              color: $red;
              position: absolute;
              bottom: 0;
            }
          }

          .fields {
            display: flex;
            flex-direction: column;
            flex: 1;

            .field {
              & + .field {
                margin-top: 4px;
              }

              &.radios {
                display: flex;
                margin-bottom: 22px;

                .radio + .radio {
                  margin-left: 32px;
                }
              }

              &.email {
                display: flex;
                flex-direction: column;
                margin-bottom: 26px;

                label {
                  font-size: 12px;
                  line-height: 14px;
                  // font-weight: 300;
                  margin-bottom: 6px;
                }

                .email {
                  background-color: $light;
                  padding: 0 16px;
                  display: flex;
                  align-items: center;
                  opacity: 0.6;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 15px;
                  height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
